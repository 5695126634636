import {useI18n} from "vue-i18n";

export const SortingOptionsList = () => {
  const {t} = useI18n()

  return [
    {
      displayName: t('market.sorting.rating'),
      value: '-rating'
    },
    {
      displayName: t('market.sorting.cheap'),
      value: 'price'
    },
    {
      displayName: t('market.sorting.expensive'),
      value: '-price'
    }
  ]
}