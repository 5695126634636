<script setup>
import {useRoute} from "vue-router";
import {useAppStore} from "@/stores/app.js";
import {onMounted, ref} from "vue";
import {onClickOutside} from "@vueuse/core";
import {useI18n} from "vue-i18n";
import BaseHeaderList from "@/components/base/header/base-header-list.vue";
import {Categories} from "@/core/api/categories.js";
import translations from "@/core/helpers/translations.js";

const route = useRoute();
const {t} = useI18n()
const appStore = useAppStore();

const categories = ref([]);

onMounted(async () => {
  const res = await Categories.getCategories()
  categories.value = res.data.categories.edges
});

const subCategory = ref("");
const subCategories = ref([]);

const cleanSelectedSubCategories = () => {
  subCategory.value = "";
  subCategories.value = [];
};

const selectSubcategories = (categoryName) => {
  if (subCategory.value === categoryName) {
    cleanSelectedSubCategories();
    return;
  }

  subCategory.value = categoryName;
  subCategories.value = categories.value.find(
    (category) => category.categoryName === categoryName,
  );
};

const list = ref(null);
const buttons = ref([]);

onClickOutside(list, () => cleanSelectedSubCategories(), {
  ignore: buttons.value,
});
</script>

<template>
  <div class="categories container">
    <el-button
      v-if="route.name === 'market'"
      class="categories__filters"
      text
      @click="appStore.isFiltersVisible = !appStore.isFiltersVisible"
    >
      <span class="categories__filters-inner">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 13">
          <path d="M15 1H7v2h8a1 1 0 1 0 0-2zm-6 9H1a1 1 0 0 0 0 2h8v-2zM.272 3.217 2.086 4.67c.153.122.218.154.297.174a.45.45 0 0 0 .234 0c.079-.02.144-.052.297-.174l1.814-1.452a.864.864 0 0 0 .18-.17.439.439 0 0 0 .07-.149A.864.864 0 0 0 5 2.652V.64c0-.223-.023-.304-.067-.385a.454.454 0 0 0-.189-.19C4.663.024 4.582 0 4.36 0H.641C.418 0 .337.023.256.067a.454.454 0 0 0-.19.189C.024.337 0 .418 0 .64v2.01c0 .143.006.192.021.247a.439.439 0 0 0 .072.15.864.864 0 0 0 .179.17zm15.503 6.803L13.95 8.24c-.157-.154-.23-.193-.317-.22a.454.454 0 0 0-.264 0c-.087.027-.16.066-.317.22l-1.826 1.781a.907.907 0 0 0-.15.17.436.436 0 0 0-.058.138.907.907 0 0 0-.017.225v1.607c0 .223.023.303.067.385a.454.454 0 0 0 .189.189c.081.043.162.067.385.067h3.718c.223 0 .304-.024.385-.067a.454.454 0 0 0 .19-.19c.043-.08.066-.161.066-.384v-1.607a.907.907 0 0 0-.017-.225.436.436 0 0 0-.058-.138.907.907 0 0 0-.15-.17z"
              fill-rule="evenodd"/>
        </svg>
        <span v-text="t('header.filter')" />
      </span>
    </el-button>
    <el-scrollbar always>
      <ul class="categories__list">
        <li
          v-for="(category, idx) of categories"
          :key="idx"
          class="categories__item"
        >
          <el-button
            :ref="
              (cat) => {
                buttons[idx] = cat;
              }
            "
            text
            class="categories__button"
          >
            <router-link :to="translations.i18nRoute({ name: 'market', query: { category: category.node.name } })" class="categories__button-inner">
              <span v-text="category.node.name" />
            </router-link>
          </el-button>
        </li>
      </ul>
    </el-scrollbar>
    <transition name="fromTop">
      <base-header-list
        v-if="subCategories.length"
        ref="list"
        :sub-categories="subCategories"
        :skin-type="subCategory"
        @close="cleanSelectedSubCategories"
      />
    </transition>
  </div>
</template>

<style scoped lang="scss">
.categories {
  height: 5rem;
  background-color: $el-bg-color-overlay;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 5px;
  gap: 50px;
  border-bottom: 2px solid $el-bg-color-page;

  @include mobile-tablet {
    display: none;
  }

  &__filters {
    margin-right: auto;
    padding: 0;

    &-inner {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1.8rem;

      & svg {
        width: 20px;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  &__button {
    padding: 8px 10px;

    &-inner {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-transform: uppercase;
      font-size: 14px;
      color: #fff;

      &:hover {
        color: #f85e27;
      }
    }

    & svg {
      transition: all 0.3s ease;
    }

    &--active {
      color: var(--el-color-primary);

      & svg {
        rotate: 180deg;
      }
    }
  }
}
</style>