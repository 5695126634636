<script setup>
</script>

<template>
  <el-skeleton class="sk-product-preview" animated>
    <template #template>
      <div class="sk-product-preview__breadcrumbs">
        <el-skeleton-item variant="text" class="sk-product-preview__breadcrumbs-item" />
        <el-skeleton-item variant="text" class="sk-product-preview__breadcrumbs-item" />
        <el-skeleton-item variant="text" class="sk-product-preview__breadcrumbs-item" />
      </div>
      <div class="sk-product-preview__state">
        <el-skeleton-item variant="circle" class="sk-product-preview__state-icon" />
        <el-skeleton-item variant="text" class="sk-product-preview__state-text" />
      </div>
      <el-skeleton-item variant="image" class="sk-product-preview__image" />
      <el-skeleton-item variant="text" class="sk-product-preview__link" />
      <div class="sk-product-preview__float">
        <el-skeleton-item variant="text" class="sk-product-preview__float-text" />
        <el-skeleton-item variant="text" class="sk-product-preview__float-line" />
      </div>
    </template>
  </el-skeleton>
</template>

<style scoped lang="scss">
.sk-product-preview {
  width: 100%;
  background-color: var(--el-bg-color);
  padding: 5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;

  &__breadcrumbs {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    &-item {
      height: 2rem;
      border-radius: 0.2rem;

      &:nth-child(1) {
        width: 10%;
      }

      &:nth-child(2) {
        width: 13%;
      }

      &:nth-child(3) {
        width: 16%;
      }
    }
  }

  &__state {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 4rem;

    &-icon {
      width: 3rem;
      height: 3rem;
    }

    &-text {
      width: 12%;
      height: 2rem;
      border-radius: 0.2rem;
    }
  }

  &__image {
    max-height: 30rem;
    width: 100%;
    height: 30rem;
    margin-bottom: 4rem;
  }

  &__link {
    align-self: center;
    width: 20%;
    height: 2rem;
    border-radius: 0.2rem;
    margin-bottom: 2rem;
  }

  &__float {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;

    &-text {
      height: 2rem;
      width: 10%;
      border-radius: 0.2rem;
    }

    &-line {
      height: 2rem;
      width: 100%;
      border-radius: 0.2rem;
    }
  }
}
</style>