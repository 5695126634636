<script setup>
import { useI18n } from "vue-i18n";
import UiAnimatedBackground from "@/components/ui/ui-animated-background.vue";
import {useCompanyStore} from "@/stores/company.js";
import {computed} from "vue";

const { t } = useI18n();
const companyStore = useCompanyStore();

const company = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo : null
})
const companyEmail = computed(() => {
  return company.value?.emailFrom || '';
});
const companyName = computed(() => {
  if (!company.value?.companyName) return '';
  const [text] = company.value.companyName.split(',').map((part) => part.trim());
  return text;
});
const companyPhone = computed(() => {
  return company.value?.companyPhoneNumber || '';
});
const companyAddress = computed(() => {
  return company.value?.companyAddress || '';
});
</script>

<template>
  <div>
    <ui-animated-background />
    <div class="container">
      <div class="contact">
        <div class="contact__head">
          <h1 class="contact__title" v-text="t('contact.title')" />
          <span class="contact__subtitle" v-text="t('contact.subtitle')" />
        </div>
        <div class="contact__container">
          <h3 class="contact__name" v-text="t('contact.contact')" />
          <div class="contact__container-inner">
            <div class="contact__box">
              <span class="contact__text">
                {{ companyName }}
                {{ companyAddress }}
              </span>
            </div>
            <div class="contact__box">
              <a
                :href="'tel:' + companyPhone"
                class="contact__text"
                v-text="companyPhone"
              />
              <a
                :href="'mailto:' + companyEmail"
                class="contact__text"
                v-text="companyEmail"
              />
            </div>
          </div>
        </div>
        <div class="contact__container">
          <h3 class="contact__name" v-text="t('contact.graphics')" />
          <div class="contact__container-inner">
            <div class="contact__box">
              <a
                target="_blank"
                href="https://steamcommunity.com"
                class="contact__text"
                v-text="'steamcommunity.com'"
              />
            </div>
          </div>
        </div>
        <div class="contact__container">
          <h3 class="contact__name" v-text="t('contact.notices')" />
          <div class="contact__container-inner">
            <div class="contact__box">
              <i18nT
                tag="span"
                class="contact__text"
                scope="global"
                keypath="contact.noticesT"
              >
                <template #link>
                  <a
                    :href="'mailto:' + companyEmail"
                    class="contact__text"
                    v-text="t('contact.here')"
                  />
                </template>
              </i18nT>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.contact {
  width: 100%;
  min-height: 50vh;
  background-color: var(--el-bg-color);
  padding: 4rem;

  @include tablet {
    padding: 4rem 2rem;
  }

  @include mobile {
    padding: 4rem 1.5rem;
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    margin-bottom: 4rem;
  }

  &__title {
    font-size: 5rem;
    font-weight: 700;

    @include mobile {
      font-size: 3.4rem;
    }
  }

  &__subtitle {
    color: var(--el-text-color-secondary);
  }

  &__container {
    max-width: 50%;
    width: 100%;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 0.1rem solid var(--el-text-color-secondary);
    display: flex;
    align-items: start;
    gap: 5rem;
    justify-content: space-between;

    @include mobile-tablet {
      max-width: none;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 2rem;
    }

    &-inner {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 3rem;

      @include mobile {
        width: 100%;
      }
    }
  }

  &__name {
    display: block;
    width: 40%;
    font-size: 1.6rem;
    font-weight: 700;

    @include mobile {
      width: 100%;
    }
  }

  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__text {
    font-weight: 500;
    line-height: 140%;
  }

  a.contact__text {
    color: var(--el-color-info);
    font-family: "Montserrat Semi Bold", sans-serif;

    @include hover {
      color: var(--el-color-info-light-5);
    }
  }
}
</style>