<script setup>
import {computed, ref} from "vue";
import en from "@/locales/en-GB.json";
import { useI18n } from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import {useCompanyStore} from "@/stores/company.js";

const { t } = useI18n();
const companyStore = useCompanyStore();

const company = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo : null
})
const companyEmail = computed(() => {
  return company.value?.emailFrom || '';
});
const companyPhone = computed(() => {
  return company.value?.companyPhoneNumber || '';
});
const companyAddress = computed(() => {
  return company.value?.companyAddress || '';
});
const companyName = computed(() => {
  if (!company.value?.companyName) return '';
  const [text] = company.value.companyName.split(',').map((part) => part.trim());
  return text;
});

const data = ref(en.privacy.content);

const doc = ref(null);

const activeAnchor = ref("");

const scrollTo = (id) => {
  activeAnchor.value = id;
  const el = document.getElementById(id);
  if (el) {
    window.scrollTo({
      top: el.offsetTop - 50,
      left: 0,
      behavior: "smooth",
    });
  }
};
</script>

<template>
  <div class="container">
    <div class="doc">
      <div class="doc__nav">
        <div
          v-for="(anchor, contentIdx) of data"
          :key="contentIdx"
          class="navigation"
        >
          <button
            class="navigation__button"
            :class="
              activeAnchor ===
              t(`privacy.content[${contentIdx}].title`)
                .toLowerCase()
                .split(' ')
                .join('-')
                ? 'navigation__button--active'
                : ''
            "
            @click="
              scrollTo(
                t(`privacy.content[${contentIdx}].title`)
                  .toLowerCase()
                  .split(' ')
                  .join('-'),
              )
            "
          >
            <span v-text="t(`privacy.content[${contentIdx}].title`)" />
          </button>
        </div>
      </div>
      <div class="doc__content">
        <div ref="doc" class="doc__head">
          <h1 class="doc__title" v-text="t('privacy.title')" />
          <h2 class="doc__subtitle" v-text="t('privacy.subtitle')" />
        </div>
        <div
          v-for="(section, idx) of data"
          :id="
            t(`privacy.content[${idx}].title`)
              .toLowerCase()
              .split(' ')
              .join('-')
          "
          :key="idx"
          class="doc-block"
        >
          <h2
            class="doc-block__title"
            v-text="`§ ${idx + 1} ` + t(`privacy.content[${idx}].title`)"
          />
          <div
            v-for="(block, index) of section.texts"
            :key="index"
            class="doc-block__inner"
          >
            <i18n-t
              v-if="typeof section.texts[index] === 'string'"
              tag="p"
              class="doc-block__text"
              scope="global"
              :keypath="`privacy.content[${idx}].texts[${index}]`"
            >
              <template #privacy>
                <router-link
                  class="doc-block__link"
                  :to="translations.i18nRoute({ name: 'privacy-policy' })"
                >
                  {{ t("privacy.title") }}
                </router-link>
              </template>
              <template #site>
                <a href="/" target="_blank" class="doc-block__link"
                  >Gameskins.eu</a
                >
              </template>
              <template #companyName>
                <span class="doc-block__text">
                  {{ companyName }}
                </span>
              </template>
            </i18n-t>
            <ul
              v-if="Array.isArray(section.texts[index])"
              class="doc-block__list"
            >
              <li
                v-for="(i, listIdx) of section.texts[index]"
                :key="i"
                class="doc-block__item"
              >
                <i18n-t
                  tag="p"
                  class="doc-block__text"
                  scope="global"
                  :keypath="`privacy.content[${idx}].texts[${index}][${listIdx}]`"
                >
                  <template #companyName>
                    <p class="doc-block__text">
                      {{ companyName }}
                    </p>
                  </template>
                  <template #address>
                    <p class="doc-block__text">
                      {{ companyAddress }}
                    </p>
                  </template>
<!--                  <template #country>-->
<!--                    <p class="doc-block__text">Cyprus</p>-->
<!--                  </template>-->
                  <template #tel>
                    <a
                      :href="'tel:' + companyPhone"
                      class="doc-block__link"
                      v-text="companyPhone"
                    />
                  </template>
                  <template #email>
                    <a
                      :href="'mailto:' + companyEmail"
                      class="doc-block__link"
                      v-text="companyEmail"
                    />
                  </template>
                </i18n-t>
              </li>
            </ul>
            <div
              v-if="
                !Array.isArray(section.texts[index]) &&
                typeof section.texts[index] === 'object'
              "
              class="doc-block__inner"
            >
              <h2
                class="doc__subtitle"
                v-text="t(`privacy.content[${idx}].texts[${index}].title`)"
              />
              <div
                v-for="(subSec, subSecIdx) of section.texts[index].texts"
                :key="subSecIdx"
              >
                <i18n-t
                  v-if="
                    typeof section.texts[index].texts[subSecIdx] === 'string'
                  "
                  tag="p"
                  class="doc-block__text"
                  scope="global"
                  :keypath="`privacy.content[${idx}].texts[${index}].texts[${subSecIdx}]`"
                >
                  <template #privacy>
                    <router-link
                      class="doc-block__link"
                      :to="translations.i18nRoute({ name: 'privacy-policy' })"
                    >
                      {{ t("privacy.title") }}
                    </router-link>
                  </template>
                  <template #site>
                    <a href="/" target="_blank" class="doc-block__link"
                      >Gameskins.eu</a
                    >
                  </template>
                  <template #tools_google>
                    <a
                      href="http://tools.google.com/dlpage/gaoptout?hl=en"
                      target="_blank"
                      class="doc-block__link"
                    >
                      http://tools.google.com/dlpage/gaoptout?hl=en
                    </a>
                  </template>
                </i18n-t>
                <ul
                  v-if="Array.isArray(section.texts[index].texts[subSecIdx])"
                  class="doc-block__list"
                >
                  <li
                    v-for="(i, listIdx) of section.texts[index].texts[
                      subSecIdx
                    ]"
                    :key="i"
                    class="doc-block__item"
                  >
                    <i18n-t
                      tag="p"
                      class="doc-block__text"
                      scope="global"
                      :keypath="`privacy.content[${idx}].texts[${index}].texts[${subSecIdx}][${listIdx}]`"
                    >
                      <template #instagram_policy>
                        <a
                          target="_blank"
                          href="https://privacycenter.instagram.com/policy"
                          class="doc-block__link"
                          v-text="'https://privacycenter.instagram.com/policy'"
                        />
                      </template>
                      <template #twitter_policy>
                        <a
                          target="_blank"
                          href="https://twitter.com/en/privacy"
                          class="doc-block__link"
                          v-text="'https://twitter.com/en/privacy'"
                        />
                      </template>
                      <template #facebook_policy>
                        <a
                          target="_blank"
                          href=": https://www.facebook.com/privacy/policy"
                          class="doc-block__link"
                          v-text="': https://www.facebook.com/privacy/policy'"
                        />
                      </template>
                      <template #telegram_policy>
                        <a
                          target="_blank"
                          href="https://telegram.org/privacy/eu"
                          class="doc-block__link"
                          v-text="'https://telegram.org/privacy/eu'"
                        />
                      </template>
                    </i18n-t>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.doc {
  width: 100%;
  position: relative;
  display: flex;
  align-items: start;
  gap: 2px;
  height: 100%;

  &__nav {
    width: 100%;
    background-color: var(--el-bg-color);
    max-width: 45rem;
    padding: 5rem;
    position: sticky;
    top: 0;

    @include tablet {
      padding: 3rem;
      max-width: 30rem;
    }

    @include mobile {
      display: none;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.3rem;
  }

  &__head {
    width: 100%;
    padding: 5rem;
    background-color: var(--el-bg-color);
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;

    @include tablet {
      padding: 3rem;
    }

    @include mobile {
      padding: 2rem;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 5rem;
    margin-bottom: 2rem;

    @include tablet {
      font-size: 4rem;
    }

    @include mobile {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }

  &__text {
    color: var(--el-text-color-secondary);
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 2rem;
  padding-left: 4rem;

  @include tablet {
    padding-left: 0;
  }

  &__button {
    width: fit-content;
    text-align: start;
    font-size: 1.6rem;
    line-height: 130%;
    font-weight: 600;
    color: var(--el-text-color-primary);

    @include hover {
      color: var(--el-color-primary);
    }

    &--active {
      padding-left: 1rem;
      color: var(--el-color-primary);
      font-family: "Montserrat Bold", sans-serif;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 0.4rem;
        border-radius: 0.5rem;
        background-color: var(--el-color-primary);
      }
    }
  }
}

.doc-block {
  width: 100%;
  padding: 5rem;
  background-color: var(--el-bg-color);

  @include tablet {
    padding: 3rem;
  }

  @include mobile {
    padding: 2rem;
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  &__inner {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 0.1rem solid var(--el-text-color-secondary);
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__subtitle {
    font-family: "Montserrat Semi Bold", sans-serif;
    font-size: 2rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__text {
    line-height: 150%;
    font-size: 1.6rem;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__item {
    line-height: 150%;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  &__link {
    color: var(--el-color-info);
    font-family: "Montserrat Bold", sans-serif;

    @include hover {
      opacity: 0.65;
    }
  }
}
</style>