import {useRouteQuery} from "@vueuse/router";
import {defineStore} from "pinia";
import {computed, ref, watch} from "vue";
import {Products} from "@/core/api/products.js";

export const useMarketStore = defineStore('market', () => {
    const page = useRouteQuery("page", 1, {transform: Number})
    const max_price = useRouteQuery("max_price", "30000", { transform: Number });
    const min_price = useRouteQuery("min_price", "0.1", { transform: Number });
    const exterior = useRouteQuery("exterior", "");
    const name = useRouteQuery("name", "");
    const ordering = useRouteQuery("ordering", '-rating');
    const quality = useRouteQuery("quality", "");
    const rarity = useRouteQuery("rarity", "");
    const skinType = useRouteQuery("skin_type", "");
    const tags = useRouteQuery("tags", "");
    const weapon = useRouteQuery("weapon", "");

    const computedQuery = computed(() => {
        const queryObject = {
            min_price: min_price.value,
            max_price: max_price.value,
            skin_type: skinType.value,
            weapon: weapon.value,
            name: name.value,
            exterior: exterior.value,
            quality: quality.value,
            rarity: rarity.value,
            ordering: ordering.value,
            tags: tags.value,
        };
        let queryString = '?'

        Object.keys(queryObject).forEach((query) => {
            if (queryObject[query]) {
                queryString =
                    queryString +
                    `${queryString.length === 1 ? query : "&" + query}=${queryObject[query]}`;
            }
        });

        return queryString
    })

    const isProductsPending = ref(true);

    const products = ref([]);
    const totalItems = ref(0);

    const empty = ref(false)

    const isLastPage = ref(false)

    return {
        page,
        max_price,
        min_price,
        exterior,
        name,
        ordering,
        quality,
        rarity,
        skinType,
        tags,
        weapon,
        computedQuery,
        products,
        totalItems,
        isProductsPending,
        empty,
        isLastPage
    }
})