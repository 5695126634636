import {apiTemplate} from "@/core/helpers/api";

export class Wishlist {
  static async getWishlist(accessToken) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "X-EVIBES-AUTH": `Bearer ${accessToken}`,
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `query {
        wishlists {
          edges {
            node {
              uuid
              products {
                edges {
                  node {
                    uuid
                    price
                    name
                    description
                    images {
                      edges {
                        node {
                          uuid
                          image
                        }
                      }
                    }
                    category {
                      name
                    }
                    attributeGroups {
                      edges {
                        node {
                          name
                          uuid
                          attributes {
                            name
                            uuid
                            values {
                              value
                              uuid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    );

    return await response;
  }

  static async overwriteWishlist(action, accessToken, wishlistUuid, productUuid) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "X-EVIBES-AUTH": `Bearer ${accessToken}`,
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        ${action}(wishlistUuid: "${wishlistUuid}", productUuid: "${productUuid}") {
          wishlist {
            uuid
            products {
              edges {
                node {
                  uuid
                  price
                  name
                  description
                  quantity
                  images {
                    edges {
                      node {
                        uuid
                        image
                      }
                    }
                  }
                  category {
                    name
                  }
                  attributeGroups {
                    edges {
                      node {
                        name
                        uuid
                        attributes {
                          name
                          uuid
                          values {
                            value
                            uuid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    );

    return await response;
  }
}
