<script setup>
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import {useAuthStore} from "@/stores/auth.js";
import {useCartStore} from "@/stores/cart.js";
import CartWidgetCard from "@/components/cart/cart-widget-card.vue";
import CartEmpty from "@/components/cart/cart-empty.vue";
import CartCheckout from "@/components/cart/cart-checkout.vue";
import {Orders} from "@/core/api/orders.js";

const { t } = useI18n();
const cartStore = useCartStore();
const authStore = useAuthStore();

const accessToken = computed(() => authStore.accessToken);
const orderUuid = computed(() => {
  return cartStore.currentOrder.uuid ? cartStore.currentOrder.uuid : null
})

const productsInCart = computed(() => {
  return cartStore.currentOrder.orderProducts ? cartStore.currentOrder.orderProducts.edges :[]
})

const clearCart = async () => {
  const res = await Orders.deleteOrder(accessToken.value, orderUuid.value);
  cartStore.setCurrentOrders(res.data.removeAllOrderProducts.order)
};
</script>

<template>
  <div class="container">
    <div class="cart">
      <div v-if="productsInCart.length" class="cart__inner">
        <div class="cart__head">
          <h1 class="cart__title" v-text="t('cart.title')" />
          <span
            class="cart__total"
            v-text="t('cart.total', { total: productsInCart.length })"
          />
        </div>
        <ul class="cart__list">
          <transition-group name="fromLeft">
            <li
              v-for="product of productsInCart"
              :key="product.node.uuid"
              class="cart__item"
            >
              <cart-widget-card :product="product.node" />
            </li>
          </transition-group>
        </ul>
        <button
          class="cart__button"
          v-text="t('cart.clear')"
          @click="clearCart"
        />
      </div>
      <cart-empty v-else />
      <cart-checkout />
    </div>
  </div>
</template>

<style scoped lang="scss">
.cart {
  width: 100%;
  background-color: var(--el-bg-color);
  padding: 4rem;
  display: flex;
  align-items: stretch;
  gap: 4rem;

  @include mobile-tablet {
    flex-direction: column;
    align-items: start;
  }

  @include mobile {
    padding: 2rem 0;
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem 4rem;
    border-bottom: 0.1rem solid var(--el-text-color-placeholder);

    @include mobile {
      padding: 0 2rem 2rem;
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  &__list {
    width: 100%;
    margin-bottom: 4rem;
  }

  &__title {
    font-size: 18px;
  }

  &__button {
    color: var(--el-text-color-primary);
    align-self: center;
    font-size: 12px;
    letter-spacing: 1.6px;
    text-transform: uppercase;

    @include hover {
      color: var(--el-color-primary);
    }

    &:active {
      color: var(--el-color-primary);
    }
  }
}
</style>