<script setup>
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import translation from "@/core/helpers/translations.js";
import BaseFooterBottom from "@/components/base/footer/base-footer-bottom.vue";
import {Categories} from "@/core/api/categories.js";
import {useAppStore} from "@/stores/app.js";

const { t } = useI18n();
const appStore = useAppStore();

const isMenuVisible = computed(() => appStore.isMenuVisible);

const selectedCategory = ref({
  displayName: "CS2",
  path: 'market',
});

const closeMenu = () => {
  appStore.isMenuVisible = false;
};

const selectCategory = (category) => {
  selectedCategory.value = category;
};

const categories = ref([]);

onMounted(async () => {
  const res = await Categories.getCategories()
  categories.value = res.data.categories.edges
});
</script>

<template>
  <article :class="isMenuVisible ? 'menu menu--open' : 'menu'">
    <div class="menu__inner">
      <div class="menu__head">
        <router-link
          class="menu__title"
          :to="translation.i18nRoute({ name: 'market' })"
          @click="closeMenu"
        >
          {{ t("header.total", { total: selectedCategory.displayName }) }}
        </router-link>
        <button class="menu__close" @click="closeMenu">
          <img src="@icons/close.svg" alt="close" />
        </button>
      </div>
      <div class="menu__content">
        <el-collapse class="full" accordion>
          <el-collapse-item
            v-for="(category, idx) of categories"
            :key="idx"
            @click="
              selectCategory({
                displayName: category.node.name,
                path: `/market?page=1&skin_type=${category.node.name}`,
              })
            "
          >
            <template #title>
              <h3 class="menu__category">
                {{ category.node.name }}
              </h3>
            </template>
            <ul class="menu__list">
              <li
                v-for="(weapon, linkIdx) of category.weapons"
                :key="linkIdx"
                class="menu__item"
              >
                <router-link
                  class="menu__link"
                  :to="
                    translations.i18nRoute(
                      `/market?page=1&skin_type=${category.node.name}&name=${weapon}`,
                    )
                  "
                  @click="closeMenu"
                >
                  {{ weapon }}
                </router-link>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
        <router-link
          class="menu__button"
          :to="translation.i18nRoute({ name: 'market' })"
          @click="closeMenu"
          >{{ t("header.market") }}</router-link
        >
        <base-footer-bottom mobile />
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss">
.menu {
  position: fixed;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  background-color: var(--el-bg-color);
  z-index: 999;
  transition: all 0.3s ease;

  @include tablet {
    width: 40vw;
    transform: translateX(-40vw);
  }

  @include mobile-tablet {
    display: block;
  }

  @include mobile {
    width: 100vw;
    transform: translateX(-100vw);
  }

  &--open {
    @include mobile-tablet {
      transform: translateX(0);
    }
  }

  &__inner {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  &__head {
    height: 5rem;
    width: 100%;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 2rem;
      width: calc(100% - 4rem);
      height: 0.2rem;
      background-color: var(--el-text-color-placeholder);
    }
  }

  &__title {
    color: var(--el-text-color-primary);
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;

    @include hover {
      color: var(--el-color-primary);
    }
  }

  &__close {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: calc(50% - 1px);
    color: var(--el-text-color-primary);

    @include hover {
      color: var(--el-color-primary);
      background-color: var(--el-bg-color-page);
    }

    &:active {
      color: var(--el-color-primary);
      background-color: var(--el-bg-color-page);
    }
  }

  &__content {
    width: 100%;
    height: calc(100vh - 5rem);
    padding: 2rem 2rem 4rem 2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4rem;

    &::-webkit-scrollbar {
      width: 1rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--el-bg-color-page);
      border: 0.2rem solid var(--el-bg-color);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 1rem;
  }

  &__link {
    color: var(--el-text-color-primary);
    font-size: 1.6rem;
    font-weight: 600;

    @include hover {
      color: var(--el-color-primary);
    }
  }

  &__button {
    width: 100%;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    color: var(--el-text-color-primary);
    border: 0.1rem solid var(--el-text-color-primary);
    text-align: center;
    font-weight: 500;

    @include hover {
      color: var(--el-color-primary);
      border-color: var(--el-color-primary);
    }
  }
}
</style>
