<script setup>
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import en from '@/locales/en-GB.json'
import {useRouter} from "vue-router";

const { t } = useI18n()
const router = useRouter()

const slider = ref(en.home.swiper.slides)

const homeSwiper = ref(null)
const prevButton = ref(null)
const nextButton = ref(null)


const redirect = async (pathName) => {
  router.push({
    name: pathName,
    params: {
      locale: localStorage.getItem('user-locale') || 'en-GB'
    },
  });
}
</script>

<template>
  <div class="slider">
    <button ref="prevButton" class="slider__button slider__button-prev">
      <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 17 29">
        <path d="M12.864.772.136 13.5l12.728 12.728" transform="translate(2 1)" stroke-width="3" fill="none"
            fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
    <div class="container">
      <div class="slider__content">
        <swiper
            ref="homeSwiper"
            class="slider__swiper"
            loop
            :effect="'fade'"
            :modules="[Navigation, Autoplay]"
            :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }"
            :navigation="{ prevEl: prevButton, nextEl: nextButton }"
        >
          <swiper-slide v-for="(slide, idx) of slider" :key="idx" class="slider__slide">
            <div
                class="slider__slide-bg"
                :style="{ backgroundImage: `url('/slides/${slide.image}.png')` }"
            />
            <h2
                v-motion
                class="slider__slide-title"
                :visible="{
                opacity: 1,
                x: 0
              }"
                :initial="{ opacity: 0, x: 30 }"
                :enter="{
                opacity: 1,
                x: 0,
                transition: {
                  opacity: {
                    duration: 400
                  },
                  x: {
                    duration: 500
                  },
                  ease: 'ease'
                }
              }"
                :delay="200"
                v-text="t(`home.swiper.slides[${idx}].title`)"
            />
            <p
                v-motion
                class="slider__slide-text"
                :visible="{
                opacity: 1,
                x: 0
              }"
                :initial="{ opacity: 0, x: 60 }"
                :enter="{
                opacity: 1,
                x: 0,
                transition: {
                  opacity: {
                    duration: 400
                  },
                  x: {
                    duration: 500
                  },
                  ease: 'ease'
                }
              }"
                :delay="300"
                v-text="t(`home.swiper.slides[${idx}].text`)"
            />
            <div
                v-motion
                :initial="{ opacity: 0, x: 90 }"
                :visible="{
                opacity: 1,
                x: 0
              }"
                :enter="{
                opacity: 1,
                x: 0,
                transition: {
                  opacity: {
                    duration: 400
                  },
                  x: {
                    duration: 500
                  },
                  ease: 'ease'
                }
              }"
                :delay="400"
            >
              <el-button
                  type="info"
                  size="large"
                  @click="redirect(slide.name)"
              >
                <span v-text="t(`home.swiper.slides[${idx}].button`)" />
              </el-button>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <button ref="nextButton" class="slider__button slider__button-next">
      <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 17 29">
        <path d="M.136 26.228 12.864 13.5.136.772" transform="translate(2 1)" stroke-width="3" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
</template>

<style scoped lang="scss">
.slider {
  width: 100%;
  position: relative;
  background-color: transparent;

  & .container {
    @include mobile-tablet {
      padding: 0 !important;
    }
  }

  &__content {
    width: 100%;
  }

  &__slide {
    width: 100%;
    height: 500px!important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 8.5rem;
    gap: 4rem;
    position: relative;
    overflow: hidden;

    @include mobile-tablet {
      padding: 0 2rem;
      height: 230px!important;
      gap: 2rem;
    }

    &-bg {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 55% 50%;
      -webkit-animation: image 5s linear;
      -o-animation: image 5s linear;
      animation: image 5s ease-out;

      @keyframes image {
        0% {
          background-position: 45% 50%;
        }
        100% {
          background-position: 55% 50%;
        }
      }
    }

    &-title {
      font-weight: 900;
      font-size: 6.4rem;
      line-height: 100%;
      max-width: 76.8rem;
      text-shadow: 0.4rem 0.7rem 1.7rem rgba(0, 0, 0, 0.8);

      @include mobile-tablet {
        font-size: 2.4rem;
      }
    }

    &-text {
      font-weight: 900;
      font-size: 2.2rem;
      line-height: 120%;
      max-width: 64rem;
      text-shadow: 0.4rem 0.7rem 1.7rem rgba(0, 0, 0, 0.8);

      @include mobile-tablet {
        font-size: 1.4rem;
        max-width: 32rem;
      }
    }
  }

  &__button {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--el-text-color-secondary);

    & svg {
      width: 20px;
    }

    @include hover {
      background-color: var(--el-bg-color);
      color: var(--el-text-color-primary);
    }

    @include mobile {
      display: none;
    }

    @include desktop {
      width: 5rem;
    }

    @include mobile {
      display: none;
    }

    &-prev {
      left: 0;
      border-right: 2px solid var(--el-bg-color-page);
    }

    &-next {
      right: 0;
      border-left: 2px solid var(--el-bg-color-page);
    }
  }
}
</style>