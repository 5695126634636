import {apiTemplate} from "@/core/helpers/api.js";
import {useCookies} from "@vueuse/integrations/useCookies";

export class Auth {
  static async register(payload) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        createUser (
          firstName: "${payload.first_name}"
          lastName: "${payload.last_name}"
          email: "${payload.email}"
          password: "${payload.password}"
          confirmPassword: "${payload.repeat_password}"
        ) {
          success
        }
      }`
    );

    return await response;
  }

  static async login(payload) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        obtainJwtToken (email: "${payload.email}", password: "${payload.password}") {
          accessToken
          refreshToken
          user {
            uuid
            attributes
            language
            email
            firstName
            lastName
            balance {
              amount
            }
          }
        }
      }`
    );

    return await response;
  }

  static async updateUser(data, accessToken) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "X-EVIBES-AUTH": `Bearer ${accessToken}`,
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        updateUser (${data}) {
          user {
            uuid
            attributes
            language
            email
            firstName
            lastName
            balance {
              amount
            }
          }
        }
      }`
    );

    return await response;
  }

  static async refresh() {
    const userLocale = localStorage.getItem('user-locale');
    const cookies = useCookies(["refresh_token"]);

    if (!cookies.get('refresh_token')) {
      return false
    } else {
      const response = await apiTemplate(
        {
          "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
        },
        `mutation {
          refreshJwtToken(refreshToken: "${cookies.get('refresh_token')}") {
            accessToken
            refreshToken
            user {
              uuid
              attributes
              language
              email
              firstName
              lastName
              balance {
                amount
              }
            }
          }
        }`
      );

      return await response;
    }
  }

  static async activateUser(token, uid) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        activateUser(token: "${token}", uid: "${uid}") {
          success
        }
      }`
    );

    return await response;
  }

  static async resetPassword(email) {
    const userLocale = localStorage.getItem('user.ts-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        resetPassword(email: "${email}") {
          success
        }
      }`
    );

    return await response;
  }

  static async confirmResetPassword(uid, token, password, passwordConfirm) {
    const userLocale = localStorage.getItem('user.ts-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        confirmResetPassword(confirmPassword: "${passwordConfirm}", password: "${password}", token: "${token}", uid: "${uid}") {
          success
        }
      }`
    );

    return await response;
  }

  static async setLanguage(userUuid, accessToken, language) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "X-EVIBES-AUTH": `Bearer ${accessToken}`,
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        updateUser (uuid: "${userUuid}", language: "${language}") {
          user {
            uuid
            attributes
            language
            email
            firstName
            lastName
            balance {
              amount
            }
          }
        }
      }`
    );

    return await response;
  }
}