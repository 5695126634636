<script setup>
import { computed, reactive, ref } from "vue";
import {ElNotification} from "element-plus";
import { useI18n } from "vue-i18n";
import { useCookies } from "@vueuse/integrations/useCookies";
import {useAuthStore} from "@/stores/auth.js";
import {useCartStore} from "@/stores/cart.js";
import {useWishlistStore} from "@/stores/wishlist.js";
import {useRouter} from "vue-router";
import translations from "@/core/helpers/translations.js";
import {Auth} from "@/core/api/auth.js";
import {Orders} from "@/core/api/orders.js";
import {Wishlist} from "@/core/api/wishlist.js";
import translation from "@/core/helpers/translations.js";

const { t } = useI18n();
const authStore = useAuthStore();
const cartStore = useCartStore();
const wishlistStore = useWishlistStore();
const cookies = useCookies(["access_token"]);
const router = useRouter()

const isPending = ref(false);

const loginFormRef = ref();
const loginForm = reactive({
  email: "",
  password: "",
});
const rules = reactive({
  email: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.email") }),
      trigger: "change",
    },
    {
      message: t("fieldErrors.email"),
      validator(rule, value) {
        return /.+@.+\..+/.test(value);
      },
      trigger: "change",
    },
  ],
  password: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.password") }),
      trigger: "change",
    },
  ],
});

const submitForm = async () => {
  if (!loginFormRef.value) return;
  await loginFormRef.value.validate(async (valid) => {
    if (!valid) return;
    isPending.value = true;

    const result = await Auth.login(loginForm);

    if (result.errors) {
      isPending.value = false;

      ElNotification({
        title: t("notifications.error.login.title"),
        message: t("notifications.error.login.text"),
        type: "error",
        duration: 5000,
        position: "bottom-left",
      });
      return;
    }

    authStore.setUser({
      user: result.data.obtainJwtToken.user,
      accessToken: result.data.obtainJwtToken.accessToken
    })

    if (result.data.obtainJwtToken.user.language !== translation.currentLocale) {
      translations.switchLanguage(result.data.obtainJwtToken.user.language)
    }

    cookies.set("refresh_token", result.data.obtainJwtToken.refreshToken, { path: "/" });

    const currentOrder = await Orders.getOrders("PENDING", result.data.obtainJwtToken.accessToken, result.data.obtainJwtToken.user.email)
    cartStore.setCurrentOrders(currentOrder.data.orders.edges[0].node)

    const wishlistRes = await Wishlist.getWishlist(result.data.obtainJwtToken.accessToken)
    wishlistStore.wishlist = wishlistRes.data.wishlists.edges[0].node

    isPending.value = false;

    ElNotification({
      title: t("notifications.success.login.title"),
      type: "success",
      duration: 5000,
      position: "bottom-left",
    });

    await router.push({
      name: 'home',
      params: {
        locale: localStorage.getItem('user-locale') || 'en-GB'
      }
    })
  });
};

const isFormValid = computed(() => {
  return /.+@.+\..+/.test(loginForm.email) && loginForm.password.length !== 0;
});

const isPasswordVisible = ref(false);
</script>

<template>
  <el-form
    ref="loginFormRef"
    class="form"
    :model="loginForm"
    :rules="rules"
    label-position="top"
    @submit.prevent="submitForm"
  >
    <el-form-item class="form__element" prop="email">
      <el-input
        v-model="loginForm.email"
        size="large"
        :placeholder="t('fields.emailPh')"
      />
    </el-form-item>
    <el-form-item class="form__element" prop="password">
      <el-input
        v-model="loginForm.password"
        size="large"
        :placeholder="t('fields.passwordPh')"
        :type="isPasswordVisible ? 'text' : 'password'"
      >
        <template #suffix>
          <button
            class="form__switch"
            @click.prevent="() => (isPasswordVisible = !isPasswordVisible)"
            v-text="isPasswordVisible ? t('login.hide') : t('login.show')"
          />
        </template>
      </el-input>
    </el-form-item>
    <router-link
      class="form__link"
      :to="translations.i18nRoute({ name: 'reset-password' })"
    >
      {{ t("login.forgot") }}
    </router-link>
    <el-button
      class="form__button"
      type="info"
      size="large"
      :disabled="!isFormValid"
      @click.prevent="submitForm"
    >
      {{ isPending ? t("login.loading") : t("login.button") }}
    </el-button>
  </el-form>
</template>

<style scoped lang="scss">
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;

  &__element {
    width: 100%;
  }

  &__switch {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: var(--el-text-color-secondary);
    margin: 0 !important;

    @include hover {
      color: var(--el-text-color-primary);
    }

    &:active {
      color: var(--el-text-color-primary);
    }
  }

  &__link {
    align-self: end;
    margin-top: -2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: var(--el-text-color-primary);
    font-weight: 500;

    @include hover {
      color: var(--el-color-primary);
    }

    &:active {
      color: var(--el-color-primary);
    }

    @include mobile {
      margin-top: 0;
    }
  }

  &__button {
    width: 100%;
    margin-top: 1rem;
  }
}
</style>