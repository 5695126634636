<script setup>
import { useI18n } from "vue-i18n";
import en from "@/locales/en-GB.json";
import { onMounted, ref } from "vue";
import { useRouteHash } from "@vueuse/router";

const { t } = useI18n();

const content = ref(en.faq.content);

const hash = useRouteHash();

const faq = ref(null);

const activeAnchor = ref("");

onMounted(() => {
  if (hash.value) {
    activeAnchor.value = hash.value;
    scrollToAnchor(hash.value.split("#")[1]);
  }
});

const scrollToAnchor = (id) => {
  activeAnchor.value = id;
  const el = document.getElementById(id);
  if (el) {
    window.scrollTo({
      top: el.offsetTop - 100,
      left: 0,
      behavior: "smooth",
    });
  }
};
</script>

<template>
  <div class="container">
    <div class="faq">
      <div class="faq__nav">
        <div
          v-for="(anchor, contentIdx) of content"
          :key="contentIdx"
          class="navigation"
        >
          <h4
            class="navigation__title"
            v-text="t(`faq.content[${contentIdx}].title`)"
          />
          <div class="navigation__list">
            <button
              v-for="(aa, answerIdx) of content[contentIdx].answers"
              :key="answerIdx"
              class="navigation__button"
              :class="
                activeAnchor ===
                t(`faq.content[${contentIdx}].answers[${answerIdx}].title`)
                  .toLowerCase()
                  .split(' ')
                  .join('-')
                  ? 'navigation__button--active'
                  : ''
              "
              @click="
                scrollToAnchor(
                  t(`faq.content[${contentIdx}].answers[${answerIdx}].title`)
                    .toLowerCase()
                    .split(' ')
                    .join('-'),
                )
              "
            >
              <span
                v-text="
                  t(`faq.content[${contentIdx}].answers[${answerIdx}].title`)
                "
              />
            </button>
          </div>
        </div>
      </div>
      <div class="faq__content">
        <div ref="faq" class="faq__head">
          <h1 class="faq__title" v-text="t('faq.title')" />
          <p class="faq__text" v-text="t('faq.subtitle')" />
          <p class="faq__text" v-text="t('faq.subtitle_desc')" />
        </div>
        <div v-for="(section, idx) of content" :key="idx" class="faq-block">
          <h2
            class="faq-block__title"
            v-text="t(`faq.content[${idx}].title`)"
          />
          <div
            v-for="(bb, index) of content[idx].answers"
            :id="
              t(`faq.content[${idx}].answers[${index}].title`)
                .toLowerCase()
                .split(' ')
                .join('-')
            "
            :key="index"
            class="faq-block__inner"
          >
            <h3
              class="faq-block__subtitle"
              v-text="t(`faq.content[${idx}].answers[${index}].title`)"
            />
            <div class="faq-block__content">
              <div
                v-for="(block, textIndex) of content[idx].answers[index]
                  .content"
                :key="textIndex"
              >
                <p
                  v-if="
                    typeof content[idx].answers[index].content[textIndex] ===
                    'string'
                  "
                  class="faq-block__text"
                  v-text="
                    t(
                      `faq.content[${idx}].answers[${index}].content[${textIndex}]`,
                    )
                  "
                />
                <ul v-else class="faq-block__list">
                  <li
                    v-for="(el, itemIndex) of content[idx].answers[index]
                      .content[textIndex]"
                    :key="itemIndex"
                    class="faq-block__item"
                  >
                    <span
                      v-text="
                        t(
                          `faq.content[${idx}].answers[${index}].content[${textIndex}][${itemIndex}]`,
                        )
                      "
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.faq {
  width: 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  gap: 2px;

  &__top {
    position: fixed;
    bottom: 4rem;
    right: 4rem;

    @include mobile {
      bottom: 3rem;
      right: 3rem;
    }
  }

  &__nav {
    width: 100%;
    background-color: var(--el-bg-color);
    max-width: 45rem;
    height: fit-content;
    padding: 5rem;

    @include tablet {
      padding: 3rem;
      max-width: 30rem;
    }

    @include mobile {
      display: none;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.3rem;
  }

  &__head {
    width: 100%;
    padding: 5rem;
    background-color: var(--el-bg-color);
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;

    @include tablet {
      padding: 3rem;
    }

    @include mobile {
      padding: 2rem;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 5rem;
    margin-bottom: 2rem;

    @include tablet {
      font-size: 4rem;
    }

    @include mobile {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }

  &__text {
    color: var(--el-text-color-secondary);
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  margin-bottom: 4rem;
  padding-left: 4rem;

  @include tablet {
    padding-left: 0;
  }

  &__title {
    font-size: 1.6rem;
    color: var(--el-text-color-secondary);
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.5rem;
  }

  &__button {
    width: fit-content;
    text-align: start;
    font-size: 1.4rem;
    line-height: 130%;
    font-weight: 700;
    color: var(--el-text-color-primary);

    @include hover {
      color: var(--el-color-primary);
    }

    &--active {
      padding-left: 1rem;
      color: var(--el-color-primary);
      font-weight: 700;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 0.4rem;
        border-radius: 0.5rem;
        background-color: var(--el-color-primary);
      }
    }
  }
}

.faq-block {
  width: 100%;
  padding: 5rem;
  background-color: var(--el-bg-color);

  @include tablet {
    padding: 3rem;
  }

  @include mobile {
    padding: 2rem;
  }

  &__title {
    font-weight: 700;
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  &__inner {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 0.1rem solid var(--el-text-color-secondary);
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__subtitle {
    font-weight: 600;
    font-size: 2rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__text {
    line-height: 150%;
    font-size: 1.6rem;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    padding-left: 20px;
    list-style: disc;
  }

  &__item {
    line-height: 150%;
    font-size: 1.6rem;
  }
}
</style>