<script setup>
import { computed, reactive, ref } from "vue";
import {ElNotification,} from "element-plus";
import { useI18n } from "vue-i18n";
import {useRouter} from "vue-router";
import UiPasswordHelper from "@/components/ui/ui-password-helper.vue";
import {Auth} from "@/core/api/auth.js";

const { t } = useI18n();
const router = useRouter()

const isPending = ref(false);

const fieldFill = reactive({
  hasMin: false,
  hasLowerUpper: false,
  hasNumber: false,
  hasSpecial: false,
});

const isPasswordFocus = ref(false);
const isPasswordHelperVisible = computed(() => {
  return isPasswordFocus.value;
});

const registerFormRef = ref();
const registerForm = reactive({
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  repeat_password: "",
  is_subscribed: false,
});

const rules = reactive({
  first_name: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.fName") }),
      trigger: "change",
    },
  ],
  last_name: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.lName") }),
      trigger: "change",
    },
  ],
  email: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.email") }),
      trigger: "change",
    },
    {
      message: t("fieldErrors.email"),
      validator(rule, value) {
        return /.+@.+\..+/.test(value);
      },
      trigger: "change",
    },
  ],
  password: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.password") }),
      trigger: "blur",
    },
    {
      message: t("fieldErrors.needLowerUpper"),
      validator(rule, value) {
        fieldFill.hasLowerUpper = !(
          !/[A-Z]/.test(value) || !/[a-z]/.test(value)
        );

        fieldFill.hasMin = value.length >= 8;

        fieldFill.hasNumber = /\d/.test(value);

        fieldFill.hasSpecial = /[#.?!@$%^&*'()_+=:;"'/>.<,|-]/.test(value);

        return true;
      },
      trigger: "change",
    },
  ],
  repeat_password: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.password") }),
      trigger: "change",
    },
    {
      message: t("fieldErrors.compare", { field: t("fields.password") }),
      trigger: "change",
      validator() {
        return registerForm.password === registerForm.repeat_password;
      },
    },
  ],
});

const createUser = async () => {
  isPending.value = true;

  const registrationInput = (registerForm);
  const result = await Auth.register(registrationInput);

  if (result?.data?.createUser?.success) {
    ElNotification({
      title: t("notifications.success.register.title"),
      message: t("notifications.success.register.text"),
      type: "success",
      duration: 5000,
      position: "bottom-left",
    });

    return;
  }

  ElNotification({
    title: t("notifications.error.register.title", {
      email: registerForm.email,
    }),
    message: t("notifications.error.register.text"),
    type: "error",
    duration: 5000,
    position: "bottom-left",
  });


  isPending.value = false;

  registerFormRef.value.resetFields();

  await router.push({
    name: 'sign-in',
    params: {
      locale: localStorage.getItem('user-locale') || 'en-GB'
    }
  })
};

const submitForm = async () => {
  if (!registerFormRef.value) return;
  await registerFormRef.value.validate(async (valid) => {
    if (!valid) return;
    await createUser();
  });
};

const isFormValid = computed(() => {
  return (
    /.+@.+\..+/.test(registerForm.email) &&
    registerForm.password.length !== 0 &&
    registerForm.password === registerForm.repeat_password &&
    fieldFill.hasLowerUpper &&
    fieldFill.hasMin &&
    fieldFill.hasNumber &&
    fieldFill.hasSpecial
  );
});

const isPasswordVisible = ref(false);
const isPasswordConfirmVisible = ref(false);
</script>

<template>
  <el-form
    ref="registerFormRef"
    class="form"
    :model="registerForm"
    :rules="rules"
    label-position="top"
  >
    <el-form-item class="form__element" prop="first_name">
      <el-input
        v-model="registerForm.first_name"
        size="large"
        :placeholder="t('fields.fNamePh')"
      />
    </el-form-item>
    <el-form-item class="form__element" prop="last_name">
      <el-input
        v-model="registerForm.last_name"
        size="large"
        :placeholder="t('fields.lNamePh')"
      />
    </el-form-item>
    <el-form-item class="form__element" prop="email">
      <el-input
        v-model="registerForm.email"
        size="large"
        :placeholder="t('fields.emailPh')"
      />
    </el-form-item>
    <el-form-item class="form__element" prop="password">
      <el-input
        v-model="registerForm.password"
        size="large"
        :placeholder="t('fields.passwordPh')"
        :type="isPasswordVisible ? 'text' : 'password'"
        @focus="() => (isPasswordFocus = true)"
        @blur="() => (isPasswordFocus = false)"
      >
        <template #suffix>
          <button
            class="form__switch"
            @click.prevent="() => (isPasswordVisible = !isPasswordVisible)"
            v-text="isPasswordVisible ? t('login.hide') : t('login.show')"
          />
        </template>
      </el-input>
    </el-form-item>
    <transition name="fade">
      <ui-password-helper v-if="isPasswordHelperVisible" :fill="fieldFill" />
    </transition>
    <el-form-item class="form__element" prop="repeat_password">
      <el-input
        v-model="registerForm.repeat_password"
        class="form__input"
        size="large"
        :placeholder="t('fields.passwordRPh')"
        :type="isPasswordConfirmVisible ? 'text' : 'password'"
      >
        <template #suffix>
          <button
            class="form__switch"
            @click.prevent="
              () => (isPasswordConfirmVisible = !isPasswordConfirmVisible)
            "
            v-text="
              isPasswordConfirmVisible ? t('login.hide') : t('login.show')
            "
          />
        </template>
      </el-input>
    </el-form-item>
    <el-form-item class="form__element form__subscribe" prop="is_subscribed">
      <el-checkbox
        v-model="registerForm.is_subscribed"
        :label="t('register.subscribe')"
      />
    </el-form-item>
    <el-button
      class="form__button"
      type="info"
      size="large"
      :disabled="!isFormValid"
      @click.prevent="submitForm"
    >
      {{ isPending ? t("login.loading") : t("register.button") }}
    </el-button>
  </el-form>
</template>

<style scoped lang="scss">
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;

  &__element {
    width: 100%;
  }

  &__switch {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: var(--el-text-color-secondary);
    margin: 0 !important;

    @include hover {
      color: var(--el-text-color-primary);
    }

    &:active {
      color: var(--el-text-color-primary);
    }
  }

  &__button {
    width: 100%;
    margin-top: 3rem;
  }
}
</style>