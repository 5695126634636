<script setup>
import { computed, reactive, ref } from "vue";
import {ElNotification} from "element-plus";
import { useI18n } from "vue-i18n";
import {useRouter} from "vue-router";
import {Auth} from "@/core/api/auth.js";

const { t } = useI18n();
const router = useRouter()

const isPending = ref(false);

const recoverFormRef = ref();
const recoverForm = reactive({
  email: "",
});
const rules = reactive({
  email: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.email") }),
      trigger: "change",
    },
    {
      message: t("fieldErrors.email"),
      validator(rule, value) {
        return /.+@.+\..+/.test(value);
      },
      trigger: "change",
    },
  ],
});

const submitForm = async () => {
  if (!recoverFormRef.value) return;
  await recoverFormRef.value.validate(async (valid) => {
    if (!valid) return;
    isPending.value = true;

    const result = await Auth.resetPassword(recoverForm.email);
    if (result.errors) {
      isPending.value = false;
      ElNotification({
        title: t("notifications.error.forgot.title", {
          email: recoverForm.email,
        }),
        duration: 5000,
        type: "error",
        position: "bottom-left",
      });

      if (recoverFormRef.value) {
        recoverFormRef.value.resetFields();
      }
      return;
    }

    isPending.value = false;

    ElNotification({
      title: t("notifications.success.forgot.title"),
      message: t("notifications.success.forgot.text", {
        email: recoverForm.email,
      }),
      duration: 5000,
      type: "success",
      position: "bottom-left",
    });

    if (recoverFormRef.value) {
      recoverFormRef.value.resetFields();
    }

    await router.push({
      name: 'home',
      params: {
        locale: localStorage.getItem('user-locale') || 'en-GB'
      }
    })
  });
};

const isFormValid = computed(() => {
  return /.+@.+\..+/.test(recoverForm.email);
});
</script>

<template>
  <el-form
    ref="recoverFormRef"
    class="form"
    :model="recoverForm"
    :rules="rules"
    label-position="top"
  >
    <el-form-item class="form__element" prop="email">
      <el-input
        v-model="recoverForm.email"
        size="large"
        :placeholder="t('fields.emailPh')"
      />
    </el-form-item>
    <el-button
      type="info"
      class="form__button"
      size="large"
      :disabled="!isFormValid"
      @click.prevent="submitForm"
      >{{ isPending ? t("login.loading") : t("forgot.button") }}</el-button
    >
  </el-form>
</template>

<style scoped lang="scss">
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;

  &__element {
    width: 100%;
  }

  &__switch {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: var(--el-text-color-secondary);
    margin: 0 !important;

    @include hover {
      color: var(--el-text-color-primary);
    }

    &:active {
      color: var(--el-text-color-primary);
    }
  }

  &__link {
    align-self: end;
    margin-top: -2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: var(--el-text-color-primary);
    font-weight: 500;

    @include hover {
      color: var(--el-color-primary);
    }

    &:active {
      color: var(--el-color-primary);
    }

    @include mobile {
      margin-top: 0;
    }
  }

  &__button {
    width: 100%;
    margin-top: 1rem;
  }
}
</style>