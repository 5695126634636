<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import UiFloatViewer from "@/components/ui/ui-float-viewer.vue";
import {useCartStore} from "@/stores/cart.js";
import UiBreadcrumbs from "@/components/ui/ui-breadcrumbs.vue";

const { t } = useI18n()
const cartStore = useCartStore()

const props = defineProps({
  image: String,
  runGameUrl: String,
  float: String,
  category: String,
  weapon: String,
  name: String,
  uuid: String
})
const breadcrumbs = computed(() => {
  const result = []

  result.push({
    displayName: props.category,
    query: { category: props.category }
  })

  // if (props.weapon) {
  //
  //   result.push({
  //     displayName: props.weapon,
  //     path: `/market?page=1&skin_type=${props.skin_type}&weapon=${props.weapon}`
  //   })
  //
  //   result.push({
  //     displayName: props.name,
  //     path: `/market?page=1&skin_type=${props.skin_type}&weapon=${props.weapon}&name=${props.name}`
  //   })
  //
  //   return result
  // }

  result.push({
    displayName: props.name,
    query: { name: props.name }
  })

  return result
})

const isOrderIncludeProduct = computed(() => {
  const el = cartStore.currentOrder?.orderProducts?.edges.find((el) => el.uuid === props.uuid)
  return !!el
})
</script>

<template>
  <div class="preview">
    <ui-breadcrumbs :links="breadcrumbs" class-name="preview__breadcrumbs" />
    <div class="preview__trade">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-clock-history"
          viewBox="0 0 16 16"
      >
        <path
            d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"
        />
        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
        <path
            d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"
        />
      </svg>
      <span v-text="t('product.state')" />
    </div>
    <div class="preview__image">
      <img :src="image" :alt="name" />
      <transition name="fade">
        <div v-if="isOrderIncludeProduct" class="preview__image-add">
          <div class="preview__image-add-inner">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-check2"
                viewBox="0 0 16 16"
            >
              <path
                  d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"
              />
            </svg>
          </div>
        </div>
      </transition>
    </div>
    <a v-if="runGameUrl" :href="runGameUrl" class="preview__link">
      <span v-text="t('productPage.runGame')" />
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-up-right"
          viewBox="0 0 16 16"
      >
        <path
            fill-rule="evenodd"
            d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
        />
      </svg>
    </a>
    <div v-if="float" class="preview__float">
      <ui-float-viewer :float="float" show-float />
    </div>
  </div>
</template>

<style scoped lang="scss">
.preview {
  width: 100%;
  background-color: var(--el-bg-color-overlay);
  padding: 5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;

  @include mobile {
    padding: 3rem 1.5rem;
  }

  @include hover {
    .preview__image-add {
      opacity: 0.3;
    }
  }

  &__breadcrumbs {
    margin-bottom: 2rem;
  }

  &__trade {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 2rem;
    font-weight: 700;
    color: var(--el-color-primary);
    margin-bottom: 4rem;

    & > svg {
      width: 2.5rem;
      height: 2.5rem;

      @include mobile {
        width: 2rem;
        height: 2rem;
      }
    }

    @include mobile {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }

  &__image {
    width: 100%;
    max-height: 30rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;

    & > img {
      height: 100%;
      max-height: 30rem;
      width: auto;

      @include mobile {
        max-height: 20rem;
      }
    }

    @include mobile {
      max-height: 20rem;
      margin-bottom: 3rem;
    }

    &-add {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(var(--el-bg-color-overlay), 0.5);
      transition: all 0.3s ease;

      &-inner {
        width: 10rem;
        height: 10rem;
        border-radius: 100%;
        background-color: var(--el-color-info);
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
          width: 50%;
          height: 50%;
        }
      }
    }
  }

  &__link {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-transform: uppercase;
    color: var(--el-text-color-primary);
    font-size: 1.5rem;
    font-family: 'Montserrat Medium', sans-serif;
    position: relative;
    z-index: 4;

    & > svg {
      width: 1.6rem;
      height: 1.6rem;
    }

    @include hover {
      color: var(--el-color-primary);
    }

    &:active {
      color: var(--el-color-primary);;
    }
  }

  &__float {
    width: 100%;
    margin-top: 2rem;
  }
}
</style>