import i18n from '@/core/plugins/i18n.config';

const translation = {
  get defaultLocale() {
    return 'en-GB'
  },

  get supportedLocales() {
    return ["en-GB", "de-DE", "fr-FR", "es-ES", "it-IT", "nl-NL"];
  },

  get currentLocale() {
    return i18n.global.locale.value
  },

  set currentLocale(newLocale) {
    i18n.global.locale.value = newLocale
  },

  switchLanguage(newLocale) {
    translation.currentLocale = newLocale

    document.querySelector('html').setAttribute('lang', newLocale)

    localStorage.setItem('user-locale', newLocale)
  },

  isLocaleSupported(locale) {
    if (locale) {
      return translation.supportedLocales.includes(locale);
    }
    return false;
  },

  getUserLocale() {
    const locale =
      window.navigator.language ||
      translation.defaultLocale

    return {
      locale: locale,
      localeNoRegion: locale.split('-')[0]
    }
  },

  getPersistedLocale() {
    const persistedLocale = localStorage.getItem('user-locale')

    if (translation.isLocaleSupported(persistedLocale)) {
      return persistedLocale
    } else {
      return null
    }
  },

  guessDefaultLocale() {
    const userPersistedLocale = translation.getPersistedLocale()
    if (userPersistedLocale) {
      return userPersistedLocale
    }

    const userPreferredLocale = translation.getUserLocale()

    if (translation.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale
    }

    if (translation.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      return userPreferredLocale.localeNoRegion
    }

    return translation.defaultLocale
  },

  async routeMiddleware(to, _from, next) {
    const paramLocale = to.params.locale

    if (!translation.isLocaleSupported(paramLocale)) {
      return next(translation.guessDefaultLocale())
    }

    await translation.switchLanguage(paramLocale)

    return next()
  },

  i18nRoute(to) {
    return {
      ...to,
      params: {
        locale: translation.currentLocale,
        ...to.params
      }
    }
  }
}

export default translation
