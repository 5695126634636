<script setup>
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useCartStore} from "@/stores/cart.js";
import translations from "@/core/helpers/translations.js";
import {Orders} from "@/core/api/orders.js";
import {useAuthStore} from "@/stores/auth.js";
import {ElNotification} from "element-plus";

const { t } = useI18n();
const cartStore = useCartStore();
const authStore = useAuthStore()

const order = computed(() => cartStore.currentOrder);
const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})
const userEmail = computed(() => {
  return authStore.user ? authStore.user.email : null
})
const userBalance = computed(() => {
  return authStore.user ? authStore.user.balance.amount : 0
})
const orderUuid = computed(() => {
  return cartStore.currentOrder.uuid ? cartStore.currentOrder.uuid : ''
})
const totalPrice = computed(() => {
  return cartStore.currentOrder.totalPrice ? cartStore.currentOrder.totalPrice : 0
})

const productsInCartLength = computed(() => {
  return cartStore.currentOrder.orderProducts ? cartStore.currentOrder.orderProducts.edges.length : 0
})

const isAgree = ref(false);

const isDisabled = computed(() => {
  if (!isAgree.value) return true;
  // return order.value.totalPrice < 5;
});

const buyOrder = async () => {
  if (userBalance.value < totalPrice.value) {
    const res = await Orders.buyOrder(accessToken.value, orderUuid.value, true, false)

    if (res.errors) {
      ElNotification({
        message: res.errors[0].message,
        type: "error",
        duration: 5000,
        position: "bottom-left",
      })
    } else {
      window.location.href = res.data.buyOrder.transaction.process.url
      // cartStore.setCurrentOrders(res.data.buyOrder.order)

      // ElNotification({
      //   message: h('div', [
      //     h('p', t('popup.successCheckout')),
      //     h(
      //       'button',
      //       {
      //         style: {
      //           marginTop: '10px',
      //           padding: '6px 12px',
      //           backgroundColor: '#FF6047',
      //           color: '#fff',
      //           border: 'none',
      //           borderRadius: '4px',
      //           cursor: 'pointer',
      //         },
      //         onClick: () => {
      //           router.push({
      //             name: 'profile',
      //             query: { page: 'orders' },
      //             params: {
      //               locale: localStorage.getItem('user-locale') || 'en-GB'
      //             }
      //           })
      //         }
      //       },
      //       t('buttons.goOrders')
      //     )
      //   ]),
      //   customClass: 'notification',
      //   offset: 50
      // })
    }
  } else {
    const res = await Orders.buyOrder(accessToken.value, orderUuid.value, false, true)

    // ElNotification({
    //   message: h('div', [
    //     h('p', t('popup.noBalance')),
    //     h(
    //       'button',
    //       {
    //         style: {
    //           marginTop: '10px',
    //           padding: '6px 12px',
    //           backgroundColor: '#FF6047',
    //           color: '#fff',
    //           border: 'none',
    //           borderRadius: '4px',
    //           cursor: 'pointer',
    //         },
    //         onClick: () => {
    //           router.push({
    //             name: 'profile',
    //             query: { page: 'balance' },
    //             params: {
    //               locale: localStorage.getItem('user-locale') || 'en-GB'
    //             }
    //           })
    //         }
    //       },
    //       t('buttons.topUp')
    //     )
    //   ]),
    //   customClass: 'notification',
    //   offset: 50
    // })

    if (res.errors) {
      ElNotification({
        message: res.errors[0].message,
        type: "error",
        duration: 5000,
        position: "bottom-left",
      })
    } else {
      const currentOrder = await Orders.getOrders("PENDING", accessToken.value, userEmail.value)
      cartStore.setCurrentOrders(currentOrder.data.orders.edges[0].node)

      ElNotification({
        title: t("notifications.success.checkout.title"),
        message: t("notifications.success.checkout.text"),
        type: "success",
        duration: 5000,
        position: "bottom-left",
      })
    }
  }
}
</script>

<template>
  <div class="checkout">
    <div class="checkout__head">
      <div class="checkout__head-inner">
        <h2 class="checkout__title" v-text="t('cart.checkout.title')" />
        <span
          class="checkout__head-total"
          v-text="
            t('cart.checkout.totalI', { total: productsInCartLength })
          "
        />
      </div>
      <router-link class="checkout__head-link" :to="translations.i18nRoute({ name: 'faq' })">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8 1.28A6.72 6.72 0 1 1 1.28 8 6.72 6.72 0 0 1 8 1.28ZM8 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8Z"/>
          <path d="M7.86 3.71A4.15 4.15 0 0 1 9.49 4a2.23 2.23 0 0 1 1 .87 2 2 0 0 1-.36 2.61 7.09 7.09 0 0 1-.74.59l-.56.43a.92.92 0 0 0-.27.4l-.14.33a.64.64 0 0 1-.59.4.77.77 0 0 1-.49-.11.77.77 0 0 1-.34-.68 1.35 1.35 0 0 1 .39-.92 6.62 6.62 0 0 1 .9-.78 2.22 2.22 0 0 0 .62-.64 1 1 0 0 0-.23-1.26 1.12 1.12 0 0 0-.47-.18 1.54 1.54 0 0 0-.77 0 1 1 0 0 0-.61.57c-.1.19-.19.38-.31.57a.75.75 0 0 1-.83.31.66.66 0 0 1-.58-.69 1.69 1.69 0 0 1 .3-.93 2.76 2.76 0 0 1 1.31-1 3.27 3.27 0 0 1 1.11-.22Zm-.09 8.58a.93.93 0 0 0 0-1.86.93.93 0 1 0 0 1.86Z"/>
        </svg>
        <span>FAQ</span>
      </router-link>
    </div>
    <div class="checkout__total">
      <span class="checkout__total-label" v-text="t('cart.checkout.total')" />
      <div class="checkout__total-price">
        <svg class="coin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0" fill="gold"/>
          <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" fill="gold"/>
          <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" fill="green"/>
          <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" fill="green"/>
        </svg>
        <span v-text="order.totalPrice" />
      </div>
    </div>
<!--    <div v-if="Number(order.totalPrice) < 5" class="checkout__note">-->
<!--      <h4 class="checkout__note-title" v-text="t('cart.checkout.note.title')" />-->
<!--      <p-->
<!--        class="checkout__note-text"-->
<!--        v-text="t('cart.checkout.note.text', { total: order.totalPrice })"-->
<!--      />-->
<!--      <router-link class="checkout__note-link" :to="translations.i18nRoute({ name: 'market' })">-->
<!--        {{ t("cart.checkout.note.link") }}-->
<!--      </router-link>-->
<!--    </div>-->
    <div class="checkout__tools">
      <el-checkbox v-model="isAgree">
        <div class="checkout__cb">
          <i18n-t tag="p" scope="global" keypath="cart.checkout.confirm">
            <template #link>
              <router-link
                class="checkout__tools-link"
                :to="translations.i18nRoute({ name: 'terms-of-use' })"
              >
                {{ t("cart.checkout.confirm_link") }}
              </router-link>
            </template>
          </i18n-t>
          <p v-text="t('cart.checkout.confirm_desc')" />
        </div>
      </el-checkbox>
      <el-button
        class="checkout__button"
        :disabled="isDisabled"
        type="info"
        size="large"
        @click="buyOrder"
      >
        {{ t("cart.checkout.button") }}
      </el-button>
      <i18n-t
        class="checkout__tools-text"
        tag="p"
        scope="global"
        keypath="cart.checkout.agree"
      >
        <template #button>
          <span class="checkout__tools-text--upper">
            {{ t("cart.checkout.button") }}
          </span>
        </template>
        <template #terms>
          <router-link
            class="checkout__tools-link"
            :to="translations.i18nRoute({ name: 'terms-of-use' })"
          >
            {{ t("terms.title") }}
          </router-link>
        </template>
        <template #privacy>
          <router-link
            class="checkout__tools-link"
            :to="translations.i18nRoute({ name: 'privacy-policy' })"
          >
            {{ t("privacy.title") }}
          </router-link>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<style scoped lang="scss">
.checkout {
  max-width: 448px;
  width: 100%;
  height: fit-content;
  padding: 3rem;
  background-color: var(--el-bg-darker);

  @include mobile-tablet {
    max-width: none;
    background-color: transparent;
  }

  @include mobile {
    padding: 3rem 2rem;
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 4rem;

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 1rem;
    }

    &-total {
      color: var(--el-text-color-secondary);
    }

    &-link {
      text-transform: uppercase;
      color: var(--el-text-color-primary);
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: 600;

      @include hover {
        color: var(--el-color-primary);
      }

      &:active {
        color: var(--el-color-primary);
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }

  &__total {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid var(--el-text-color-placeholder);
    padding-bottom: 3rem;
    margin-bottom: 4rem;

    &-label {
      font-size: 2.4rem;
      color: var(--el-text-color-secondary);
    }

    &-price {
      font-size: 2.4rem;
      font-weight: 600;
      color: var(--el-text-color-primary);
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }
  }

  &__note {
    width: 100%;
    background-color: var(--el-text-color-primary);
    padding: 3rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    margin-bottom: 4rem;

    &-title {
      font-weight: 700;
      color: var(--el-color-primary);
    }

    &-text {
      line-height: 130%;
      color: var(--el-bg-color-page);
      font-weight: 500;
      margin-bottom: 1rem;
    }

    &-link {
      width: 100%;
      color: var(--el-color-info);
      font-weight: 700;
      text-transform: uppercase;

      @include hover {
        color: var(--el-color-info-light-5);
      }

      &:active {
        color: var(--el-color-info-dark-2);
      }
    }
  }

  &__tools {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    &-text {
      color: var(--el-text-color-secondary);
      line-height: 130%;

      &--upper {
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    &-link {
      font-weight: 600;
      color: var(--el-color-info);

      @include hover {
        color: var(--el-color-info-light-5);
      }

      &:active {
        color: var(--el-color-info-dark-2);
      }
    }
  }

  &__cb {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    line-height: 130%;
  }

  &__button {
    width: 100%;
    margin-top: 10rem;
    text-transform: uppercase;
  }
}
</style>