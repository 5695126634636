<script setup>
import FormsSearch from "@/components/forms/forms-search.vue";

const emits = defineEmits(['close'])
</script>

<template>
<div class="mobile-search mobile">
  <forms-search />
  <button
      class="mobile-search__button"
      @click="emits('close')"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill="currentColor">
      <path d="M9.138 10.5.282 1.645A.963.963 0 1 1 1.645.282L10.5 9.138 19.355.282a.963.963 0 0 1 1.363 1.363L11.862 10.5l8.856 8.855a.963.963 0 0 1-1.363 1.363L10.5 11.862l-8.855 8.856a.963.963 0 0 1-1.363-1.363L9.138 10.5z"/>
    </svg>
  </button>
</div>
</template>

<style scoped lang="scss">
.mobile-search {
  background-color: $el-bg-color-overlay;
  min-height: 5rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  padding: 0 2rem;

  &__button {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    color: var(--el-text-color-primary);

    & > svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>