<script setup>
import { useI18n } from "vue-i18n";
import { computed, reactive, ref } from "vue";
import {ElNotification,} from "element-plus";
import { genFileId } from "element-plus";

const { t } = useI18n();

const isPending = ref(false);

const formRef = ref();

const upload = ref();

const handleExceed = (files) => {
  upload.value.clearFiles();
  const file = files[0];
  file.uid = genFileId();
  upload.value.handleStart(file);
};

const form = reactive({
  first_name: "",
  last_name: "",
  email: "",
  message: "",
  file: null,
});

const rules = reactive({
  first_name: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.fName") }),
      trigger: "change",
    },
  ],
  email: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.email") }),
      trigger: "change",
    },
    {
      message: t("fieldErrors.email"),
      validator(rule, value) {
        return /.+@.+\..+/.test(value);
      },
      trigger: "change",
    },
  ],
  message: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.message") }),
      trigger: "change",
    },
  ],
});

const isFormValid = computed(() => {
  return !!form.message && /.+@.+\..+/.test(form.email) && !!form.first_name;
});

const submitForm = async () => {
  if (!formRef.value) return;
  await formRef.value.validate(async (valid) => {
    if (!valid) return;
    isPending.value = true;

    setTimeout(() => {
      ElNotification({
        title: t("notifications.success.job.title"),
        message: t("notifications.success.job.text"),
        duration: 5000,
        type: "success",
        position: "bottom-left",
      });

      isPending.value = false;
      formRef.value.resetFields();
    }, 2500);
  });
};
</script>

<template>
  <el-form
    ref="formRef"
    class="form"
    :model="form"
    :rules="rules"
    label-position="top"
  >
    <div class="form__box">
      <el-form-item
        class="form__element"
        prop="first_name"
        :label="t('fields.fName')"
      >
        <el-input
          v-model="form.first_name"
          size="large"
          :placeholder="t('fields.fNamePh')"
        />
      </el-form-item>
      <el-form-item
        class="form__element"
        prop="last_name"
        :label="t('fields.lName')"
      >
        <el-input
          v-model="form.last_name"
          size="large"
          :placeholder="t('fields.lNamePh')"
        />
      </el-form-item>
    </div>
    <el-form-item class="form__element" prop="email" :label="t('fields.email')">
      <el-input
        v-model="form.email"
        size="large"
        :placeholder="t('fields.emailPh')"
      />
    </el-form-item>
    <el-form-item
      class="form__element"
      prop="message"
      :label="t('fields.message')"
    >
      <el-input
        v-model="form.message"
        size="large"
        type="textarea"
        resize="none"
        :autosize="{ minRows: 3, maxRows: 6 }"
        :placeholder="t('fields.messagePh')"
      />
    </el-form-item>
    <el-form-item class="form__element" prop="file" :label="t('job.form.cv')">
      <el-upload
        ref="upload"
        class="form__element"
        :limit="1"
        :on-exceed="handleExceed"
        :auto-upload="false"
      >
        <template #trigger>
          <el-button
            type="info"
            @click.prevent="() => console.log('file uploading')"
            >{{ t("job.form.add") }}</el-button
          >
        </template>
      </el-upload>
    </el-form-item>
    <el-button
      type="primary"
      class="form__button"
      :disabled="!isFormValid"
      @click.prevent="submitForm"
      >{{ isPending ? t("job.form.loading") : t("job.form.button") }}</el-button
    >
  </el-form>
</template>

<style scoped lang="scss">
.form {
  width: 80%;
  border-radius: 0.5rem;
  padding: 2rem;
  background-color: var(--el-bg-color);
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;

  @include mobile {
    width: 100%;
    gap: 1rem;
  }

  &__box {
    width: 100%;
    display: flex;
    gap: 2rem;

    @include mobile {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__element {
    width: 100%;
  }

  &__button {
    align-self: center;

    @include mobile {
      width: 100%;
    }
  }
}
</style>