<script setup>
import {useI18n} from "vue-i18n";
import UiAnimatedBackground from "@/components/ui/ui-animated-background.vue";
import FormsTradeUrl from "@/components/forms/forms-trade-url.vue";

const { t } = useI18n();
</script>

<template>
  <div class="container">
    <div class="url">
      <div class="url__inner">
        <h1 class="url__title" v-text="t('account.tradeUrl.title')" />
        <p class="url__text" v-text="t('account.tradeUrl.text')" />
        <forms-trade-url />
      </div>
    </div>
    <ui-animated-background />
  </div>
</template>

<style scoped lang="scss">
.url {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 12rem);

  @include tablet {
    height: calc(100vh - 6rem);
  }

  @include mobile {
    height: calc(100vh - 5rem);
  }

  &__inner {
    max-width: 650px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    @include mobile {
      gap: 3rem;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;

    @include mobile {
      font-size: 20px;
    }
  }

  &__text {
    text-align: center;
    font-size: 14px;
    color: var(--el-text-color-secondary);
  }
}
</style>