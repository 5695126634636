<script setup>
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import {useAppStore} from "@/stores/app.js";
import {useAuthStore} from "@/stores/auth.js";
import {useRouter} from "vue-router";
import {useCartStore} from "@/stores/cart.js";
import CartWidgetCard from "@/components/cart/cart-widget-card.vue";
import {Orders} from "@/core/api/orders.js";

const { t } = useI18n();
const cartStore = useCartStore();
const appStore = useAppStore();
const authStore = useAuthStore();
const router = useRouter()

const order = computed(() => cartStore.currentOrder);
const accessToken = computed(() => authStore.accessToken);
const orderUuid = computed(() => {
  return cartStore.currentOrder.uuid ? cartStore.currentOrder.uuid : null
})
const productsInCart = computed(() => {
  return cartStore.currentOrder.orderProducts ? cartStore.currentOrder.orderProducts.edges :[]
})

const closeCartWidget = () => {
  appStore.isHeaderCartVisible = false;
};

const isButtonDisabled = ref(false);

const removeAllProductsFromCart = async () => {
  isButtonDisabled.value = true;

  const res = await Orders.deleteOrder(accessToken.value, orderUuid.value);
  cartStore.setCurrentOrders(res.data.removeAllOrderProducts.order)

  isButtonDisabled.value = false;
};

const redirectToCart = async () => {
  appStore.isHeaderCartVisible = false;

  await router.push({
    name: "cart",
    params: {
      locale: localStorage.getItem('user-locale') || 'en-GB'
    },
  });
};

const isViewButtonDisabled = computed(() => {
  return productsInCart.value.length;
});
</script>

<template>
  <div class="cart">
    <div class="cart__head">
      <span
        class="cart__title"
        v-text="t('cartW.title', { count: productsInCart.length })"
      />
      <button class="cart__close" @click="closeCartWidget">
        <svg xmlns="http://www.w3.org/2000/svg" id="close" viewBox="0 0 21 21" fill="currentColor">
          <path d="M9.138 10.5.282 1.645A.963.963 0 1 1 1.645.282L10.5 9.138 19.355.282a.963.963 0 0 1 1.363 1.363L11.862 10.5l8.856 8.855a.963.963 0 0 1-1.363 1.363L10.5 11.862l-8.855 8.856a.963.963 0 0 1-1.363-1.363L9.138 10.5z"/>
        </svg>
      </button>
    </div>
    <div v-if="!productsInCart.length" class="cart__empty">
      <span v-text="t('cartW.empty')" />
    </div>
    <div v-else class="cart__list">
      <transition-group name="fromLeft">
        <cart-widget-card
          v-for="product of productsInCart"
          :key="product.node.product.uuid"
          :product="product.node"
        />
      </transition-group>
    </div>
    <div class="cart__tools">
      <div class="cart__total">
        <span v-text="t('cartW.total')" />
        <div class="cart__total-value">
          <img src="@icons/coin.svg" alt="coin" />
          <span v-text="order.totalPrice" />
        </div>
      </div>
      <div class="cart__buttons">
        <el-button
          class="cart__button"
          type="info"
          size="large"
          :disabled="!isViewButtonDisabled"
          @click="redirectToCart"
        >
          {{ t("cartW.button") }}
        </el-button>
        <el-button
          v-if="productsInCart.length"
          class="cart__button"
          text
          size="large"
          :disabled="isButtonDisabled"
          @click="removeAllProductsFromCart"
        >
          {{ t("cartW.clear") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cart {
  position: fixed;
  z-index: 990;
  top: 7rem;
  background-color: $el-bg-darker;
  box-shadow: 0 1rem 4rem #1d2021;
  right: 5rem;
  min-width: 60rem;
  width: 100%;
  max-width: 60rem;
  min-height: 60vh;
  display: flex;
  max-height: calc(100vh - 15rem);
  flex-direction: column;
  overflow: hidden;

  @include mobile-tablet {
    top: 6rem;
    right: 2rem;
  }

  @include mobile {
    top: 5rem;
    left: 0;
    right: 0;
    width: 100%;
    height: fit-content;
    min-width: 0;

    max-height: calc(100vh - 5rem);
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;

    @include mobile {
      padding: 1.5rem;
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 700;

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__close {
    position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $el-text-color-primary;

    & svg {
      width: 20px;
    }

    &::after {
      width: 100%;
      height: 100%;
      background: none;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      transform: scale(0);
      transition-duration: 0.25s;
      transition-property: transform, opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      content: "";
      z-index: -1;
    }

    @include hover {
      color: $el-color-primary;

      &::after {
        background: rgba(29, 32, 33, 45%);
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &__list {
    width: 100%;
    flex: 1;
    max-height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;

    @include mobile {
      max-height: 64vh;
    }

    &::-webkit-scrollbar {
      width: 1.3rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $el-bg-color-overlay;
      border: 2px solid $el-bg-darker;
      transition: all 0.3s ease;
      border-radius: 0.3rem;

      @include hover {
        background-color: $el-text-color-secondary;
      }
    }
  }

  &__empty {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      font-size: 2rem;
      font-weight: 600;
      color: $el-text-color-secondary;
    }
  }

  &__tools {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    @include mobile {
      padding: 1.5rem;
    }
  }

  &__total {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
      font-size: 2rem;
      color: $el-text-color-secondary;
    }

    &-value {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > span {
        font-size: 2.2rem;
        font-weight: 700;
      }

      & > img {
        width: 2.5rem;
        height: 2.5rem;
        transform: rotate(15deg);
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__button {
    width: 100%;
    height: 5.5rem;
  }
}
</style>