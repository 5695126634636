import {apiTemplate} from "@/core/helpers/api.js";

export class Categories {
  static async getCategories() {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `query {
        categories {
          edges {
            node {
              name
              uuid
              image
              description
            }
          }
        }
      }`
    );

    return await response;
  }

  static async getCategoryByName(category) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `query {
        categories (name: "${category}") {
          edges {
            node {
              name
              uuid
              image
              description
              filterableAttributes {
                possibleValues
                attributeName
              }
              minMaxPrices {
                maxPrice
                minPrice
              }
            }
          }
        }
      }`
    );

    return await response;
  }
}