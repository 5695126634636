<script setup>
import {useI18n} from "vue-i18n";
import translations from "@/core/helpers/translations.js";

const {t} = useI18n()
</script>

<template>
  <div class="empty">
    <h1 class="empty__title" v-text="t('wishlist.empty.title')" />
    <p class="empty__text" v-text="t('wishlist.empty.text')" />
    <router-link
      class="el-button el-button--info el-button--large empty__button empty__button"
      :to="translations.i18nRoute({ name: 'market' })"
    >
      {{ t("wishlist.empty.button") }}
    </router-link
    >
  </div>
</template>

<style scoped lang="scss">
.empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  background-color: var(--el-bg-color);
  padding: 4rem 2rem;
  min-height: 80vh;

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }

  &__text {
    color: var(--el-text-color-secondary);
    text-align: center;
    letter-spacing: 0.08rem;
    font-size: 14px;
    max-width: 640px;
    line-height: 140%;
  }

  &__button {
    text-transform: uppercase;

    @include mobile {
      font-size: 1.2rem !important;
    }
  }
}
</style>