<script setup>
import {computed} from "vue";
import {useAppStore} from "@/stores/app.js";
import BaseHeader from "@/components/base/header/base-header.vue";
import BaseArticleMenu from "@/components/base/article/base-article-menu.vue";
import BaseArticleFilters from "@/components/base/article/base-article-filters.vue";
import BaseFooter from "@/components/base/footer/base-footer.vue";

const appStore = useAppStore();

const isMenuVisible = computed(() => appStore.isMenuVisible);
const isFiltersVisible = computed(
  () => appStore.isMobilFiltersVisible
);

const isOverlayVisible = computed(() => {
  return isMenuVisible.value || isFiltersVisible.value;
});
const hideArticles = () => {
  appStore.isMobilFiltersVisible = false;
  appStore.isMenuVisible = false;
};
</script>

<template>
  <div class="layout">
    <base-article-menu />
    <div
      :class="[
        'layout__inner',
        {
          'layout__inner--hidden': isMenuVisible,
          'layout__inner--hidden2': isFiltersVisible,
        },
      ]"
    >
      <base-header />
      <main class="main">
        <RouterView v-slot="{ Component }">
          <Transition name="opacity" mode="out-in">
            <component :is="Component" />
          </Transition>
        </RouterView>
      </main>
      <base-footer />
      <transition name="fade">
        <div
          v-if="isOverlayVisible"
          class="layout__overlay"
          @click="hideArticles"
        />
      </transition>
    </div>
    <base-article-filters />
  </div>
</template>

<style scoped lang="scss">
.layout {
  width: 100%;
  overflow-x: hidden;

  &__inner {
    min-height: 100vh;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    transition: all 0.3s ease;
    position: relative;

    &--hidden {
      max-height: 100vh;
      overflow-y: hidden;

      @include tablet {
        transform: translateX(40vw);
      }

      @include mobile {
        transform: translateX(100vw);
      }
    }

    &--hidden2 {
      max-height: 100vh;
      overflow-y: hidden;

      @include tablet {
        transform: translateX(-50vw);
      }

      @include mobile {
        transform: translateX(-100vw);
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: $el-bg-color-overlay;
    opacity: 0.5;
    cursor: pointer;
    display: none;

    @include mobile-tablet {
      display: block;
    }
  }
}

.main {
  width: 100%;
  flex: 1;
  margin-top: 117px;

  @include tablet {
    margin-top: 6rem;
  }

  @include mobile {
    margin-top: 5rem;
  }
}
</style>
