import { useCookies } from "@vueuse/integrations/useCookies";
import {useAppStore} from "@/stores/app.js";
import {useAuthStore} from "@/stores/auth.js";
import {useRouter} from "vue-router";

export const logoutUser = (router) => {
  const authStore = useAuthStore();
  const appStore = useAppStore();
  const cookies = useCookies();

  authStore.setUser({ user: null, access: null });
  appStore.isHeaderToolsVisible = false;
  cookies.remove("refresh_token");

  router.push({
    name: "home",
    params: {
      locale: localStorage.getItem('user-locale') || 'en-GB'
    },
  });
};
