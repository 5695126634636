<script setup>
import { useCookies } from "@vueuse/integrations/useCookies";
import {useAuthStore} from "@/stores/auth.js";
import {useWishlistStore} from "@/stores/wishlist.js";
import {useCartStore} from "@/stores/cart.js";
import {onMounted} from "vue";
import {Auth} from "@/core/api/auth.js";
import {Processes} from "@/core/api/processes.js";
import {Orders} from "@/core/api/orders.js";
import {Wishlist} from "@/core/api/wishlist.js";
import {useCompanyStore} from "@/stores/company.js";
import translation from "@/core/helpers/translations.js";
import translations from "@/core/helpers/translations.js";
import {useRoute, useRouter} from "vue-router";

const authStore = useAuthStore();
const cartStore = useCartStore();
const companyStore = useCompanyStore();
const wishlistStore = useWishlistStore()
const route = useRoute()
const router = useRouter()
const cookies = useCookies(["refresh_token"]);

const authenticateUser = async () => {
  const [res, companyRes] = await Promise.all([Auth.refresh(), Processes.getCompanyInfo()]);

  if (companyRes) {
    companyStore.setCompanyInfo(companyRes.data.parameters)
  }

  if (!res.errors && res.data) {
    authStore.setUser({
      user: res.data.refreshJwtToken.user,
      accessToken: res.data.refreshJwtToken.accessToken
    })

    if (res.data.refreshJwtToken.user.language !== translation.currentLocale) {
      translations.switchLanguage(res.data.refreshJwtToken.user.language)
      await router.push({
        name: route.name,
        params: {
          locale: localStorage.getItem('user-locale') || 'en-GB'
        }
      })
    }

    cookies.set("refresh_token", res.data.refreshJwtToken.refreshToken, { path: "/" });

    const currentOrder = await Orders.getOrders("PENDING", res.data.refreshJwtToken.accessToken, res.data.refreshJwtToken.user.email)
    cartStore.setCurrentOrders(currentOrder.data.orders.edges[0].node)

    const currentWishlist = await Wishlist.getWishlist(res.data.refreshJwtToken.accessToken);
    wishlistStore.wishlist = currentWishlist.data.wishlists.edges[0].node
  }
};

onMounted(async () => {
  await authenticateUser();

  setInterval(async () => {
    await authenticateUser()
  }, 750000)
});
</script>

<template>
  <div class="main" id="top">
    <RouterView v-slot="{ Component }" id="top">
      <Transition name="page-opacity" mode="out-in">
        <component :is="Component" />
      </Transition>
    </RouterView>
  </div>
</template>

<style lang="scss">
:root {
  --el-color-primary: #fa490a;
  --el-color-primary-light-3: #f85e27;
  --el-color-primary-light-5: #fa6b38;
  --el-color-primary-light-7: #fa784a;
  --el-color-primary-light-8: #f88259;
  --el-color-primary-light-9: #f88e69;
  --el-color-primary-dark-2: #c53a09;
  --el-color-success: #167e8d;
  --el-color-success-light-3: #20a4a9;
  --el-color-success-light-5: #2cc5bd;
  --el-color-success-light-7: #5bdcca;
  --el-color-success-light-8: #7eedd4;
  --el-color-success-light-9: #abf9e0;
  --el-color-success-dark-2: #0e5c72;
  --el-color-warning: #dbb039;
  --el-color-warning-light-3: #ffd54f;
  --el-color-warning-light-5: #ffe27a;
  --el-color-warning-light-7: #ffeb95;
  --el-color-warning-light-8: #fff3b8;
  --el-color-warning-light-9: #fffadb;
  --el-color-warning-dark-2: #b78e27;
  --el-color-danger: #b7473b;
  --el-color-danger-light-3: #db6c56;
  --el-color-danger-light-5: #ff9777;
  --el-color-danger-light-7: #ffb899;
  --el-color-danger-light-8: #ffccad;
  --el-color-danger-light-9: #ffe1c8;
  --el-color-danger-dark-2: #932825;
  --el-color-error: #f56c6c;
  --el-color-error-light-3: #f89898;
  --el-color-error-light-5: #fab6b6;
  --el-color-error-light-7: #fcd3d3;
  --el-color-error-light-8: #fde2e2;
  --el-color-error-light-9: #fef0f0;
  --el-color-error-dark-2: #c45656;
  --el-color-info: #4db5da;
  --el-color-info-light-3: #6ABCDA;
  --el-color-info-light-5: #76c3df;
  --el-color-info-light-7: #80c8e3;
  --el-color-info-light-8: #8bcfe8;
  --el-color-info-light-9: #98d6ed;
  --el-color-info-dark-2: #449EBD;
  --el-bg-color-page: #1d2021;
  --el-bg-color-overlay: #232728;
  --el-bg-color: #2b2f30;
  --el-bg-darker: #323738;
  --el-bg-light: #3b4142;
  --el-text-color-primary: #F6F6F6;
  --el-text-color-regular: #F6F6F6;
  --el-text-color-secondary: #717777;
  --el-text-color-placeholder: #525455;
  --el-text-color-disabled: #c0c4cc;
  --el-border-color: #dcdfe6;
  --el-border-color-light: #e4e7ed;
  --el-border-color-lighter: #ebeef5;
  --el-border-color-extra-light: #f2f6fc;
  --el-border-color-dark: #d4d7de;
  --el-border-color-darker: #cdd0d6;
  --el-fill-color: #f0f2f5;
  --el-fill-color-light: #f5f7fa;
  --el-fill-color-lighter: #fafafa;
  --el-fill-color-extra-light: #fafcff;
  --el-fill-color-dark: #ebedf0;
  --el-fill-color-darker: #e6e8eb;
  --el-fill-color-blank: #ffffff;
  --el-box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.12);
  --el-box-shadow-light: 0 8px 16px 0 rgba(96, 97, 112, 0.16), 0 2px 4px 0 rgba(40, 41, 61, 0.04);
  --el-box-shadow-lighter: 0px 0px 6px rgba(0, 0, 0, 0.12);
  --el-box-shadow-dark: 0px 16px 48px 16px rgba(0, 0, 0, 0.08), 0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  --el-disabled-bg-color: var(--el-fill-color-light);
  --el-disabled-text-color: var(--el-text-color-placeholder);
  --el-disabled-border-color: var(--el-border-color-light);
  --el-overlay-color: rgba(0, 0, 0, 0.8);
  --el-overlay-color-light: rgba(0, 0, 0, 0.7);
  --el-overlay-color-lighter: rgba(0, 0, 0, 0.5);
  --el-mask-color: rgba(255, 255, 255, 0.9);
  --el-mask-color-extra-light: rgba(255, 255, 255, 0.3);
  --el-border-width: 1px;
  --el-border-style: solid;
  --el-border-color-hover: var(--el-text-color-disabled);
  --el-border: var(--el-border-width) var(--el-border-style) var(--el-border-color);
  --el-svg-monochrome-grey: var(--el-border-color);
  --el-component-size-large: 48px !important;
  --el-component-size: 40px !important;
  --el-component-size-small: 32px !important;
  --el-border-radius-base: 5px !important;
  --el-border-radius-small: 2px !important;
  --el-border-radius-circle: calc(100% - 1px) !important;
  --el-input-icon-color: #f6f6f6;
}
.el-button {
  --el-button-font-weight: 500;
  --el-button-border-color: #dcdfe6;
  --el-button-bg-color: #FFF;
  --el-button-text-color: #FFF;
  --el-button-disabled-text-color: #525455;
  --el-button-disabled-bg-color: #FFF;
  --el-button-disabled-border-color: #e4e7ed;
  --el-button-divide-border-color: rgba(255,255,255,0.5);
  --el-button-hover-text-color: #fa490a;
  --el-button-hover-bg-color: #f88e69;
  --el-button-hover-border-color: #fa784a;
  --el-button-active-text-color: #fa490a;
  --el-button-active-border-color: #fa490a;
  --el-button-active-bg-color: #f88e69;
  --el-button-outline-color: #fa6b38;
  --el-button-hover-link-text-color: #4db5da;
  --el-button-active-color: #f6f6f6;
}
.el-button--info {
  --el-button-text-color: #fff;
  --el-button-bg-color: #4db5da;
  --el-button-border-color: #4db5da;
  --el-button-outline-color: #76c3df;
  --el-button-active-color: #449ebd;
  --el-button-hover-text-color: #fff;
  --el-button-hover-link-text-color: #76c3df;
  --el-button-hover-bg-color: #6abcda;
  --el-button-hover-border-color: #6abcda;
  --el-button-active-bg-color: #449ebd;
  --el-button-active-border-color: #449ebd;
  --el-button-disabled-text-color: #fff;
  --el-button-disabled-bg-color: #76c3df;
  --el-button-disabled-border-color: #76c3df;
}
.el-input {
  --el-input-text-color: #f6f6f6;
  --el-input-border: 1px solid #dcdfe6;
  --el-input-hover-border: #c0c4cc;
  --el-input-focus-border: #fa490a;
  --el-input-transparent-border: 0 0 0 1px transparent inset;
  --el-input-border-color: #dcdfe6;
  --el-input-border-radius: 5px;
  --el-input-bg-color: #fff;
  --el-input-icon-color: #525455;
  --el-input-placeholder-color: #525455;
  --el-input-hover-border-color: #c0c4cc;
  --el-input-clear-hover-color: #717777;
  --el-input-focus-border-color: #fa490a;
  --el-input-width: 100%;
  --el-input-height: 38px;
}
.el-select {
  --el-select-input-focus-border-color: #fa490a;
}

//.el-button {
//  --el-button-font-weight: 500;
//  --el-button-border-color: $el-border-color;
//  --el-button-bg-color: #FFF;
//  --el-button-text-color: #FFF;
//  --el-button-disabled-text-color: $el-text-color-placeholder;
//  --el-button-disabled-bg-color: #FFF;
//  --el-button-disabled-border-color: $el-border-color-light;
//  --el-button-divide-border-color: rgba(255,255,255,0.5);
//  --el-button-hover-text-color: $el-color-primary;
//  --el-button-hover-bg-color: $el-color-primary-light-9;
//  --el-button-hover-border-color: $el-color-primary-light-7;
//  --el-button-active-text-color: $el-color-primary;
//  --el-button-active-border-color: $el-color-primary;
//  --el-button-active-bg-color: $el-color-primary-light-9;
//  --el-button-outline-color: $el-color-primary-light-5;
//  --el-button-hover-link-text-color: $el-color-info;
//  --el-button-active-color: $el-text-color-primary;
//}
//.el-button--info {
//  --el-button-text-color: #fff;
//  --el-button-bg-color: $el-color-info;
//  --el-button-border-color: $el-color-info;
//  --el-button-outline-color: $el-color-info-light-5;
//  --el-button-active-color: $el-color-info-dark-2;
//  --el-button-hover-text-color: #fff;
//  --el-button-hover-link-text-color: $el-color-info-light-5;
//  --el-button-hover-bg-color: $el-color-info-light-3;
//  --el-button-hover-border-color: $el-color-info-light-3;
//  --el-button-active-bg-color: $el-color-info-dark-2;
//  --el-button-active-border-color: $el-color-info-dark-2;
//  --el-button-disabled-text-color: #fff;
//  --el-button-disabled-bg-color: $el-color-info-light-5;
//  --el-button-disabled-border-color: $el-color-info-light-5;
//}
//.el-input {
//  --el-input-text-color: $el-text-color-primary;
//  --el-input-border: 1px solid $el-border-color;
//  --el-input-hover-border: $el-text-color-disabled;
//  --el-input-focus-border: $el-color-primary;
//  --el-input-transparent-border: 0 0 0 1px transparent inset;
//  --el-input-border-color: $el-border-color;
//  --el-input-border-radius: 5px;
//  --el-input-bg-color: #fff;
//  --el-input-icon-color: $el-text-color-placeholder;
//  --el-input-placeholder-color: $el-text-color-placeholder;
//  --el-input-hover-border-color: $el-text-color-disabled;
//  --el-input-clear-hover-color: $el-text-color-secondary;
//  --el-input-focus-border-color: $el-color-primary;
//  --el-input-width: 100%;
//  --el-input-height: 38px;
//}

.el-scrollbar__bar.is-horizontal {
  height: 3px;
}
.el-scrollbar__thumb {
  background-color: #525455;
  opacity: 1;

  &:hover {
    background-color: #525455;
    opacity: 1;
  }
}
</style>
