<script setup>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import en from "@/locales/en-GB.json";
import UiAnimatedBackground from "@/components/ui/ui-animated-background.vue";
import translations from "@/core/helpers/translations.js";

const { t } = useI18n();

const content = ref(en.faq.content);
</script>

<template>
  <div>
    <ui-animated-background />
    <div class="container">
      <section class="support">
        <div class="support__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 19 19"
          >
            <path
              d="M3.364 16.092A9 9 0 1 1 16.092 3.364 9 9 0 0 1 3.364 16.092zm1.414-1.414a7 7 0 1 0 9.9-9.9 7 7 0 0 0-9.9 9.9zm1.414-1.415a5 5 0 1 1 7.071-7.07 5 5 0 0 1-7.07 7.07zm1.415-1.414a3 3 0 1 0 4.242-4.242 3 3 0 0 0-4.242 4.242zm-.582-6.172c.301.314.233.873-.153 1.25-.386.376-.944.427-1.246.113l-1.68-1.746c-.302-.314-.234-.874.153-1.25.386-.377.943-.428 1.245-.114l1.68 1.747zm8.485 8.485c.302.314.233.874-.153 1.25-.386.377-.944.427-1.245.114l-1.68-1.747c-.303-.314-.234-.873.152-1.25.386-.376.944-.427 1.245-.114l1.68 1.747zM14.162 3.946c.314-.302.874-.234 1.25.153.377.386.427.943.114 1.245l-1.747 1.68c-.314.302-.873.234-1.25-.152-.376-.386-.427-.944-.114-1.246l1.747-1.68zM5.677 12.43c.314-.302.873-.233 1.25.153.376.386.427.944.113 1.245l-1.746 1.68c-.314.303-.874.234-1.25-.152-.377-.386-.428-.944-.114-1.245l1.747-1.68z"
            />
          </svg>
        </div>
        <h1 class="support__title" v-text="t('support.title')" />
        <p class="support__subtitle" v-text="t('support.subtitle')" />
        <div class="support__content">
          <h4
            class="support__content-title"
            v-text="t('support.description')"
          />
          <div class="support__content-content">
            <div class="support__content-box">
              <div class="navigation">
                <h4
                  class="navigation__title"
                  v-text="t(`faq.content[${0}].title`)"
                />
                <div class="navigation__list">
                  <router-link
                    v-for="(anchor, answerIdx) of content[0].answers"
                    :key="answerIdx"
                    class="navigation__button"
                    :to="
                      translations.i18nRoute({
                        name: 'faq',
                        hash: `#${t(
                          `faq.content[${0}].answers[${answerIdx}].title`,
                        )
                          .toLowerCase()
                          .split(' ')
                          .join('-')}`,
                      })
                    "
                  >
                    <span
                      v-text="
                        t(`faq.content[${0}].answers[${answerIdx}].title`)
                      "
                    />
                  </router-link>
                </div>
              </div>
              <div class="navigation">
                <h4
                  class="navigation__title"
                  v-text="t(`faq.content[${2}].title`)"
                />
                <div class="navigation__list">
                  <router-link
                    v-for="(anchor, answerIdx) of content[2].answers"
                    :key="answerIdx"
                    class="navigation__button"
                    :to="
                      translations.i18nRoute({
                        name: 'faq',
                        hash: `#${t(
                          `faq.content[${2}].answers[${answerIdx}].title`,
                        )
                          .toLowerCase()
                          .split(' ')
                          .join('-')}`,
                      })
                    "
                  >
                    <span
                      v-text="
                        t(`faq.content[${2}].answers[${answerIdx}].title`)
                      "
                    />
                  </router-link>
                </div>
              </div>
            </div>
            <div class="support__content-box">
              <div class="navigation">
                <h4
                  class="navigation__title"
                  v-text="t(`faq.content[${1}].title`)"
                />
                <div class="navigation__list">
                  <router-link
                    v-for="(anchor, answerIdx) of content[1].answers"
                    :key="answerIdx"
                    class="navigation__button"
                    :to="
                      translations.i18nRoute({
                        name: 'faq',
                        hash: `#${t(
                          `faq.content[${1}].answers[${answerIdx}].title`,
                        )
                          .toLowerCase()
                          .split(' ')
                          .join('-')}`,
                      })
                    "
                  >
                    <span
                      v-text="
                        t(`faq.content[${1}].answers[${answerIdx}].title`)
                      "
                    />
                  </router-link>
                </div>
              </div>
              <div class="navigation">
                <h4
                  class="navigation__title"
                  v-text="t(`faq.content[${3}].title`)"
                />
                <div class="navigation__list">
                  <router-link
                    v-for="(anchor, answerIdx) of content[3].answers"
                    :key="answerIdx"
                    class="navigation__button"
                    :to="
                      translations.i18nRoute({
                        name: 'faq',
                        hash: `#${t(
                          `faq.content[${3}].answers[${answerIdx}].title`,
                        )
                          .toLowerCase()
                          .split(' ')
                          .join('-')}`,
                      })
                    "
                  >
                    <span
                      v-text="
                        t(`faq.content[${3}].answers[${answerIdx}].title`)
                      "
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
.support {
  width: 100%;
  min-height: 50vh;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @include mobile {
    padding: 4rem 0;
  }

  &__title {
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
  }

  &__subtitle {
    color: var(--el-text-color-secondary);
    text-align: center;
    margin-bottom: 5rem;

    @include mobile {
      margin-bottom: 3rem;
    }
  }

  &__icon {
    width: 8rem;
    height: 8rem;
    color: var(--el-text-color-primary);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    background-color: var(--el-bg-color);
    padding: 4rem;
    border-radius: 0.5rem;
    max-width: 90rem;
    width: 100%;

    @include mobile {
      padding: 2rem;
    }

    &-title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    &-content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem;

      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 3rem;
      }
    }

    &-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 3rem;
    }
  }

  .navigation {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;

    @include tablet {
      padding-left: 0;
    }

    &__title {
      font-size: 1.6rem;
      color: var(--el-text-color-secondary);
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.5rem;
    }

    &__button {
      width: fit-content;
      text-align: start;
      font-size: 1.4rem;
      line-height: 130%;
      font-weight: 500;
      color: var(--el-color-info);

      @include hover {
        color: var(--el-color-primary);
      }
    }
  }
}
</style>