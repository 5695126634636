<script setup>
import {computed, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import UiPasswordHelper from "@/components/ui/ui-password-helper.vue";
import {Auth} from "@/core/api/auth.js";
import {ElNotification} from "element-plus";
import {logoutUser} from "@/core/helpers/logout.js";
import {useAuthStore} from "@/stores/auth.js";

const { t } = useI18n();
const authStore = useAuthStore()

const fieldFill = reactive({
  hasMin: false,
  hasLowerUpper: false,
  hasNumber: false,
  hasSpecial: false,
});

const form = ref();
const model = reactive({
  password: "",
  confirm_password: "",
});

const rules = reactive({
  password: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.passwordN") }),
      trigger: "change",
    },
    {
      message: t("fieldErrors.needLowerUpper"),
      validator(rule, value) {
        fieldFill.hasLowerUpper = !(
          !/[A-Z]/.test(value) || !/[a-z]/.test(value)
        );

        fieldFill.hasMin = value.length >= 8;

        fieldFill.hasNumber = /\d/.test(value);

        fieldFill.hasSpecial = /[#.?!@$%^&*'()_+=:;"'/>.<,|-]/.test(value);

        return true;
      },
      trigger: "change",
    },
  ],
  confirm_password: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.passwordR") }),
      trigger: "change",
    },
    {
      message: t("fieldErrors.compare", { field: t("fields.password") }),
      trigger: "change",
      validator() {
        return model.password === model.confirm_password;
      },
    },
  ],
});

const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : ''
})
const userUuid = computed(() => {
  return authStore.user.uuid ? authStore.user.uuid : ''
})

const isFormValid = computed(() => {
  return (
    model.password.length !== 0 &&
    model.password === model.confirm_password
  );
});

const updateUser = async () => {
  const res = await Auth.updateUser(
    `uuid: "${userUuid.value}"` +
      `, password: "${model.password}"` +
      `, confirmPassword: "${model.confirm_password}"`,
      accessToken.value
  )

  if (!res.errors) {
    ElNotification({
      title: t("notifications.success.url.title"),
      message: t("notifications.success.url.text"),
      type: "success",
      duration: 5000,
      position: "bottom-left",
    });

    await logoutUser(router)
  } else {
    ElNotification({
      message: res.errors[0].message,
      type: "error",
      duration: 5000,
      position: "bottom-left",
    });
  }
};

const isPasswordVisible = ref(false);
const isPasswordFocus = ref(false);
const isConfirmPasswordVisible = ref(false);
</script>

<template>
  <el-form
    ref="form"
    class="form"
    :model="model"
    require-asterisk-position="right"
    label-position="top"
    :rules="rules"
  >
    <el-form-item class="form__item" prop="password">
      <el-input
        v-model="model.password"
        size="large"
        :placeholder="t('fields.passwordN')"
        :type="isPasswordVisible ? 'text' : 'password'"
        @focus="() => (isPasswordFocus = true)"
        @blur="() => (isPasswordFocus = false)"
      >
        <template #suffix>
          <button
            class="form__switch"
            @click.prevent="() => (isPasswordVisible = !isPasswordVisible)"
            v-text="isPasswordVisible ? t('login.hide') : t('login.show')"
          />
        </template>
      </el-input>
    </el-form-item>
    <transition name="fade">
      <ui-password-helper v-if="isPasswordFocus" :fill="fieldFill" />
    </transition>
    <el-form-item class="form__item" prop="confirm_password">
      <el-input
        v-model="model.confirm_password"
        size="large"
        :placeholder="t('fields.passwordR')"
        :type="isConfirmPasswordVisible ? 'text' : 'password'"
      >
        <template #suffix>
          <button
            class="form__switch"
            @click.prevent="
              () => (isConfirmPasswordVisible = !isConfirmPasswordVisible)
            "
            v-text="
              isConfirmPasswordVisible ? t('login.hide') : t('login.show')
            "
          />
        </template>
      </el-input>
    </el-form-item>
    <div class="form__buttons">
      <el-button
        class="form__button"
        type="info"
        size="large"
        :disabled="!isFormValid"
        @click="updateUser"
      >
        {{ t("account.save") }}
      </el-button>
      <router-link
        :to="translations.i18nRoute({ name: 'profile' })"
        class="form__button el-button el-button--danger el-button--large"
        type="info"
        size="large"
      >
        {{ t("account.cancel") }}
      </router-link>
    </div>
  </el-form>
</template>

<style scoped lang="scss">
.form {
  max-width: 340px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  @include mobile {
    gap: 2rem;
  }

.el-button--danger {
  --el-button-bg-color: #fa490a;
  --el-button-border-color: #fa490a;
}
  &__item {
.el-button--danger {
  --el-button-bg-color: #fa490a;
  --el-button-border-color: #fa490a;
}
    width: 100%;
  }

  &__buttons {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    row-gap: 1rem;
    margin-top: 2rem;

    @include mobile {
      flex-direction: column;
      width: 100%;
    }
  }

  &__button {
    min-width: 160px;
    @include mobile {
      width: 100%;
    }
  }

  &__switch {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: var(--el-text-color-secondary);
    margin: 0 !important;

    @include hover {
      color: var(--el-text-color-primary);
    }

    &:active {
      color: var(--el-text-color-primary);
    }
  }
}
:deep(.el-input--large) {
  --el-input-inner-height: 48px;
}
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #717777 inset;
}
.el-button--danger {
  --el-button-bg-color: #fa490a;
  --el-button-border-color: #fa490a;
}
</style>