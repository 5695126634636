import {apiTemplate} from "@/core/helpers/api.js";

export class Processes {
  static async getCompanyInfo() {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `query {
        parameters {
          companyAddress
          companyName
          companyPhoneNumber
          emailFrom
          emailHostUser
          projectName
        }
      }`
    );

    return await response;
  }

  static async topUpBalance(amount, accessToken) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "X-EVIBES-AUTH": `Bearer ${accessToken}`,
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
          deposit(amount: ${amount}) {
            transaction {
              process
            }
          }
        }`
    );

    return await response;
  }
}