import '@/assets/styles/main.scss'
import '@/assets/styles/global/fonts.scss'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n from "@/core/plugins/i18n.config.js";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import 'element-plus/theme-chalk/el-skeleton.css';
import '@vueform/slider/themes/default.css'

import App from './App.vue'
import router from './router'

const app = createApp(App)
    .use(createPinia())
    .use(router)
    .use(i18n)
    .use(ElementPlus)

app.mount('#app')
