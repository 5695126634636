<script setup>
import { useI18n } from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import FormsCreateAccount from "@/components/forms/forms-create-account.vue";

const { t } = useI18n();
</script>

<template>
  <div class="sign-up">
    <div class="sign-up__nav">
      <router-link
        class="sign-up__link"
        active-class="sign-up__link--active"
        :to="translations.i18nRoute({ name: 'sign-in' })"
      >
        {{ t("header.login") }}
      </router-link>
      <router-link
        class="sign-up__link"
        active-class="sign-up__link--active"
        :to="translations.i18nRoute({ name: 'sign-up' })"
      >
        {{ t("header.register") }}
      </router-link>
    </div>
    <div class="sign-up__head">
      <h1 class="sign-up__title" v-text="t('register.title')" />
    </div>
    <div class="sign-up__form">
      <forms-create-account />
    </div>
    <i18n-t
      class="sign-up__agree"
      scope="global"
      tag="p"
      keypath="register.agree"
    >
      <template #terms>
        <router-link
          class="sign-up__agree-link"
          :to="translations.i18nRoute({ name: 'terms-of-use' })"
        >
          {{ t("terms.title") }}
        </router-link>
      </template>
      <template #privacy>
        <router-link
          class="sign-up__agree-link"
          :to="translations.i18nRoute({ name: 'privacy-policy' })"
        >
          {{ t("privacy.title") }}
        </router-link>
      </template>
    </i18n-t>
  </div>
</template>

<style scoped lang="scss">
.sign-up {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4rem;

  @include mobile {
    padding: 0 2rem;
  }

  &__nav {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-bottom: 6rem;
  }

  &__link {
    padding: 1rem 0;
    font-weight: 700;
    color: var(--el-text-color-primary);
    text-transform: uppercase;

    @include hover {
      color: var(--el-color-primary);
    }

    &:active {
      color: var(--el-color-primary);
    }

    &--active {
      position: relative;
      color: var(--el-color-primary);

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.3rem;
        background-color: var(--el-color-primary);
      }
    }
  }

  &__head {
    margin-bottom: 3rem;
  }

  &__title {
    font-size: 2.5rem;
    font-weight: 700;

    @include mobile {
      font-size: 1.8rem;
      text-align: center;
    }
  }

  &__form {
    width: 100%;
    max-width: 45rem;
    margin-bottom: 6rem;
  }

  &__agree {
    width: 100%;
    max-width: 45rem;
    text-align: center;
    font-weight: 500;
    line-height: 150%;

    &-link {
      font-weight: 700;
      color: var(--el-color-info);

      @include hover {
        color: var(--el-color-info-light-5);
      }

      &:active {
        color: var(--el-color-info-dark-2);
      }
    }
  }
}
</style>