<script setup>
import {useI18n} from "vue-i18n";
import {computed, ref} from "vue";
import translations from "@/core/helpers/translations.js";
import UiFloatViewer from "@/components/ui/ui-float-viewer.vue";
import {useCartStore} from "@/stores/cart.js";

const { product, view } = defineProps({
  product: Object,
  view: "grid" | "list"
})

const { t } = useI18n();
const cartStore = useCartStore();

const productFloat = computed(() => {
  const steamInfoGroup = product.product.attributeGroups.edges.find(
    (group) => group.node.name === "Skin Info"
  );
  if (!steamInfoGroup) return null;

  const floatAttribute = steamInfoGroup.node.attributes.find(
    (attr) => attr.name === "float"
  );
  if (!floatAttribute || !floatAttribute.values.length) return null;

  return floatAttribute.values[0].value;
});

const productStickers = computed(() => {
  const skinInfoGroup = product.product.attributeGroups.edges.find(
    (group) => group.node.name === "Skin Info"
  );
  if (!skinInfoGroup) return null;

  const stickerAttribute = skinInfoGroup.node.attributes.find(
    (attr) => attr.name === "stickers"
  );

  if (!stickerAttribute || !stickerAttribute.values.length) return null;

  try {
    return stickerAttribute?.values.map((sticker) => {
      const { uuid, ...rest } = JSON.parse(sticker.value);
      return rest;
    })[0];
  } catch (error) {
    console.error("Failed to parse stickers JSON:", error);
    return null;
  }
});

const isProductInCart = computed(() => {
  const el = cartStore.currentOrder?.orderProducts?.edges.find((el) => el.node.product.uuid === product.product.uuid);
  return !!el;
});

const computedProductName = computed(() => {
  return product.product.name.split("| ")[1]
    ? product.product.name.split("| ")[1].split(" (")[0]
    : product.product.name;
});
</script>

<template>
  <div
    :class="[`product-card product--${view}`]"
  >
    <div
      :class="[
        'product-card__inner',
        isProductInCart ? 'product-card--added' : ''
      ]"
    >
      <img class="product-card__image" :src="product.product.images.edges[0].node.image" alt="image" />
      <p class="product-card__price">
        <img src="@icons/coin.svg" alt="coin" />
        <span v-text="product.product.price" />
      </p>
      <div class="product-card__stickers" v-if="productStickers">
        <el-tooltip
          v-for="sticker of productStickers"
          :key="sticker.uuid"
          :content="sticker.name"
          placement="left"
          effect="light"
        >
          <img class="product-card__sticker" :src="sticker.img" :alt="sticker.name" />
        </el-tooltip>
      </div>
      <span class="product-card__weapon" :style="{ color: product.color }">
        {{ product.product.weapon }}
      </span>
      <div class="product-card__name">
        {{ computedProductName }}
      </div>
      <span class="product-card__quality">
        {{
          `${product.product.weapon ? product.product.weapon : ""} ${product.product.exterior ? product.product.exterior : ""} ${product.product.rarity ? product.product.rarity : ""} ${product.quality ? product.quality : ""}`
        }}
      </span>
      <div class="product-card__float">
        <ui-float-viewer
          v-if="productFloat"
          :float="productFloat"
          show-float
        />
      </div>
    </div>
      <router-link class="product-card__link" :to="translations.i18nRoute({ name: 'product', params: { uuid: product.product.uuid } })" />
    <div v-if="isProductInCart" class="product-card__added">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 12 10">
        <path d="M2.904 8.11.12 5.163 1.536 3.75l2.777 2.94L10.12.835l1.415 1.414-5.849 5.894.005.005-1.414 1.415-1.414-1.415.04-.04z"/>
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.product-card {
  width: 100%;
  height: 100%;
  background-color: var(--el-bg-color);
  transition: all 0.2s ease;
  position: relative;

  @include hover {
    //transform: translateY(-25px) !important;
    background-color: var(--el-bg-darker);
    box-shadow: var(--el-box-shadow-dark);
    z-index: 7;

    .product-card__tools-desktop {
      height: 50px;
      z-index: 7;
    }
  }

  @include mobile-tablet {
    @include hover {
      transform: none !important;
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
    position: relative;
    padding: 2rem 1.5rem;
    transition: all 0.3s ease;
  }

  &--added {
    filter: grayscale(100%);

    @include hover {
      filter: none;
    }

    @include mobile-tablet {
      filter: none;
    }
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-weight: 700;
    font-size: 1.4rem;
    color: var(--el-color-success-light-7);
    margin-bottom: 2rem;
    position: relative;
    z-index: 6;

    & svg {
      width: 20px;
    }
  }

  &__added {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 78px;
    height: 78px;
    border-radius: 100%;
    background-color: var(--el-color-info);
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(0);
  }

  &__image {
    width: auto;
    height: 12rem;
    align-self: center;
    margin-bottom: 2rem;

    @include mobile {
      height: 10rem;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2rem;

    & img {
      width: 20px;
    }

    @include mobile {
      font-size: 2rem;
    }
  }

  &__weapon {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;

    @include mobile {
      font-size: 1.2rem;
    }
  }

  &__name {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    @include mobile {
      font-size: 1.6rem;
    }
  }

  &__quality {
    color: var(--el-text-color-secondary);

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__float {
    width: 100%;
    margin-top: auto;
    padding-top: 2rem;
  }

  &__tools {
    &-desktop {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: -1;
      display: flex;
      align-items: start;
      background-color: var(--el-bg-color);
      overflow: hidden;
      height: 0;
      transition:
        height 0.2s ease,
        z-index 0.1s ease;

      @include mobile-tablet {
        display: none;
      }
    }

    &-mobile {
      width: 100%;
      display: none;
      align-items: stretch;
      margin-top: 2rem;
      border: 1px solid var(--el-text-color-placeholder);
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      z-index: 6;

      @include mobile-tablet {
        display: flex;
      }
    }

    &-menu {
      z-index: 5;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#1d2021, 0.7);
      display: flex;
      align-items: end;

      & .inner {
        width: 100%;
        background-color: var(--el-bg-color);
        padding: 0.5rem 0;

        @include mobile-tablet {
          padding-bottom: calc(30px + 4rem);
        }
      }

      & .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      & .item {
        width: 100%;
      }

      & .button {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        width: 100%;
        padding: 1.5rem 2rem;
        text-align: start;
        color: var(--el-text-color-primary);
        font-weight: 600;
        font-size: 1.8rem;

        @include hover {
          background-color: var(--el-bg-darker);
        }

        @include mobile-tablet {
          padding: 1rem 1.5rem;
          font-size: 1.4rem;
        }
      }
    }

    &-button {
      background-color: var(--el-color-info);
      min-height: 4rem;

      @include mobile-tablet {
        background-color: transparent;
        padding: 5px;
        font-size: 1.2rem;
      }

      @include hover {
        background-color: var(--el-color-info-dark-2);
      }

      &:nth-of-type(1) {
        width: 100%;
        height: 100%;
        border-radius: 0 0 0 5px;
        color: var(--el-text-color-primary);
        letter-spacing: 0.12rem;
        font-weight: 700;
        font-size: 1.7rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        @include mobile-tablet {
          border-radius: 0;
          font-weight: 600;
          font-size: 1rem;
        }
      }

      &:nth-of-type(2) {
        border-radius: 0 0 5px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        min-width: 40px;
        width: 40px;
        height: 100%;
        margin-left: 1px;

        @include mobile-tablet {
          border-radius: 0;
          gap: 3px;
          border-left: 1px solid var(--el-text-color-placeholder);
          min-width: 35px;
          width: 35px;
        }
      }

      &--added {
        background-color: var(--el-color-error);

        @include hover {
          background-color: var(--el-color-error-dark-2);
        }
      }
    }

    &-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: var(--el-text-color-primary);
      display: block;

      @include mobile-tablet {
        width: 3px;
        height: 3px;
      }
    }
  }

  &__link {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__stickers {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @include mobile {
      right: 1rem;
      top: 1rem;
    }
  }

  &__sticker {
    width: 35px;
    height: auto;
    transition: all 0.3s ease;

    @include hover {
      scale: 1.3;
    }
  }
}

svg {
  width: 20px;
}
</style>