<script setup>
import {computed} from "vue";
import {useWishlistStore} from "@/stores/wishlist.js";
import WishlistCard from "@/components/wishlist/wishlist-card.vue";

const wishlistStore = useWishlistStore();

const products = computed(() => {
  return wishlistStore.wishlist.products ? wishlistStore.wishlist.products.edges : []
});
</script>

<template>
  <ul class="list">
    <li class="list__item" v-for="product of products" :key="product.node.uuid">
      <wishlist-card
        :product="product.node"
        view="grid"
        :visible="{
          opacity: 1,
          filter: 'grayscale(0)'
        }"
        :initial="{ opacity: 0, filter: 'grayscale(1)' }"
        :enter="{
          opacity: 1,
          filter: 'grayscale(0)',
          transition: {
            duration: 300,
            ease: 'ease',
          },
        }"
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
.list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 2px;

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
  }
}
</style>