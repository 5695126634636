<script setup>
</script>

<template>
  <div class="container">
    <el-skeleton class="swiper" animated>
      <template #template>
        <div class="swiper__head">
          <el-skeleton-item class="swiper__title" variant="h3" />
          <el-skeleton-item class="swiper__title" variant="h3" />
        </div>
        <el-skeleton-item class="swiper__text" variant="p" />
        <el-skeleton-item class="swiper__button" variant="button" />
      </template>
    </el-skeleton>
  </div>
</template>

<style scoped lang="scss">
.container {
  @include mobile-tablet {
    padding: 0 !important;
  }
}

.swiper {
  width: 100%;
  height: 50rem;
  background-color: var(--el-bg-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 8.5rem;
  gap: 4rem;

  @include mobile-tablet {
    height: 23rem;
    gap: 2rem;
    padding: 0 2rem;
  }

  &__head {
    max-width: 76.8rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__title {
    height: 5rem;

    @include mobile-tablet {
      height: 2.4rem;
    }

    &:nth-child(2) {
      width: 40%;

      @include mobile-tablet {
        width: 60%;
      }
    }
  }

  &__text {
    height: 4rem;
    max-width: 64rem;

    @include mobile-tablet {
      height: 2rem;
    }
  }

  &__button {
    width: 17.6rem;
    height: 6rem;

    @include mobile-tablet {
      width: 12.4rem;
      height: 3.5rem;
    }
  }
}
</style>