<script setup>
import {computed, ref} from "vue";
import {onClickOutside} from "@vueuse/core";
import {useAuthStore} from "@/stores/auth.js";
import {useAppStore} from "@/stores/app.js";
import UiLogotype from "@/components/ui/ui-logotype.vue";
import {useI18n} from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import BaseHeaderCart from "@/components/base/header/base-header-cart.vue";
import FormsSearch from "@/components/forms/forms-search.vue";
import {User} from "@element-plus/icons-vue";
import {ShoppingCart} from "@element-plus/icons-vue";
import BaseHeaderSearch from "@/components/base/header/base-header-search.vue";
import BaseHeaderToolsWidget from "@/components/base/header/base-header-tools-widget.vue";

const appStore = useAppStore();
const authStore = useAuthStore();
// const orderStore = useOrdersStore();
const {t} = useI18n()

const isSearchVisible = ref(false);

const showSearch = (state) => {
  isSearchVisible.value = state;
};

const search = ref(null);

onClickOutside(search, () => showSearch(false));

const toggleMenuHandler = (state) => {
  appStore.isMenuVisible = state;
};

const user = computed(() => authStore.user);
// const orderItemsCount = computed(() => orderStore.localOrder.length);

const setToolsWidgetVisible = (state) => {
  appStore.isHeaderToolsVisible = state;
};

const setCartWidgetVisible = (state) => {
  appStore.isHeaderCartVisible = state;
};

const isCartWidgetVisible = computed(
  () => appStore.isHeaderCartVisible,
);

const isProfileWidgetVisible = computed(
  () => appStore.isHeaderToolsVisible
);

const cart = ref(null);
const cartBtn = ref(null);
const cartBtnMobile = ref(null);

onClickOutside(cart, () => setCartWidgetVisible(false), {
  ignore: [cartBtn, cartBtnMobile],
});

const profile = ref(null);
const profileBtn = ref(null);
const profileBtnMobile = ref(null);

onClickOutside(profile, () => setToolsWidgetVisible(false), {
  ignore: [profileBtn, profileBtnMobile],
});
</script>

<template>
  <div class="tools container">
    <div class="tools__inner">
      <div class="tools__nav">
        <button
          class="tools__mobile mobile-tablet"
          @click="toggleMenuHandler(true)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 14"
            fill="CurrentColor"
          >
            <path
              d="M0 0h18v2H0V0Zm0 12h18v2H0v-2Zm0-6h18v2H0V6Z"
              fill-rule="evenodd"
            />
          </svg>
        </button>
        <ui-logotype />
        <router-link
          class="el-button is-text tools__link"
          :to="translations.i18nRoute({ name: 'market' })"
        >
          {{ t("header.market") }}
        </router-link>
      </div>
      <div class="tools__search">
        <forms-search />
      </div>
      <div v-if="!user" class="tools__buttons tablet-desktop">
        <router-link
          class="el-button is-text"
          :to="translations.i18nRoute({ name: 'sign-in' })"
        >
          {{ t("header.login") }}
        </router-link>
        <router-link
          class="el-button el-button--info"
          :to="translations.i18nRoute({ name: 'sign-up' })"
        >
          {{ t("header.register") }}
        </router-link>
      </div>
      <div v-else class="tools__buttons tablet-desktop">
        <button
          ref="profileBtn"
          class="tools__user"
          @click="setToolsWidgetVisible(!isProfileWidgetVisible)"
        >
          <el-icon><User /></el-icon>
        </button>
        <el-button
          ref="cartBtn"
          type="info"
          @click="setCartWidgetVisible(!isCartWidgetVisible)"
        >
          <span class="tools__cart">
            <el-icon><ShoppingCart /></el-icon>
<!--            <span v-text="orderItemsCount" />-->
          </span>
        </el-button>
      </div>
      <div class="tools__buttons mobile">
        <button class="tools__mobile" @click="showSearch(!isSearchVisible)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13 14"
            fill="currentColor"
          >
            <path
              d="M5.385.596c-1.438 0-2.79.56-3.807 1.577A5.351 5.351 0 0 0 0 5.981c0 1.438.56 2.791 1.578 3.808a5.349 5.349 0 0 0 3.808 1.578c1.164 0 2.28-.372 3.202-1.057l3.286 3.286L13 12.47 9.713 9.183c1.552-2.103 1.37-5.121-.52-7.01A5.35 5.35 0 0 0 5.386.596zM9.177 5.98a3.77 3.77 0 0 1-1.11 2.683 3.77 3.77 0 0 1-2.682 1.11 3.77 3.77 0 0 1-2.682-1.11A3.769 3.769 0 0 1 1.592 5.98c0-1.013.394-1.966 1.11-2.682a3.77 3.77 0 0 1 2.683-1.112A3.77 3.77 0 0 1 8.068 3.3a3.767 3.767 0 0 1 1.109 2.68z"
            />
          </svg>
        </button>
        <router-link
          v-if="!user"
          class="tools__mobile"
          :to="translations.i18nRoute({ name: 'sign-in' })"
        >
          <el-icon><User /></el-icon>
        </router-link>
        <button
          v-else
          ref="profileBtnMobile"
          class="tools__mobile"
          @click="setToolsWidgetVisible(!isProfileWidgetVisible)"
        >
          <el-icon><User /></el-icon>
        </button>
        <button
          v-if="user"
          ref="cartBtn"
          class="tools__mobile"
          @click="setCartWidgetVisible(!isCartWidgetVisible)"
        >
            <el-icon><ShoppingCart /></el-icon>
        </button>
      </div>
    </div>
    <transition name="fromTop">
      <base-header-search
        v-if="isSearchVisible"
        ref="search"
        @close="showSearch(false)"
      />
    </transition>
    <transition name="fromBottom">
      <base-header-cart v-if="isCartWidgetVisible" ref="cart" />
    </transition>
    <transition name="fromBottom">
      <base-header-tools-widget v-if="isProfileWidgetVisible" ref="profile" />
    </transition>
  </div>
</template>

<style scoped lang="scss">
.tools {
  width: 100%;
  background-color: $el-bg-color-overlay;
  position: relative;

  &__inner {
    border-bottom: 0.1rem solid $el-text-color-placeholder;
    min-height: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5rem;

    @include tablet {
      min-height: 6rem;
    }

    @include mobile-tablet {
      gap: 2.5rem;
    }

    @include mobile {
      min-height: 5rem;
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    gap: 2rem;

    @include mobile-tablet {
      gap: 1rem;
    }
  }

  &__link {
    text-transform: uppercase;

    @include mobile-tablet {
      display: none;
    }
  }

  &__mobile {
    display: block;
    width: 2rem;
    height: 2rem;
    color: $el-text-color-primary;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  &__search {
    max-width: 72rem;
    width: 100%;

    @include mobile-tablet {
      display: none;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 2rem;

    @include tablet {
      gap: 1rem;
    }

    @include mobile {
      gap: 2rem;
    }
  }

  &__user {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $el-bg-color;
    border-radius: 100%;
    color: $el-text-color-primary;

    & svg {
      width: 100%;
      height: 100%;
    }

    @include hover {
      color: $el-color-primary;
    }
  }

  &__cart {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 700;
    font-size: 1.8rem;

    & > span {
      transform: translateY(0.1rem);
    }

    & > svg {
      min-width: 2rem;
      width: 2rem;
      height: 2rem;
    }

    &--active {
      background-color: $el-color-primary !important;
    }
  }
}
</style>