<script setup>
import {useI18n} from "vue-i18n";
import {useAppStore} from "@/stores/app.js";
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import ProductFilters from "@/components/product/product-filters.vue";
import MarketToolsSkeleton from "@/components/skeletons/market/market-tools-skeleton.vue";
import {Products} from "@/core/api/products.js";
import {useRoute} from "vue-router";
import {useIntersectionObserver} from "@vueuse/core";
import ProductCardSkeleton from "@/components/skeletons/product/product-card-skeleton.vue";
import ProductCard from "@/components/product/product-card.vue";
import {SortingOptionsList} from "@/core/helpers/marketSorting.js";

const { t } = useI18n();
const appStore = useAppStore();
const route = useRoute()

const isFiltersVisible = computed(() => appStore.isFiltersVisible)

onMounted(() => {
  setTimeout(() => {
    const body = document.querySelector('body')

    if (body && window.innerWidth > 769) {
      body.style.overflowY = 'hidden'
    }
  }, 100)
})

onUnmounted(() => {
  const body = document.querySelector('body')

  if (body) {
    body.style.overflowY = 'auto'
  }
})

const showMobileFilters = () => {
  appStore.isMobilFiltersVisible = !appStore.isMobilFiltersVisible
}

const isLoading = ref(false)
const observer = ref(null)

const { stop } = useIntersectionObserver(
  observer,
  ([{ isIntersecting }], observerElement) => {
    if (isIntersecting && pageInfo.value.hasNextPage) {
      getMoreProducts()
    }
  },
)

const products = ref([])
const pageInfo = ref([])
const cursorForAfter = ref('0')
const name = ref(route.query['name'] ? (route.query['name']) : '')
const orderBy = ref(route.query['orderBy'] ? (route.query['orderBy']) : 'price')
const attributes = ref(route.query['attributes'] ? route.query['attributes'] : '')
const minPrice = ref(route.query['minPrice'] ? (route.query['minPrice']) : '0')
const maxPrice = ref(route.query['maxPrice'] ? (route.query['maxPrice']) : '500')
const category = ref(route.query['category'] ? (route.query['category']) : '')
const tag = ref(route.query['tag'] ? (route.query['tag']) : '')

const currentSorting = ref({
  displayName: t('market.sorting.rating'),
  value: orderBy.value
})

const switchSorting = (newSort) => {
  currentSorting.value = newSort
  orderBy.value = newSort.value
}

const onFilterAttributes = (filteredAttributes) => {
  const queryParts = [];

  for (const [key, values] of Object.entries(filteredAttributes)) {
    if (values.length === 1) {
      queryParts.push(`${key}=icontains-${values[0]}`);
    } else if (values.length > 1) {
      const formattedValues = values
        .map(value => `\\"${value}\\"`)
        .join(', ');
      queryParts.push(`${key}=in-[${formattedValues}]`);
    }
  }

  attributes.value = queryParts.join(';');
};
const onFilterPrice = (filteredPrice) => {
  minPrice.value = filteredPrice[0];
  maxPrice.value = filteredPrice[1];
};

const query = computed(() => {
  let res =`?orderBy=${orderBy.value}&minPrice=${minPrice.value}&maxPrice=${maxPrice.value}`

  if (name.value) {
    res = res + `&name=${name.value}`
  }

  if (category.value) {
    res = res + `&category=${category.value}`
  }

  if (tag.value) {
    res = res + `&tag=${tag.value}`
  }

  if (attributes.value) {
    res = res + `&attributes=${attributes.value}`
  }

  return res
})

const getProducts = async () => {
  isLoading.value = true
  cursorForAfter.value = '0'

  const res = await Products.getProducts(
      `after: "${cursorForAfter.value}"` +
      `, first: 24` +
      `, orderBy: "${orderBy.value}"` +
      `, minPrice: "${minPrice.value}"` +
      `, maxPrice: "${maxPrice.value}"` +
      (category.value ? `, categories: "${category.value}"` : "") +
      (tag.value ? `, tags: "${tag.value}"` : "") +
      (attributes.value ? `, attributes: "${attributes.value}"` : "") +
      (name.value ? `, name: "${name.value}"` : "")
  )
  products.value = res.data?.products?.edges
  pageInfo.value = res.data?.products?.pageInfo

  isLoading.value = false
}

const getMoreProducts = async () => {
  isLoading.value = true

  cursorForAfter.value = pageInfo.value.endCursor

  const res = await Products.getProducts(
      `after: "${cursorForAfter.value}"` +
      `, first: 24` +
      `, orderBy: "${orderBy.value}"` +
      `, minPrice: "${minPrice.value}"` +
      `, maxPrice: "${maxPrice.value}"` +
      (category.value ? `, categories: "${category.value}"` : "") +
      (tag.value ? `, tags: "${tag.value}"` : "") +
      (attributes.value ? `, attributes: "${attributes.value}"` : "") +
      (name.value ? `, name: "${name.value}"` : "")
  )
  products.value.push(...res.data.products.edges)
  pageInfo.value = res.data.products.pageInfo

  isLoading.value = false
}

watch(
  () => route.query,
  (val) => {
    category.value = val.category ? (val.category) : null;
    name.value = val.name ? (val.name) : null;
  },
  { deep: true }
)
watch(
  () => query.value,
  async () => {
    products.value = [];
    history.pushState({}, '', route.path + query.value);
    await getProducts();
  }
)

onMounted( async () => {
  await getProducts()
})
</script>

<template>
  <div>
    <div class="market__mobile">
      <div class="container">
        <div class="market__mobile-inner">
          <div class="market__crumb">CS 2</div>
<!--          <div class="market__total">-->
<!--            <span v-text="t('market.items', { total: totalItems })" />-->
<!--            <el-tooltip placement="top" effect="dark" trigger="hover">-->
<!--              <template #content>-->
<!--              <span-->
<!--                  class="market__total-tooltip"-->
<!--                  v-text="t('market.totalDescription')"-->
<!--              />-->
<!--              </template>-->
<!--              <button class="market__total-button">-->
<!--                <img src="@icons/question-circle.svg" alt="question-circle">-->
<!--              </button>-->
<!--            </el-tooltip>-->
<!--          </div>-->
          <button class="market__mobile-button" @click="showMobileFilters">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 16 13">
              <path d="M15 1H7v2h8a1 1 0 1 0 0-2zm-6 9H1a1 1 0 0 0 0 2h8v-2zM.272 3.217 2.086 4.67c.153.122.218.154.297.174a.45.45 0 0 0 .234 0c.079-.02.144-.052.297-.174l1.814-1.452a.864.864 0 0 0 .18-.17.439.439 0 0 0 .07-.149A.864.864 0 0 0 5 2.652V.64c0-.223-.023-.304-.067-.385a.454.454 0 0 0-.189-.19C4.663.024 4.582 0 4.36 0H.641C.418 0 .337.023.256.067a.454.454 0 0 0-.19.189C.024.337 0 .418 0 .64v2.01c0 .143.006.192.021.247a.439.439 0 0 0 .072.15.864.864 0 0 0 .179.17zm15.503 6.803L13.95 8.24c-.157-.154-.23-.193-.317-.22a.454.454 0 0 0-.264 0c-.087.027-.16.066-.317.22l-1.826 1.781a.907.907 0 0 0-.15.17.436.436 0 0 0-.058.138.907.907 0 0 0-.017.225v1.607c0 .223.023.303.067.385a.454.454 0 0 0 .189.189c.081.043.162.067.385.067h3.718c.223 0 .304-.024.385-.067a.454.454 0 0 0 .19-.19c.043-.08.066-.161.066-.384v-1.607a.907.907 0 0 0-.017-.225.436.436 0 0 0-.058-.138.907.907 0 0 0-.15-.17z"
                  fill-rule="evenodd"/>
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="market hide-scroll-bar container">
      <div class="market__filters hide-scroll-bar" :class="{'market__filters--hide': !isFiltersVisible}">
        <product-filters @filterPrice="onFilterPrice" @filterAttributes="onFilterAttributes" />
      </div>
      <div :class="isFiltersVisible ? 'market__content' : 'market__content market__content--full'">
        <div v-if="!isLoading" class="market__head">
          <div class="market__crumb">Counter-Strike 2</div>
<!--          <div class="market__total">-->
<!--            <el-tooltip placement="top" effect="dark" trigger="hover">-->
<!--              <template #content>-->
<!--              <span-->
<!--                  class="market__total-tooltip"-->
<!--                  v-text="t('market.totalDescription')"-->
<!--              />-->
<!--              </template>-->
<!--              <button class="market__total-button">-->
<!--                <img src="@icons/question-circle.svg" alt="question-circle">-->
<!--              </button>-->
<!--            </el-tooltip>-->
<!--          </div>-->
          <div class="market__tools">
            <el-select
                v-model="currentSorting"
                class="market__tools-select"
                popper-class="market-sorting-select"
                @change="switchSorting(currentSorting)"
            >
              <el-option
                  v-for="option of SortingOptionsList()"
                  :key="option.value"
                  :label="option.displayName"
                  :value="option"
              />
            </el-select>
          </div>
        </div>
        <market-tools-skeleton v-else />
        <ul class="product-list">
          <p class="market__empty" v-if="!isLoading && products.length === 0">{{ t('market.empty.title') }}</p>
          <li v-for="product of products" :key="product.node.uuid" class="product-item">
            <product-card
              v-motion
              :product="product.node"
              :visible="{
                opacity: 1,
                filter: 'grayscale(0)',
              }"
              :initial="{ opacity: 0, filter: 'grayscale(1)' }"
              :enter="{
                opacity: 1,
                filter: 'grayscale(0)',
                transition: {
                  duration: 300,
                  ease: 'ease',
                },
              }"
            />
          </li>
          <li v-for="i of 18" v-if="isLoading" :key="i" class="product-item">
            <product-card-skeleton />
          </li>
        </ul>
        <div class="market__content-observer" ref="observer"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.market {
  width: 100%;
  display: flex;
  align-items: start;
  height: calc(100vh - 12rem);
  overflow-y: auto;
  padding-bottom: 25px;

  @include tablet {
    margin-top: 5rem;
    height: calc(100vh - 5rem);
  }

  @include mobile {
    margin-top: 5rem;
    height: fit-content;
  }

  &__mobile {
    display: none;
    height: 5rem;
    width: 100%;
    background-color: var(--el-bg-color-overlay);
    border-bottom: 2px solid var(--el-bg-color-page);
    position: fixed;
    z-index: 997;


    @include mobile-tablet {
      display: flex;
    }

    @include tablet {
      top: 6rem;
    }

    @include mobile {
      top: 5rem;
    }

    &-inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-button {
      color: var(--el-text-color-primary);

      @include hover {
        color: var(--el-color-primary);
      }

      & svg {
        width: 20px;
      }
    }
  }

  &__filters {
    width: 375px;
    height: calc(100vh - 12rem);
    background-color: var(--el-bg-color);
    position: sticky;
    overflow-x: hidden;
    top: 0;
    z-index: 996;
    transition: all .3s ease;
    border-right: 2px solid var(--el-bg-color-page);

    @include mobile-tablet {
      display: none;
    }

    &--hide {
      transform: translateX(-270px);
      min-width: 0;
      width: 0;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;

    &-observer {
      width: 100%;
      height: 1px;
      background-color: transparent;
    }
  }

  &__head {
    width: 100%;
    background-color: var(--el-bg-color);
    height: 8rem;
    padding: 0 3.5rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;

    @include mobile-tablet {
      display: none;
    }
  }

  &__crumb {
    color: var(--el-text-color-secondary);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.8rem;

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__total {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--el-text-color-primary);
    font-weight: 500;
    font-size: 1.8rem;
    letter-spacing: 0.08rem;

    @include mobile {
      font-size: 1.4rem;
    }

    &-tooltip {
      display: inline-block;
      width: 100%;
      max-width: 40rem;
      font-weight: 500;

      @include mobile {
        max-width: 30rem;
      }
    }

    &-button {
      color: var(--el-text-color-primary);
    }
  }

  &__tools {
    min-width: 22rem;

    @include mobile {
      max-width: 16rem;
    }
  }

  &__empty {
    padding-top: 20px;
    font-weight: 500;
    font-size: 1.8rem;
    color: var(--el-text-color-secondary);
    text-align: center;
  }
}

.product-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 2px;

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
  }

  &--controller {
    display: block;
    opacity: 0;
  }
}

:deep(.el-select__wrapper) {
  text-align: center;
}
</style>
