<script setup>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import en from "@/locales/en-GB.json";

const { t } = useI18n();

const content = ref(en.home.content.all);
</script>

<template>
  <section class="description">
    <div class="description__box">
      <h2 class="description__title" v-text="t('home.content.top.title')" />
      <p class="description__text" v-text="t('home.content.top.text')" />
    </div>
    <div class="description__content">
      <div class="description__content-inner">
        <div class="description__box">
          <h2
              class="description__title"
              v-text="t(`home.content.all[${0}].title`)"
          />
          <div class="description__box-inner">
            <p
                v-for="(text, textIdx) of content[0].text"
                :key="textIdx"
                class="description__text"
                v-text="t(`home.content.all[${0}].text[${textIdx}]`)"
            />
          </div>
        </div>
        <div class="description__box">
          <h2
              class="description__title"
              v-text="t(`home.content.all[${3}].title`)"
          />
          <div class="description__box-inner">
            <p
                v-for="(text, textIdx) of content[3].text"
                :key="textIdx"
                class="description__text"
                v-text="t(`home.content.all[${3}].text[${textIdx}]`)"
            />
          </div>
        </div>
      </div>
      <div class="description__content-inner">
        <div class="description__box">
          <h2
              class="description__title"
              v-text="t(`home.content.all[${1}].title`)"
          />
          <div class="description__box-inner">
            <p
                v-for="(text, textIdx) of content[1].text"
                :key="textIdx"
                class="description__text"
                v-text="t(`home.content.all[${1}].text[${textIdx}]`)"
            />
          </div>
        </div>
        <div class="description__box">
          <h2
              class="description__title"
              v-text="t(`home.content.all[${4}].title`)"
          />
          <div class="description__box-inner">
            <p
                v-for="(text, textIdx) of content[4].text"
                :key="textIdx"
                class="description__text"
                v-text="t(`home.content.all[${4}].text[${textIdx}]`)"
            />
          </div>
        </div>
      </div>
      <div class="description__content-inner">
        <div class="description__box">
          <h2
              class="description__title"
              v-text="t(`home.content.all[${2}].title`)"
          />
          <div class="description__box-inner">
            <p
                v-for="(text, textIdx) of content[2].text"
                :key="textIdx"
                class="description__text"
                v-text="t(`home.content.all[${2}].text[${textIdx}]`)"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.description {
  width: 100%;
  margin-top: 4rem;

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    gap: 4rem;
    flex-wrap: wrap;

    &-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 3rem;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 3rem;
    }
  }

  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: 0.1rem solid var(--el-text-color-secondary);

    &-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 2rem;
    }
  }

  &__title {
    font-size: 2.8rem;
    font-weight: 700;

    @include tablet {
      font-size: 2.6rem;
    }

    @include mobile {
      font-size: 2.2rem;
      line-height: 130%;
    }
  }

  &__text {
    color: var(--el-text-color-secondary);
    line-height: 140%;
    letter-spacing: 0.12rem;
  }
}
</style>