<script setup>
import {useI18n} from "vue-i18n";
import {useAuthStore} from "@/stores/auth.js";
import {computed} from "vue";
import FormsChangePassword from "@/components/forms/forms-change-password.vue";
import UiAnimatedBackground from "@/components/ui/ui-animated-background.vue";

const { t } = useI18n();
const authStore = useAuthStore();

const user = computed(() => authStore.user);
</script>

<template>
  <div class="container">
    <div class="password">
      <div class="password__inner">
        <h1
          class="password__title"
          v-text="t('account.changePassword.title')"
        />
        <i18n-t
          tag="p"
          class="password__text"
          scope="global"
          keypath="account.changePassword.text"
        >
          <template #email>
            <span class="password__text-email" v-text="user.email" />
          </template>
        </i18n-t>
        <forms-change-password />
      </div>
    </div>
    <ui-animated-background />
  </div>
</template>

<style scoped lang="scss">
.password {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 12rem);

  @include tablet {
    height: calc(100vh - 6rem);
  }

  @include mobile {
    height: calc(100vh - 5rem);
  }

  &__inner {
    max-width: 650px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    @include mobile {
      gap: 3rem;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;

    @include mobile {
      font-size: 20px;
    }
  }

  &__text {
    text-align: center;
    font-size: 14px;
    color: var(--el-text-color-secondary);

    &-email {
      font-weight: 600;
      color: var(--el-color-info);
    }
  }
}
</style>