<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n()
</script>

<template>
  <div class="empty">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="empty__icon"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M6.854 8.146a.5.5 0 1 0-.708.708L7.293 10l-1.147 1.146a.5.5 0 0 0 .708.708L8 10.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 10l1.147-1.146a.5.5 0 0 0-.708-.708L8 9.293z"
      />
    </svg>
    <span class="empty__text" v-text="t('cart.empty')" />
  </div>
</template>

<style scoped lang="scss">
.empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  min-height: 10rem;
  color: var(--el-text-color-placeholder);

  &__icon {
    width: 10rem;
    height: 10rem;
  }

  &__text {
    font-size: 1.8rem;
    font-weight: 700;
  }
}
</style>