<script setup>
import {useI18n} from "vue-i18n";
import {onMounted, ref} from "vue";
import {Products} from "@/core/api/products.js";
import UiSection from "@/components/ui/ui-section.vue";
import BaseSectionSkeleton from "@/components/skeletons/base/base-section-skeleton.vue";
import BaseSwiper from "@/components/base/base-swiper.vue";
import BaseDescription from "@/components/base/base-description.vue";

const { t } = useI18n();

const sectionAll = ref({
  products: [],
  isPending: true,
  title: t("home.all.title"),
  icon: "cs-icon",
  link: {
    displayName: ""
  },
});

const sectionTop = ref({
  products: [],
  isPending: true,
  title: t("home.top.title"),
  icon: "top",
  link: {
    displayName: "",
    query: 'top'
  },
});

const sectionNew = ref({
  products: [],
  isPending: true,
  title: t("home.new.title"),
  icon: "new",
  link: {
    displayName: "",
    query: 'new'
  },
});

const getProducts = async () => {
  const allProducts = await Products.getProducts(`first: 24`);
  if (allProducts) {
    sectionAll.value.products = allProducts.data.products.edges;
    sectionAll.value.link.displayName = t("home.all.link");
    sectionAll.value.isPending = false;
  }

  const topProducts = await Products.getProducts(`tags: "top", first: 24`);
  if (topProducts) {
    sectionTop.value.products = topProducts.data.products.edges;
    sectionTop.value.link.displayName = t("home.top.link");
    sectionTop.value.isPending = false;
  }

  const newProducts = await Products.getProducts(`tags: "new", first: 24`);
  if (newProducts) {
    sectionNew.value.products = newProducts.data.products.edges;
    sectionNew.value.link.displayName = t("home.new.link");
    sectionNew.value.isPending = false;
  }
};

onMounted(async () => {
  await getProducts();
});
</script>

<template>
  <div class="container">
    <div class="content">
      <div class="content__inner">
        <ui-section
          v-if="!sectionAll.isPending"
          :title="sectionAll.title"
          :icon="sectionAll.icon"
          :link="sectionAll.link"
        >
          <base-swiper :products="sectionAll.products" />
        </ui-section>
        <base-section-skeleton v-else />
        <ui-section
          v-if="!sectionTop.isPending"
          :title="sectionTop.title"
          :icon="sectionTop.icon"
          :link="sectionTop.link"
        >
          <base-swiper :products="sectionTop.products" />
        </ui-section>
        <base-section-skeleton v-else />
        <ui-section
          v-if="!sectionNew.isPending"
          :title="sectionNew.title"
          :icon="sectionNew.icon"
          :link="sectionNew.link"
        >
          <base-swiper :products="sectionNew.products" />
        </ui-section>
        <base-section-skeleton v-else />
        <base-description />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content {
  width: 100%;
  margin-top: 4rem;
  display: flex;
  align-items: start;
  gap: 5rem;

  @include mobile-tablet {
    flex-direction: column;
    gap: 4rem;
  }

  &__widgets {
    min-width: 225px;
    width: 225px;

    @include mobile-tablet {
      order: 2;
    }
  }

  &__inner {
    //width: calc(100% - 225px - 5rem);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4rem;

    @include mobile-tablet {
      width: 100%;
      order: 1;
    }
  }
}
</style>