<script setup>
import {ref} from "vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {Search} from "@element-plus/icons-vue";

const router = useRouter()
const {t} = useI18n()

const searchValue = ref("");

const searchQuery = () => {
  router.push({
    name: "market",
    query: {
      name: searchValue.value
    },
    params: {
      locale: localStorage.getItem('user-locale') || 'en-GB'
    },
  });
};
</script>

<template>
  <el-form class="form" @submit.prevent="searchQuery">
    <el-input
      v-model="searchValue"
      class="form__input"
      :placeholder="t('fields.searchPh')"
      :prefix-icon="Search"
    />
  </el-form>
</template>

<style lang="scss">
.form {
  width: 100%;

  &__input {
    width: 100%;
  }
}
</style>