<script setup >
import {computed, ref, watch} from "vue";
import {onClickOutside} from "@vueuse/core";
import {useI18n} from "vue-i18n";
import BaseHeaderProducts from "@/components/base/header/base-header-products.vue";
import HeaderProductsSkeleton from "@/components/skeletons/header/header-products-skeleton.vue";
import {Products} from "@/core/api/products.js";

const {t} = useI18n()

const props = defineProps<{
  subCategories: Array,
  skinType: Array
};

const activeWeapon = ref("all");

const setActiveWeapon = (weapon) => {
  activeWeapon.value = weapon;
};

const shortedWeapons = computed(() =>
  props.subCategories.slice(0, 7)
);

const fulledWeapons = computed(() => props.subCategories.slice(7));

const isFulledMenuVisible = ref(false);

const setFulledMenuVisible = (state) => {
  isFulledMenuVisible.value = state;
};

const menu = ref(null);
const menuButton = ref(null);

onClickOutside(menu, () => setFulledMenuVisible(false), {
  ignore: [menuButton],
});

const selectWeaponFromMenu = (weapon) => {
  setActiveWeapon(weapon);
  setFulledMenuVisible(false);
};

const products = ref(null);

watch(
  () => activeWeapon.value,
  async (val) => {
    if (val === "all") {
      products.value = null;
      const res = await Products.getProducts(`categories: "${props.skinType}"`)
      products.value = res.data.products.edges
      return;
    }
    const res = await Products.getProducts(`categories: "${props.skinType}"`)
    products.value = res.data.products.edges
  },
  { deep: true, immediate: true },
);

watch(
  () => props.skinType,
  async () => {
    products.value = null;
    activeWeapon.value = "all";
    const res = await Products.getProducts(`categories: "${props.skinType}"`)
    products.value = res.data.products.edges
  },
  { deep: true, immediate: true },
);

const $emits = defineEmits(['close'])
</script>

<template>
  <div class="subcategories">
    <ul class="subcategories__list">
      <li class="subcategories__item">
        <el-button
          text
          :class="
            activeWeapon === 'all'
              ? 'subcategories__button subcategories__button--active'
              : 'subcategories__button'
          "
          @click="setActiveWeapon('all')"
        >
          {{ t("header.all") }}
        </el-button>
      </li>
      <li
        v-for="(name, idx) of shortedWeapons"
        :key="idx"
        class="subcategories__item"
      >
        <el-button
          text
          :class="
            activeWeapon === name
              ? 'subcategories__button subcategories__button--active'
              : 'subcategories__button'
          "
          @click="setActiveWeapon(name)"
        >
          {{ name }}
        </el-button>
      </li>
      <li
        v-if="fulledWeapons.length"
        class="subcategories__item subcategories__item-lust"
      >
        <el-button
          ref="menuButton"
          class="subcategories__button"
          text
          @click="setFulledMenuVisible(!isFulledMenuVisible)"
        >
          <span
            :class="
              isFulledMenuVisible || fulledWeapons.includes(activeWeapon)
                ? 'subcategories__button-inner subcategories__button-inner--active'
                : 'subcategories__button-inner'
            "
          >
            <span v-text="t('header.more')" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
              />
            </svg>
          </span>
        </el-button>
      </li>
      <transition name="fromTop">
        <li v-if="isFulledMenuVisible" ref="menu" class="subcategories__menu">
          <ul class="subcategories__list-full">
            <li v-for="(name, idx) of fulledWeapons" :key="idx">
              <el-button
                text
                :class="
                  activeWeapon === name
                    ? 'subcategories__button-full--active'
                    : ''
                "
                @click="selectWeaponFromMenu(name)"
              >
                {{ name }}
              </el-button>
            </li>
          </ul>
        </li>
      </transition>
    </ul>
    <div class="subcategories__products">
      <base-header-products
        v-if="products"
        :products="products.results"
        :total="products.totalTtems"
        :skin_type="skinType"
        :name="activeWeapon === 'all' ? undefined : activeWeapon"
        @close="$emits('close')"
      />
      <header-products-skeleton v-else />
    </div>
  </div>
</template>

<style scoped lang="scss">
.subcategories {
  width: 100%;
  background-color: var(--el-bg-color);
  position: absolute;
  top: 100%;
  left: 0;
  border-top: 0.2rem solid var(--el-bg-color-page);

  &__list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.2rem solid var(--el-bg-color-page);
    padding: 2rem 1rem;
    position: relative;

    &-full {
      padding: 2rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
      background-color: var(--el-bg-darker);
      box-shadow: var(--el-box-shadow-dark);
    }
  }

  &__item {
    &-lust {
      margin-left: auto;
    }
  }

  &__button {
    padding: 3rem 2rem;

    &--active {
      color: var(--el-color-primary);
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: calc(100% + 1.5rem);
        left: 0;
        width: 100%;
        height: 0.5rem;
        background-color: var(--el-color-primary);
      }
    }

    &-inner {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > svg {
        transition: rotate 0.3s ease;
      }

      &--active {
        color: var(--el-color-primary);
        & > svg {
          rotate: 180deg;
        }
      }
    }

    &-full--active {
      color: var(--el-color-primary);
    }
  }

  &__menu {
    position: absolute;
    z-index: 3;
    top: 100%;
    right: 0;
    max-width: 60rem;
    width: 100%;
  }
}
</style>
