<script setup>
import {useI18n} from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import BaseHeaderSlider from "@/components/base/header/base-header-slider.vue";

const props = defineProps<{
  products: Object,
  skinType: String,
  name: String,
  total: Number
};

const { t } = useI18n();

const $emits = defineEmits(['close'])
</script>

<template>
  <div class="products">
    <div class="products__head">
      <h2
        class="products__title"
        v-text="t('header.name', { name: props.name })"
      />
      <router-link
        class="products__link"
        @click="$emits('close')"
        :to="
          translations.i18nRoute(
            `/market?page=1&skin_type=${props.skin_type}&${props.name ? `weapon=${name}` : ''}`,
          )
        "
      >
        <span>{{ t("header.total", { total: props.total }) }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 6 10"
          fill="currentColor"
        >
          <path
            d="M.186 1.1A.644.644 0 0 1 .398.05a.646.646 0 0 1 .712.144l4.12 4.12a.644.644 0 0 1 0 .907l-4.138 4.12a.644.644 0 0 1-.906 0 .644.644 0 0 1 0-.906l3.655-3.672L.186 1.1Z"
          />
        </svg>
      </router-link>
    </div>
    <base-header-slider
      v-if="props.products.length"
      :products="props.products"
      @close="$emits('close')"
    />
  </div>
</template>

<style scoped lang="scss">
.products {
  width: 100%;
  background-color: var(--el-bg-color);
  padding: 5rem 0;

  &__head {
    padding: 0 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  &__title {
    font-size: 2.4rem;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--el-text-color-primary);
    text-transform: uppercase;

    & > svg {
      width: 1.4rem;
      height: 1.4rem;
    }

    @include hover {
      color: var(--el-color-primary);
    }
  }
}
</style>
