<script setup>
import {computed, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import {Auth} from "@/core/api/auth.js";
import {ElNotification} from "element-plus";
import {useAuthStore} from "@/stores/auth.js";
import {logoutUser} from "@/core/helpers/logout.js";
import {useRouter} from "vue-router";

const {t} = useI18n()
const authStore = useAuthStore()
const router = useRouter()

const form = ref();
const model = reactive({
  email: "",
});
const rules = reactive({
  email: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.email") }),
      trigger: "change",
    },
    {
      message: t("fieldErrors.email"),
      validator(rule, value) {
        return /.+@.+\..+/.test(value);
      },
      trigger: "change",
    }
  ],
});

const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : ''
})
const userUuid = computed(() => {
  return authStore.user.uuid ? authStore.user.uuid : ''
})

const isFormValid = computed(() => !!model.email);

const updateUser = async () => {
  const res = await Auth.updateUser(
    `uuid: "${userUuid.value}"` +
      `, email: "${model.email}"`,
      accessToken.value
  )

  if (!res.errors) {
    ElNotification({
      title: t("notifications.success.url.title"),
      message: t("notifications.success.url.text"),
      type: "success",
      duration: 5000,
      position: "bottom-left",
    });

    await logoutUser(router)
  } else {
    ElNotification({
      message: res.errors[0].message,
      type: "error",
      duration: 5000,
      position: "bottom-left",
    });
  }
};
</script>

<template>
  <el-form
    ref="form"
    class="form"
    :model="model"
    require-asterisk-position="right"
    label-position="top"
    :rules="rules"
  >
    <el-form-item class="form__item" prop="email">
      <el-input
        v-model="model.email"
        size="large"
        :placeholder="t('fields.email')"
      />
    </el-form-item>
    <div class="form__buttons">
      <el-button
        class="form__button"
        type="info"
        size="large"
        :disabled="!isFormValid"
        @click="updateUser"
      >
        {{ t("account.save") }}
      </el-button>
      <router-link
        :to="translations.i18nRoute({ name: 'profile' })"
        class="form__button el-button el-button--danger el-button--large"
        type="info"
        size="large"
      >
        {{ t("account.cancel") }}
      </router-link>
    </div>
  </el-form>
</template>

<style scoped lang="scss">
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;

  @include mobile {
    gap: 2rem;
  }

  &__item {
    width: 100%;
  }

  &__buttons {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    row-gap: 1rem;

    @include mobile {
      flex-direction: column;
      width: 100%;
    }
  }

  &__button {
    min-width: 160px;
    @include mobile {
      width: 100%;
    }
  }
}

:deep(.el-input--large) {
  --el-input-inner-height: 48px;
}
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #717777 inset;
}
.el-button--danger {
  --el-button-bg-color: #fa490a;
  --el-button-border-color: #fa490a;
}
</style>