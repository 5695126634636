<script setup>
import { useI18n } from 'vue-i18n'
import en from '@/locales/en-GB.json'
import { ref } from 'vue'

const { t } = useI18n()

const content = ref(en.terms.content)

const doc = ref(null)

const activeAnchor = ref('')
const scrollTo = (id) => {
  activeAnchor.value = id
  const el = document.getElementById(id)
  if (el) {
    window.scrollTo({
      top: el.offsetTop - 50,
      left: 0,
      behavior: 'smooth'
    })
  }
}
</script>

<template>
  <div class="container">
    <div class="doc">
      <div class="doc__nav">
        <div v-for="(anchor, contentIdx) of content" :key="contentIdx" class="navigation">
          <button
              class="navigation__button"
              :class="
          activeAnchor ===
          t(`terms.content[${contentIdx}].title`)
            .toLowerCase()
            .split(' ')
            .join('-')
            ? 'navigation__button--active'
            : ''
        "
              @click="scrollTo(t(`terms.content[${contentIdx}].title`).toLowerCase()
            .split(' ')
            .join('-'))"
          >
            <span v-text="t(`terms.content[${contentIdx}].title`)" />
          </button>
        </div>
      </div>
      <div class="doc__content">
        <div ref="doc" class="doc__head">
          <h1 class="doc__title" v-text="t('terms.title')" />
        </div>
        <div v-for="(section, idx) of content" :key="idx" class="doc-block">
          <h2 class="doc-block__title" v-text="t(`terms.content[${idx}].title`)" />
          <div
              v-for="(block, index) of content[idx].content"
              :id="t(`terms.content[${idx}].title`).toLowerCase().split(' ').join('-')"
              :key="index"
              class="doc-block__inner"
          >
            <p
                v-if="typeof content[idx].content[index] === 'string'"
                class="doc-block__text"
                v-text="t(`terms.content[${idx}].content[${index}]`)"
            />
            <div v-else class="doc-block__inner">
              <h3 class="doc-block__subtitle" v-text="t(`terms.content[${idx}].content[${index}].title`)" />
              <p
                  v-for="(el, textIdx) of content[idx].content[index].content"
                  :key="textIdx"
                  class="doc-block__text"
                  v-text="t(`terms.content[${idx}].content[${index}].content[${textIdx}]`)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.doc {
  width: 100%;
  position: relative;
  display: flex;
  align-items: start;
  gap: 2px;

  &__top {
    position: fixed;
    bottom: 4rem;
    right: 4rem;

    @include mobile {
      bottom: 3rem;
      right: 3rem;
    }
  }

  &__nav {
    width: 100%;
    background-color: var(--el-bg-color);
    max-width: 45rem;
    height: fit-content;
    padding: 5rem;

    @include tablet {
      padding: 3rem;
      max-width: 30rem;
    }

    @include mobile {
      display: none;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.3rem;
  }

  &__head {
    width: 100%;
    padding: 5rem;
    background-color: var(--el-bg-color);
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;

    @include tablet {
      padding: 3rem;
    }

    @include mobile {
      padding: 2rem;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 5rem;
    margin-bottom: 2rem;

    @include tablet {
      font-size: 4rem;
    }

    @include mobile {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }

  &__text {
    color: var(--el-text-color-secondary);
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 2rem;
  padding-left: 4rem;

  @include tablet {
    padding-left: 0;
  }

  &__button {
    width: fit-content;
    text-align: start;
    font-size: 1.6rem;
    line-height: 130%;
    font-weight: 600;
    color: var(--el-text-color-primary);

    @include hover {
      color: var(--el-color-primary);
    }

    &--active {
      padding-left: 1rem;
      color: var(--el-color-primary);
      font-family: 'Montserrat Bold', sans-serif;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        bottom: 0;
        width: 0.4rem;
        border-radius: 0.5rem;
        background-color: var(--el-color-primary);
      }
    }
  }
}

.doc-block {
  width: 100%;
  padding: 5rem;
  background-color: var(--el-bg-color);

  @include tablet {
    padding: 3rem;
  }

  @include mobile {
    padding: 2rem;
  }

  &__title {
    font-family: 'Montserrat Bold', sans-serif;
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  &__inner {
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 0.1rem solid var(--el-text-color-secondary);
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 2rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__text {
    line-height: 150%;
    font-size: 1.6rem;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    padding-left: 20px;
    list-style: disc;
  }

  &__item {
    line-height: 150%;
    font-size: 1.6rem;
  }
}
</style>