<script setup>
</script>

<template>
  <el-skeleton class="sk" animated>
    <template #template>
      <div class="sk__head">
        <el-skeleton-item variant="circle" class="sk__icon" />
        <el-skeleton-item variant="caption" class="sk__caption" />
      </div>
      <el-skeleton-item class="sk__image" variant="image" />
      <div class="sk__titles">
        <el-skeleton-item variant="h1" class="sk__title" />
      </div>
      <div class="sk__info">
        <el-skeleton-item class="sk__text" variant="text" />
        <el-skeleton-item class="sk__text" variant="text" />
        <el-skeleton-item class="sk__text" variant="text" />
        <el-skeleton-item class="sk__text" variant="text" />
      </div>
    </template>
  </el-skeleton>
</template>

<style scoped lang="scss">
.sk {
  width: 100%;
  height: 100%;
  background-color: var(--el-bg-color);
  padding: 2rem;
  min-width: 220px;

  @include mobile {
    padding: 1.5rem;
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__icon {
    width: 2rem;
    height: 2rem;
  }

  &__caption {
    width: 60%;
    height: 1.2rem;
    border-radius: 0.2rem;
  }

  &__image {
    width: 100%;
    height: 18rem;
    margin-bottom: 2rem;
    border-radius: 0.2rem;
  }

  &__titles {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  &__title {
    width: 100%;
    height: 2.5rem;
    border-radius: 0.2rem;

    &:nth-child(2) {
      width: 50%;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__text {
    width: 100%;
    border-radius: 0.2rem;
    height: 1.3rem;

    &:nth-child(2) {
      margin-bottom: 1rem;
      height: 1.6rem;
    }

    &:nth-child(4) {
      width: 100%;
      height: 1.2rem;
      margin-top: 1.5rem;
    }
  }
}
</style>