<script setup>
import { useI18n } from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import BaseLanguageSwitcher from "@/components/base/base-language-switcher.vue";
import {useAppStore} from "@/stores/app.js";
import {computed} from "vue";
import {useCompanyStore} from "@/stores/company.js";

const props = defineProps({
  mobile: Boolean
})

const { t } = useI18n();
const appStore = useAppStore();
const companyStore = useCompanyStore();

const closeMenu = () => {
  appStore.isMenuVisible = false;
};

const company = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo : null
});
const companyEmail = computed(() => {
  return company.value?.emailFrom || '';
});
const companyName = computed(() => {
  if (!company.value?.companyName) return '';
  const [text] = company.value.companyName.split(',').map((part) => part.trim());
  return text;
});
</script>

<template>
  <div class="footer-bottom" :class="mobile ? 'footer-bottom--mobile' : ''">
    <div class="footer-bottom__box">
      <div class="footer-bottom__switcher">
        <base-language-switcher />
      </div>
      <div class="footer-bottom__nav">
        <ul class="footer-bottom__list">
          <li class="footer-bottom__item">
            <router-link
              active-class="footer-bottom__link--active"
              class="footer-bottom__link"
              :to="translations.i18nRoute({ name: 'about' })"
              @click="closeMenu"
              >{{ t("about.title") }}</router-link
            >
          </li>
          <li class="footer-bottom__item">
            <router-link
              active-class="footer-bottom__link--active"
              class="footer-bottom__link"
              :to="translations.i18nRoute({ name: 'faq' })"
              @click="closeMenu"
              >FAQ</router-link
            >
          </li>
          <li class="footer-bottom__item">
            <router-link
              active-class="footer-bottom__link--active"
              class="footer-bottom__link"
              :to="translations.i18nRoute({ name: 'support' })"
              @click="closeMenu"
              >{{ t("footer.support") }}</router-link
            >
          </li>
          <li class="footer-bottom__item">
            <router-link
              active-class="footer-bottom__link--active"
              class="footer-bottom__link"
              :to="translations.i18nRoute({ name: 'jobs' })"
              @click="closeMenu"
              >{{ t("footer.job") }}</router-link
            >
          </li>
        </ul>
        <ul class="footer-bottom__list">
          <li class="footer-bottom__item">
            <router-link
              active-class="footer-bottom__link--active"
              class="footer-bottom__link"
              :to="translations.i18nRoute({ name: 'contact-us' })"
              @click="closeMenu"
              >{{ t("footer.contact") }}</router-link
            >
          </li>
          <li class="footer-bottom__item">
            <router-link
              active-class="footer-bottom__link--active"
              class="footer-bottom__link"
              :to="translations.i18nRoute({ name: 'terms-of-use' })"
              @click="closeMenu"
              >{{ t("terms.title") }}</router-link
            >
          </li>
          <li class="footer-bottom__item">
            <router-link
              active-class="footer-bottom__link--active"
              class="footer-bottom__link"
              :to="translations.i18nRoute({ name: 'privacy-policy' })"
              @click="closeMenu"
              >{{ t("privacy.title") }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom__box">
      <div class="footer-bottom__follow">
        <h5 class="footer-bottom__text" v-text="t('footer.follow')" />
        <div class="footer-bottom__follow-inner">
          <a href="/" class="footer-bottom__follow-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-instagram"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"
              />
            </svg>
          </a>
          <a href="/" class="footer-bottom__follow-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-facebook"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
              />
            </svg>
          </a>
          <a href="/" class="footer-bottom__follow-link">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-telegram"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="footer-bottom__info">
        <a :href="'mailto:' + companyEmail" class="footer-bottom__mail">
          <span>{{ companyEmail }}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-up-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
            />
          </svg>
        </a>
        <span class="footer-bottom__text" v-text="'© 2025' + ' ' + companyName" />
        <span class="footer-bottom__text" v-text="t('footer.steam')" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer-bottom {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 2rem;
  padding: 4rem 0;

  @include mobile {
    flex-direction: column;
    align-items: start;
    gap: 4rem;
  }

  &__box {
    width: 100%;
    display: flex;
    align-items: start;
    gap: 8rem;

    &:first-child {
      justify-content: start;
    }

    &:last-child {
      justify-content: end;
    }

    @include tablet {
      gap: 4rem;
    }

    @include mobile {
      flex-direction: column;
      align-items: start;
      gap: 4rem;
    }
  }

  &__switcher {
    @include mobile {
      order: 2;
    }
  }

  &__nav {
    display: flex;
    align-items: start;
    gap: 5rem;

    @include tablet {
      gap: 2rem;
    }
  }

  &__link {
    color: var(--el-text-color-primary);
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: "Montserrat Medium", sans-serif;

    @include hover {
      color: var(--el-color-primary);
    }

    &--active {
      color: var(--el-color-primary);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__follow {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4rem;

    @include mobile {
      gap: 2rem;
    }

    &-inner {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 3rem;
    }

    &-link {
      color: var(--el-text-color-primary);

      & > svg {
        width: 3rem;
        height: 3rem;
      }

      @include hover {
        color: var(--el-color-primary);
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    max-width: 25rem;
  }

  &__mail {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.8rem;
    font-family: "Montserrat Bold", sans-serif;
    color: var(--el-text-color-primary);
    text-transform: uppercase;
    margin-bottom: 2rem;

    @include hover {
      color: var(--el-color-primary);
    }

    & > svg {
      min-width: 1.8rem;
      width: 1.8rem;
      height: 1.8rem;
    }

    @include mobile {
      margin-bottom: 0;
      font-size: 1.6rem;
    }
  }

  &__text {
    font-size: 1.4rem;
    color: var(--el-text-color-secondary);
    line-height: 130%;
    letter-spacing: 0.12rem;
  }
}

.footer-bottom--mobile {
  flex-direction: column;
  align-items: start;
  gap: 4rem;

  & .footer-bottom__box {
    flex-direction: column;
    align-items: start;
    gap: 4rem;
  }

  & .footer-bottom__switcher {
    order: 2;
  }

  & .footer-bottom__nav {
    gap: 2rem;
  }

  & .footer-bottom__follow {
    gap: 2rem;
  }

  & .footer-bottom__mail {
    margin-bottom: 0;
    font-size: 1.6rem;
  }
}
</style>