<script setup>
</script>

<template>
  <el-skeleton class="filters" animated>
    <template #template>
      <div class="filters__box">
        <div class="filters__head">
          <el-skeleton-item variant="h3" class="filters__title" />
          <el-skeleton-item variant="button" class="filters__icon" />
        </div>
        <div class="filters__buttons">
          <el-skeleton-item variant="button" class="filters__button" />
          <el-skeleton-item variant="button" class="filters__button" />
        </div>
      </div>
      <div class="filters__box">
        <div class="filters__head">
          <el-skeleton-item variant="h3" class="filters__title" />
          <el-skeleton-item variant="button" class="filters__icon" />
        </div>
        <div class="filters__list">
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
        </div>
        <el-skeleton-item variant="button" class="filters__button" />
      </div>
      <div class="filters__box">
        <div class="filters__head">
          <el-skeleton-item variant="h3" class="filters__title" />
          <el-skeleton-item variant="button" class="filters__icon" />
        </div>
        <div class="filters__list">
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
        </div>
        <el-skeleton-item variant="button" class="filters__button" />
      </div>
      <div class="filters__box">
        <div class="filters__head">
          <el-skeleton-item variant="h3" class="filters__title" />
          <el-skeleton-item variant="button" class="filters__icon" />
        </div>
        <div class="filters__list">
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
          <div class="filters__item">
            <el-skeleton-item variant="button" class="filters__icon2" />
            <el-skeleton-item variant="text" class="filters__text2" />
            <el-skeleton-item variant="button" class="filters__link" />
          </div>
        </div>
        <el-skeleton-item variant="button" class="filters__button" />
      </div>
    </template>
  </el-skeleton>
</template>

<style scoped lang="scss">
.filters {
  width: 100%;
  height: 100%;
  background-color: var(--el-bg-color);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5rem;

  &__box {
    width: 100%;
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  &__title {
    width: 50%;
    height: 2rem;
    border-radius: 0.2rem;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.2rem;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.4rem;
    margin-bottom: 2rem;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;

    &:nth-child(even) {
      .filters__text2 {
        width: 50%;
      }
    }
  }

  &__icon2 {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 0.3rem;
  }

  &__text {
    height: 1.5rem;
    width: 40%;
    border-radius: 0.3rem;
  }

  &__text2 {
    height: 1.5rem;
    width: 40%;
    border-radius: 0.3rem;
  }

  &__link {
    margin-left: auto;
    width: 25%;
    height: 1.5rem;
    border-radius: 0.2rem;
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  &__button {
    width: 100%;
    height: 3.5rem;
    border-radius: 0.3rem;
  }
}
</style>
