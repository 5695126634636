<script setup>
import { useI18n } from "vue-i18n";
import { computed, ref, watch } from "vue";

const props = defineProps({
  fill: Object
});

const computedFill = computed(() => props.fill);

const { t } = useI18n();

const customColors = [
  { color: "#e05a59", percentage: 20 },
  { color: "#e9a75d", percentage: 40 },
  { color: "#e3e15b", percentage: 60 },
  { color: "#79d154", percentage: 80 },
  { color: "#5eb648", percentage: 100 },
];

const percentage = ref(5);

watch(
  () => computedFill.value,
  () => {
    const a = Array.from(Object.values(props.fill)).filter((el) => el).length;

    switch (a) {
      case 0:
        percentage.value = 5;
        break;
      case 1:
        percentage.value = 25;
        break;
      case 2:
        percentage.value = 50;
        break;
      case 3:
        percentage.value = 75;
        break;
      case 4:
        percentage.value = 100;
        break;
    }
  },
  { deep: true, immediate: true },
);

const computedTitle = computed(() => {
  if (percentage.value === 5) return t("register.helper.titles.easy");
  if (percentage.value === 25) return t("register.helper.titles.steel");
  if (percentage.value === 50) return t("register.helper.titles.less");
  if (percentage.value === 75) return t("register.helper.titles.good");
  return t("register.helper.titles.whoa");
});
</script>

<template>
  <div class="ui-pass">
    <div class="ui-pass__head">
      <h3 class="ui-pass__title" v-text="computedTitle" />
      <el-progress
        class="ui-pass__progress"
        :percentage="percentage"
        :color="customColors"
        :stroke-width="10"
        :show-text="false"
        striped
        striped-flow
        :duration="10"
      />
    </div>
    <div class="ui-pass__content">
      <span class="ui-pass__content-title" v-text="t('register.helper.text')" />
      <ul class="ui-pass__list">
        <li class="ui-pass__item">
          <svg
            v-if="!computedFill.hasMin"
            class="ui-pass__item-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#E05A59"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
            />
          </svg>
          <svg
            v-else
            class="ui-pass__item-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#5EB648"
            viewBox="0 0 16 16"
          >
            <path
              d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"
            />
          </svg>
          <span v-text="t('register.helper.values.less')" />
        </li>
        <li class="ui-pass__item">
          <svg
            v-if="!computedFill.hasLowerUpper"
            class="ui-pass__item-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#E05A59"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
            />
          </svg>
          <svg
            v-else
            class="ui-pass__item-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#5EB648"
            viewBox="0 0 16 16"
          >
            <path
              d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"
            />
          </svg>
          <span v-text="t('register.helper.values.lowerUpper')" />
        </li>
        <li class="ui-pass__item">
          <svg
            v-if="!computedFill.hasNumber"
            class="ui-pass__item-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#E05A59"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
            />
          </svg>
          <svg
            v-else
            class="ui-pass__item-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#5EB648"
            viewBox="0 0 16 16"
          >
            <path
              d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"
            />
          </svg>
          <span v-text="t('register.helper.values.numbers')" />
        </li>
        <li class="ui-pass__item">
          <svg
            v-if="!computedFill.hasSpecial"
            class="ui-pass__item-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#E05A59"
            viewBox="0 0 16 16"
          >
            <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
            />
          </svg>
          <svg
            v-else
            class="ui-pass__item-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#5EB648"
            viewBox="0 0 16 16"
          >
            <path
              d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z"
            />
          </svg>
          <span v-text="t('register.helper.values.specials')" />
        </li>
      </ul>
    </div>
    <div class="ui-pass__notify">
      <span v-text="t('register.helper.notify')" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.ui-pass {
  width: 100%;
  border-radius: 0.5rem;
  background-color: var(--el-text-color-primary);
  overflow: hidden;
  color: var(--el-bg-color-page);

  &__head {
    padding: 2rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 700;
  }

  &__progress {
    width: 100%;
  }

  &__content {
    padding: 2rem;

    &-title {
      display: block;
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.5rem;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.4rem;

    &-icon {
      min-width: 1.8rem;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__notify {
    padding: 2rem;
    background-color: rgba(var(--el-text-color-secondary), 0.3);
    color: var(--el-text-color-secondary);
    font-size: 1.4rem;
  }
}
</style>

<style lang="scss">
.el-progress-bar__outer {
  background-color: rgba(var(--el-text-color-secondary), 0.3);
}
</style>