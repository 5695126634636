<script setup>
import {ref} from "vue";
import {useI18n} from "vue-i18n";

const { t } = useI18n();

const isPushNotificationOn = ref(false);
</script>

<template>
  <section class="push">
    <div class="push__head">
      <h4 class="push__title" v-text="t('account.pushN.title')" />
    </div>
    <div class="push__inner">
      <div class="push__switcher">
        <el-switch v-model="isPushNotificationOn" />
        <span class="push__text" v-text="t('account.pushN.title')" />
      </div>
      <span class="push__text" v-text="t('account.pushN.text')" />
    </div>
  </section>
</template>

<style scoped lang="scss">
.push {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 2rem;
  }

  &__inner {
    width: 100%;
    padding: 4rem;
    background-color: var(--el-bg-darker);

    @include mobile {
      padding: 2rem;
    }
  }

  &__switcher {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  &__text {
    font-weight: 600;
    font-size: 1.8rem;
    display: inline-block;
  }
}
</style>