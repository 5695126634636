import {createRouter, createWebHistory, RouterView} from 'vue-router'
import HomePage from "@/pages/home-page.vue";
import translation from "@/core/helpers/translations.js";
import DefaultLayout from "@/layouts/default-layout.vue";
import AuthLayout from "@/layouts/auth-layout.vue";
import SignUpPage from "@/pages/sign-up-page.vue";
import SignInPage from "@/pages/sign-in-page.vue";
import ResetPasswordPage from "@/pages/reset-password-page.vue";
import AboutPage from "@/pages/about-page.vue";
import FaqPage from "@/pages/faq-page.vue";
import SupportPage from "@/pages/support-page.vue";
import PrivacyPolicyPage from "@/pages/privacy-policy-page.vue";
import TermsPage from "@/pages/terms-page.vue";
import ContactPage from "@/pages/contact-page.vue";
import JobsPage from "@/pages/jobs-page.vue";
import MarketPage from "@/pages/market-page.vue";
import ProductPage from "@/pages/product-page.vue";
import ProfileBalance from "@/pages/profile-balance.vue";
import Profile from "@/pages/profile.vue";
import ProfileChangePassword from "@/pages/profile-change-password.vue";
import ProfileChangeEmail from "@/pages/profile-change-email.vue";
import ProfileChangeUsername from "@/pages/profile-change-username.vue";
import {checkAuth} from "@/core/guards/notAuthGuard.js";
import ProfileTradeUrl from "@/pages/profile-trade-url.vue";
import ProfileWishlistPage from "@/pages/profile-wishlist-page.vue";
import ProfileCart from "@/pages/profile-cart.vue";
import ProfileOrders from "@/pages/profile-orders.vue";
import ConfirmResetPassword from "@/pages/confirm-reset-password.vue";

const routes = [
  {
    path: '/:locale?',
    component: RouterView,
    beforeEnter: translation.routeMiddleware,
    children: [
      {
        path: '',
        component: DefaultLayout,
        children: [
          {
            path: '',
            name: 'home',
            component: HomePage,
            meta: {
              title: 'Home'
            }
          },
          {
            path: 'market',
            name: 'market',
            component: MarketPage,
            meta: {
              title: 'Market'
            }
          },
          {
            path: 'product/:uuid',
            name: 'product',
            component: ProductPage,
            meta: {
              title: 'Market'
            }
          },
          {
            path: 'cart',
            name: 'cart',
            component: ProfileCart,
            meta: {
              title: 'Cart'
            }
          },
          {
            path: 'orders',
            name: 'orders',
            component: HomePage,
            meta: {
              title: 'Orders'
            }
          },
          {
            path: 'balance',
            name: 'balance',
            component: HomePage,
            meta: {
              title: 'Balance'
            }
          },
          {
            path: 'account',
            name: 'account',
            component: HomePage,
            meta: {
              title: 'Account'
            }
          },
          {
            path: 'wishlist',
            name: 'wishlist',
            component: ProfileWishlistPage,
            meta: {
              title: 'Profile | Wishlist'
            },
            beforeEnter: checkAuth
          },
          {
            path: 'privacy-policy',
            name: 'privacy-policy',
            component: PrivacyPolicyPage,
            meta: {
              title: 'Privacy policy'
            }
          },
          {
            path: 'terms-of-use',
            name: 'terms-of-use',
            component: TermsPage,
            meta: {
              title: 'Terms of use'
            }
          },
          {
            path: 'contact-us',
            name: 'contact-us',
            component: ContactPage,
            meta: {
              title: 'Contact us'
            }
          },
          {
            path: 'jobs',
            name: 'jobs',
            component: JobsPage,
            meta: {
              title: 'Jobs'
            }
          },
          {
            path: 'support',
            name: 'support',
            component: SupportPage,
            meta: {
              title: 'Support'
            }
          },
          {
            path: 'about',
            name: 'about',
            component: AboutPage,
            meta: {
              title: 'About'
            }
          },
          {
            path: 'faq',
            name: 'faq',
            component: FaqPage,
            meta: {
              title: 'FAQ'
            }
          },
          {
            path: 'activate-user',
            name: 'activate-user',
            component: HomePage,
            meta: {
              title: 'Home'
            }
          },
          {
            path: 'profile',
            name: 'profile',
            component: Profile,
            meta: {
              title: 'Profile'
            },
            beforeEnter: checkAuth
          },
          {
            path: 'profile/balance',
            name: 'balance',
            component: ProfileBalance,
            meta: {
              title: 'Profile | Balance'
            },
            beforeEnter: checkAuth
          },
          {
            path: 'profile/change-password',
            name: 'change-password',
            component: ProfileChangePassword,
            meta: {
              title: 'Profile | Change password'
            },
            beforeEnter: checkAuth
          },
          {
            path: 'profile/change-email',
            name: 'change-email',
            component: ProfileChangeEmail,
            meta: {
              title: 'Profile | Change email'
            },
            beforeEnter: checkAuth
          },
          {
            path: 'profile/change-username',
            name: 'change-username',
            component: ProfileChangeUsername,
            meta: {
              title: 'Profile | Change username'
            },
            beforeEnter: checkAuth
          },
          {
            path: 'profile/trade-url',
            name: 'trade-url',
            component: ProfileTradeUrl,
            meta: {
              title: 'Profile | Trade url'
            },
            beforeEnter: checkAuth
          },
          {
            path: 'profile/orders',
            name: 'orders',
            component: ProfileOrders,
            meta: {
              title: 'Profile | Orders'
            },
            beforeEnter: checkAuth
          }
        ]
      },
      {
        path: 'auth',
        component: AuthLayout,
        children: [
          {
            path: 'sign-in',
            name: 'sign-in',
            component: SignInPage,
            meta: {
              title: 'Sign in'
            }
          },
          {
            path: 'sign-up',
            name: 'sign-up',
            component: SignUpPage,
            meta: {
              title: 'Sign up'
            }
          },
          {
            path: 'reset',
            name: 'reset',
            component: ResetPasswordPage,
            meta: {
              title: 'Reset password'
            }
          },
          {
            path: 'reset-password',
            name: 'reset-password',
            component: ConfirmResetPassword,
            meta: {
              title: 'Confirm reset password'
            }
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.querySelector('#top').scrollIntoView({ behavior: 'smooth' })
    return {
      top: 0,
      left: 0,
      behavior: 'smooth'
    }
  }
})

router.beforeEach((to, from) => {
  document.title = to.meta.title ? 'Gameskins | ' + to.meta?.title : 'Gameskins'
})

export default router
