<script setup>
import {useAuthStore} from "@/stores/auth.js";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";

const { t } = useI18n();
const authStore = useAuthStore();

const user = computed(() => authStore.user);

const isSubscribed = ref(user.value.is_subscribed);

// const updateUser = async () => {
//   await updateEmailSubscribe(isSubscribed.value);
// };
</script>

<template>
  <section class="email">
    <div class="email__head">
      <h4 class="email__title" v-text="t('account.emailN.title')" />
    </div>
    <div class="email__inner">
      <p class="email__text" v-text="t('account.emailN.subtitle')" />
<!--      <el-checkbox v-model="isSubscribed" @change="updateUser">-->
      <el-checkbox v-model="isSubscribed">
        <span v-text="t('account.emailN.text')" />
      </el-checkbox>
    </div>
  </section>
</template>

<style scoped lang="scss">
.email {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 2rem;
  }

  &__inner {
    width: 100%;
    padding: 4rem 4rem 3rem;
    border: 2px solid var(--el-bg-darker);
    display: flex;
    flex-direction: column;

    @include mobile {
      padding: 2rem;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    display: inline-block;
  }
}
</style>