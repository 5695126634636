<script setup>
import { ElNotification } from "element-plus";
import {useI18n} from "vue-i18n";
import {computed, onMounted, ref} from "vue";
import {Auth} from "@/core/api/auth.js";
import {useRoute} from "vue-router";
import HomeSwiper from "@/components/home/home-swiper.vue";
import HomeSwiperSkeleton from "@/components/skeletons/home/home-swiper-skeleton.vue";
import HomeContent from "@/components/home/home-content.vue";

const route = useRoute()

const { t } = useI18n();

const token = computed(() =>
  route.query.token ? (route.query.token) : undefined,
);
const id = computed(() =>
  route.query.uid ? (route.query.uid) : undefined,
);

const activateAccount = async () => {
  if (token.value && id.value && route.name === "activate-user") {
    const res = await Auth.activateUser(token.value, id.value)

    if (res.data.activateUser) {
        ElNotification({
        title: t("notifications.success.activate.title"),
        type: "success",
        duration: 5000,
        position: "bottom-left",
      });
    } else {
      ElNotification({
        title: t("notifications.error.activate.title"),
        message: t("notifications.error.activate.text"),
        type: "error",
        duration: 5000,
        position: "bottom-left",
      });
    }
  }

  if (token.value && id.value && route.name === "reset-password") {
    const res = await Auth.activateUser(token.value, id.value)

    if (res.data.activateUser) {
        ElNotification({
        title: t("notifications.success.activate.title"),
        type: "success",
        duration: 5000,
        position: "bottom-left",
      });
    } else {
      ElNotification({
        title: t("notifications.error.activate.title"),
        message: t("notifications.error.activate.text"),
        type: "error",
        duration: 5000,
        position: "bottom-left",
      });
    }
  }
};

const isLoaded = ref(false)

onMounted(async () => {
  await activateAccount();
  setTimeout(() => {
    isLoaded.value = true
  }, 1000)
});
</script>

<template>
  <div>
    <home-swiper v-if="isLoaded" />
    <home-swiper-skeleton v-else />
    <home-content />
  </div>
</template>

<style scoped></style>
