<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import ProductPreviewSkeleton from "@/components/skeletons/product/product-preview-skeleton.vue";
import ProductInfoSkeleton from "@/components/skeletons/product/product-info-skeleton.vue";
import ProductVariantsSkeleton from "@/components/skeletons/product/product-variants-skeleton.vue";
import ProductPreview from "@/components/product/product-preview.vue";
import UiSection from "@/components/ui/ui-section.vue";
import BaseSwiper from "@/components/base/base-swiper.vue";
import ProductInfo from "@/components/product/product-info.vue";
import BaseSectionSkeleton from "@/components/skeletons/base/base-section-skeleton.vue";
import {nameAdapter} from "@/core/helpers/nameAdapter.js";
import {scrollTop} from "@/core/helpers/scrollTop.js";
import {Products} from "@/core/api/products.js";
import {useRoute} from "vue-router";

const { t } = useI18n()
const route = useRoute()

const productId = computed(() => {
  return route.params.uuid ? route.params.uuid : ''
})

const isProductsPending = ref(true)

const product = ref(null)
const productStickers = ref(null)
const productAttributes = ref(null)
const similarProducts = ref(null)
const moreOffersForProduct = ref(null)

const variants = ref([])

const showMoreOffers = computed(() => {
  if (!moreOffersForProduct.value || isProductsPending.value) return false
  return !!moreOffersForProduct.value?.filter((el) => el.node.uuid !== productId.value).length
})

const showSimilarProducts = computed(() => {
  if (!similarProducts.value || isProductsPending.value) return false
  return !!similarProducts.value?.filter((el) => el.node.uuid !== productId.value).length
})

const toSimilarLink = computed(() => {
  if (!similarProducts.value) return null

  const link = {
    path: `/market?page=1&skin_type=${product.value.skin_type}&name=${nameAdapter(product.value.name)}`,
    displayName: t('productPage.total', { total: similarProducts.value?.total_items }),
  }

  if (product.value.weapon) link.path = link.path + `&weapon=${product.value.weapon}`

  return link
})
const toMoreOffers = computed(() => {
  if (!moreOffersForProduct.value) return null

  const link = {
    path: `/market?page=1&skin_type=${product.value.skin_type}&name=${nameAdapter(product.value.name)}`,
    displayName: t('productPage.total', { total: moreOffersForProduct.value?.total_items }),
  }

  if (product.value.exterior) link.path = link.path + `&exterior=${product.value.exterior}`
  if (product.value.rarity) link.path = link.path + `&rarity=${product.value.rarity}`
  if (product.value.quality) link.path = link.path + `&quality=${product.value.quality}`

  return link
})

const getProducts = async () => {
  isProductsPending.value = true

  const result = await Products.getProductById(productId.value)
  if (!result) {
    // #TODO add redirect to 404
    isProductsPending.value = false

    return
  }

  product.value = result.data.products.edges[0].node
  const steamInfoGroup = result.data.products.edges[0].node.attributeGroups.edges.find(
    (group) => group.node.name === "Skin Info"
  );

  if (steamInfoGroup) {
    const stickerAttribute = steamInfoGroup.node.attributes.find(
      (attr) => attr.name === "stickers"
    );
    productAttributes.value = steamInfoGroup.node.attributes.filter((attr) => attr.name !== "stickers")
    productStickers.value = stickerAttribute?.values.map((sticker) => {
      const { uuid, ...rest } = JSON.parse(sticker.value);
      return rest;
    })[0];
  }

  if (product.value.category.name) {
    const res = await Products.getProducts(`categories: "${product.value.category.name}", first: 24`)
    similarProducts.value = res.data.products.edges
  }

  // let query = `?page=1&name=${product.value.name}`
  //
  // if (product.value.skin_type) query = query + `&skin_type=${product.value.skin_type}`
  // if (product.value.exterior) query = query + `&exterior=${product.value.exterior}`
  // if (product.value.rarity) query = query + `&rarity=${product.value.rarity}`
  // if (product.value.quality) query = query + `&quality=${product.value.quality}`

  const res = await Products.getProducts(`first: 24`)
  moreOffersForProduct.value = res.data.products.edges

  // await getVariants()

  isProductsPending.value = false
}

// const getVariants = async () => {
//   const res = (await Products.getProducts(`?page_size=100&skin_type=${product.value.skin_type}&name=${nameAdapter(product.value.name)}`)).results
//       .filter((el) => el.exterior)
//       .sort((a, b) => {
//         if (a.exterior.toLowerCase() < b.exterior.toLowerCase()) return -1
//         if (a.exterior.toLowerCase() > b.exterior.toLowerCase()) return 1
//         return 0
//       })
//
//   for (let i = 0; i < res.length; i++) {
//     variants.value.push({
//       name: res[i].exterior,
//       price: res[i].price,
//       slug: `${res[i].skin_type}-${res[i].weapon ? res[i].weapon + '-' : ''}${nameAdapter(res[i].name)}`,
//       uuid: res[i].uuid
//     })
//   }
// }

onMounted(async () => {
  await getProducts()
})

watch(
    () => productId.value,
    async () => {
      scrollTop()
      await getProducts()
    },
    { deep: true }
)
</script>

<template>
  <section class="product">
    <div class="container">
      <div class="product__inner">
        <div v-if="isProductsPending" class="product__content">
          <div class="product__content-inner">
            <product-preview-skeleton />
            <product-info-skeleton />
            <product-variants-skeleton />
          </div>
          <div class="product__content-info">
            <product-info-skeleton />
          </div>
        </div>
        <div v-else class="product__content">
          <div v-if="product" class="product__content-inner">
            <product-preview
                :name="nameAdapter(product.name)"
                :float="product.float ? product.float : undefined"
                :image="product.images.edges[0].node.image"
                :run-game-url="product.link ? product.link : undefined"
                :category="product.category.name"
                :weapon="product.weapon ? product.weapon : undefined"
                :uuid="product.uuid"
            />
            <div class="product__content-info--mobile">
              <product-info
                  :product="product"
                  :attributes="productAttributes"
                  :stickers="productStickers"
              />
            </div>
            <!--            <product-variants :variants="variants" v-if="variants.length" />-->
          </div>
          <div class="product__content-info">
            <product-info
                :product="product"
                :attributes="productAttributes"
                :stickers="productStickers"
            />
          </div>
        </div>
        <base-section-skeleton v-if="isProductsPending" />
        <base-section-skeleton v-if="isProductsPending" />
        <div v-if="showMoreOffers" class="product__section">
          <ui-section :title="t('productPage.offers')" :link="toMoreOffers">
            <div class="product__section-slider">
              <base-swiper :products="moreOffersForProduct" />
            </div>
          </ui-section>
        </div>
        <div v-if="showSimilarProducts" class="product__section">
          <ui-section :title="t('productPage.items')" :link="toSimilarLink">
            <div class="product__section-slider">
              <base-swiper :products="similarProducts" />
            </div>
          </ui-section>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.product {
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4rem;
  }

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: stretch;
    grid-gap: 0.2rem;

    @include mobile-tablet {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.2rem;
    }

    &-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.2rem;
    }

    &-info {
      @include mobile-tablet {
        display: none;
      }

      &--mobile {
        width: 100%;
        display: none;

        @include mobile-tablet {
          display: block;
        }
      }
    }
  }

  &__section {
    width: 100%;

    &-slider {
      position: relative;
    }
  }
}
</style>