<script setup>
import { useI18n } from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import UiAnimatedBackground from "@/components/ui/ui-animated-background.vue";
import {useCompanyStore} from "@/stores/company.js";
import {computed} from "vue";

const { t } = useI18n();
const companyStore = useCompanyStore();

const company = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo : null
})
const companyEmail = computed(() => {
  return company.value?.emailFrom || '';
});
</script>

<template>
  <div>
    <ui-animated-background />
    <div class="container">
      <section class="about">
        <div class="about__head">
          <h1 class="about__title" v-text="t('about.title')" />
          <div class="about__links">
            <router-link
              class="about__links-item"
              active-class="about__links-item--active"
              :to="translations.i18nRoute({ name: 'about' })"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 26 30"
              >
                <path
                  d="M26 8.357v13.277a1.5 1.5 0 0 1-.744 1.296l-11.369 6.634a1.5 1.5 0 0 1-1.5.007L.758 22.936A1.5 1.5 0 0 1 0 21.633L.005 8.375A1.5 1.5 0 0 1 .76 7.073L12.398.429a1.5 1.5 0 0 1 1.499.007l11.359 6.626A1.5 1.5 0 0 1 26 8.357zm-2 1.334a1.2 1.2 0 0 0-.597-1.038l-9.65-5.608a1.2 1.2 0 0 0-1.193-.007L2 9v12l11.15 6.3L23.997 21 24 9.69zM13.5 14a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h1zm0-4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1z"
                />
              </svg>
              <span v-text="t('about.title')" />
            </router-link>
            <router-link
              class="about__links-item"
              active-class="about__links-item--active"
              :to="translations.i18nRoute({ name: 'faq' })"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-question-circle"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                />
                <path
                  d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"
                />
              </svg>
              <span v-text="'FAQ'" />
            </router-link>
            <router-link
              class="about__links-item"
              active-class="about__links-item--active"
              :to="translations.i18nRoute({ name: 'support' })"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 19 19"
              >
                <path
                  d="M3.364 16.092A9 9 0 1 1 16.092 3.364 9 9 0 0 1 3.364 16.092zm1.414-1.414a7 7 0 1 0 9.9-9.9 7 7 0 0 0-9.9 9.9zm1.414-1.415a5 5 0 1 1 7.071-7.07 5 5 0 0 1-7.07 7.07zm1.415-1.414a3 3 0 1 0 4.242-4.242 3 3 0 0 0-4.242 4.242zm-.582-6.172c.301.314.233.873-.153 1.25-.386.376-.944.427-1.246.113l-1.68-1.746c-.302-.314-.234-.874.153-1.25.386-.377.943-.428 1.245-.114l1.68 1.747zm8.485 8.485c.302.314.233.874-.153 1.25-.386.377-.944.427-1.245.114l-1.68-1.747c-.303-.314-.234-.873.152-1.25.386-.376.944-.427 1.245-.114l1.68 1.747zM14.162 3.946c.314-.302.874-.234 1.25.153.377.386.427.943.114 1.245l-1.747 1.68c-.314.302-.873.234-1.25-.152-.376-.386-.427-.944-.114-1.246l1.747-1.68zM5.677 12.43c.314-.302.873-.233 1.25.153.376.386.427.944.113 1.245l-1.746 1.68c-.314.303-.874.234-1.25-.152-.377-.386-.428-.944-.114-1.245l1.747-1.68z"
                />
              </svg>
              <span v-text="t('support.shortTitle')" />
            </router-link>
          </div>
        </div>
        <div class="about__content">
          <h2 class="about__subtitle" v-text="t('about.subtitle')" />
          <p class="about__text" v-text="t('about.text')" />
          <i18n-t tag="p" class="about__text" keypath="about.link">
            <template #link>
              <a :href="'mailto:' + companyEmail" class="about__link">
                {{companyEmail}}
              </a>
            </template>
          </i18n-t>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
.about {
  width: 100%;
  min-height: 50vh;
  padding: 4rem 4rem;

  @include tablet {
    padding: 4rem 2rem;
  }

  @include mobile {
    padding: 4rem 0;
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
    margin-bottom: 6rem;

    @include mobile {
      margin-bottom: 4rem;
    }
  }

  &__title {
    font-size: 5rem;
    font-weight: 700;

    @include mobile {
      font-size: 2.6rem;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 3rem;

    &-item {
      font-size: 1.6rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 1rem;
      color: var(--el-text-color-primary);
      text-transform: uppercase;

      & > svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      @include hover {
        color: var(--el-color-primary);
      }

      &--active {
        color: var(--el-color-primary);
      }
    }

    @include mobile {
      flex-wrap: wrap;
      gap: 2rem;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4rem;
  }

  &__subtitle {
    font-size: 6rem;
    font-weight: 700;
    max-width: 65%;
    line-height: 120%;
    display: flex;
    align-items: end;

    @include tablet {
      font-size: 5rem;
    }

    @include mobile-tablet {
      max-width: none;
    }

    @include mobile {
      font-size: 3rem;
    }
  }

  &__text {
    max-width: 50%;
    font-size: 2rem;
    line-height: 150%;
    font-weight: 500;

    @include mobile-tablet {
      max-width: none;
    }
  }

  &__link {
    font-weight: 700;
    color: var(--el-color-info);

    @include hover {
      color: var(--el-color-info-light-5);
    }
  }
}
</style>