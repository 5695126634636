<script setup>
import {useAppStore} from "@/stores/app.js";
import {useI18n} from "vue-i18n";
import {computed, ref} from "vue";
import ProductFilters from "@/components/product/product-filters.vue";
import {useMarketStore} from "@/stores/market.js";

const appStore = useAppStore();
const marketStore = useMarketStore();

const isFiltersVisible = computed(() => appStore.isMobilFiltersVisible);

const {t} = useI18n()

const closeFilters = () => {
  appStore.isMobilFiltersVisible = false
}

const ordering = computed(() => marketStore.ordering)

const currentSorting = ref({
  displayName: t('market.sorting.rating'),
  value: ordering.value
})

const switchSorting = (newSort) => {
  currentSorting.value = newSort
  marketStore.ordering = newSort.value
}
</script>

<template>
  <article :class="['filters-article', {'filters-article--open': isFiltersVisible}]">
    <div class="filters-article__inner">
      <div class="filters-article__head">
<!--        <el-select-->
<!--            v-model="currentSorting"-->
<!--            class="market__tools-select"-->
<!--            :suffix-icon="UiSelectIcon"-->
<!--            popper-class="market-sorting-select"-->
<!--            @change="switchSorting(currentSorting)"-->
<!--        >-->
<!--        <el-select-->
<!--            v-model="currentSorting"-->
<!--            class="market__tools-select"-->
<!--            :suffix-icon="UiSelectIcon"-->
<!--            popper-class="market-sorting-select"-->
<!--            @change="switchSorting(currentSorting)"-->
<!--        >-->
<!--          <el-option-->
<!--              v-for="option of SortingOptionsList()"-->
<!--              :key="option.value"-->
<!--              :label="option.displayName"-->
<!--              :value="option"-->
<!--          />-->
<!--        </el-select>-->

        <button class="filters-article__close" @click="closeFilters">
          <svg xmlns="http://www.w3.org/2000/svg" id="close" viewBox="0 0 21 21" fill="currentColor">
            <path d="M9.138 10.5.282 1.645A.963.963 0 1 1 1.645.282L10.5 9.138 19.355.282a.963.963 0 0 1 1.363 1.363L11.862 10.5l8.856 8.855a.963.963 0 0 1-1.363 1.363L10.5 11.862l-8.855 8.856a.963.963 0 0 1-1.363-1.363L9.138 10.5z"/>
          </svg>
        </button>
      </div>
      <div class="filters-article__content">
        <product-filters />
      </div>
      <div class="filters-article__bottom">
        <el-button
            class="filters-article__button"
            type="info"
            @click="closeFilters"
        >
          {{ t('product.filters.button') }}
        </el-button>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss">
.filters-article {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
  display: none;
  background-color: var(--el-bg-color);
  z-index: 999;
  transition: all 0.3s ease;

  @include tablet {
    width: 50vw;
    transform: translateX(50vw);
  }

  @include mobile-tablet {
    display: block;
  }

  @include mobile {
    width: 100vw;
    transform: translateX(100vw);
  }

  &--open {
    @include mobile-tablet {
      transform: translateX(0);
    }
  }

  &__head {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    background-color: var(--el-bg-color);
    gap: 2rem;
    z-index: 1001;
  }

  &__close {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: calc(50% - 1px);
    color: var(--el-text-color-primary);

    & svg {
      width: 20px;
    }

    @include hover {
      color: var(--el-color-primary);
      background-color: var(--el-bg-color-page);
    }

    &:active {
      color: var(--el-color-primary);
      background-color: var(--el-bg-color-page);
    }
  }

  &__inner {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 1rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--el-bg-color-page);
      border: 0.2rem solid var(--el-bg-color);
    }
  }

  &__bottom {
    padding: 2rem;
    position: sticky;
    bottom: 0;
    background-color: var(--el-bg-color);
  }

  &__button {
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    height: 4rem!important;
  }
}
</style>