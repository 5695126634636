<script setup>
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {useAuthStore} from "@/stores/auth.js";
import translations from "@/core/helpers/translations.js";

const { t } = useI18n();
const authStore = useAuthStore();

const userEmail = computed(() => {
  return authStore.user.email ? authStore.user.email : ''
});
const userFirstName = computed(() => {
  return authStore.user.firstName ? authStore.user.firstName : ''
});
const userLastName = computed(() => {
  return authStore.user.lastName ? authStore.user.lastName : ''
});
</script>

<template>
  <section class="general">
    <div class="general__head">
      <h4 class="general__title" v-text="t('account.general.title')" />
      <router-link
        class="general__link"
        :to="translations.i18nRoute({ name: 'change-password' })"
      >
        <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 13 13">
          <g fill-rule="nonzero" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path d="M1.564 4.498h9.872a1 1 0 0 1 .998 1.063l-.375 6.001a1 1 0 0 1-.998.938H1.939a1 1 0 0 1-.998-.938L.566 5.561a1 1 0 0 1 .998-1.063Z"/>
            <path d="m3.522 4.533-.016-3.027a1 1 0 0 1 1-1.006H8.5a1 1 0 0 1 1 1v3m-3 3v2"/>
          </g>
        </svg>
        <span v-text="t('account.general.changePass')" />
      </router-link>
    </div>
    <div class="general__inner">
      <div class="general__item">
        <span
          class="general__item-label general__text"
          v-text="t('fields.email')"
        />
        <span class="general__item-value" v-text="userEmail" />
        <router-link
          class="general__update"
          :to="translations.i18nRoute({ name: 'change-email' })"
        >
          <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 19 19">
            <path d="m232.215 20.41 11.75-12.14 4.25 4.14-12 12h-4z" transform="translate(-231 -7)" fill-rule="nonzero"
                stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
      <div class="general__item">
        <span
          class="general__item-label general__text"
          v-text="t('fields.fName')"
        />
        <span class="general__item-value" v-text="userFirstName" />
        <router-link
          class="general__update"
          :to="translations.i18nRoute({ name: 'change-username' })"
        >
          <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 19 19">
            <path d="m232.215 20.41 11.75-12.14 4.25 4.14-12 12h-4z" transform="translate(-231 -7)" fill-rule="nonzero"
                stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
      <div class="general__item">
        <span
          class="general__item-label general__text"
          v-text="t('fields.lName')"
        />
        <span class="general__item-value" v-text="userLastName" />
        <router-link
          class="general__update"
          :to="translations.i18nRoute({ name: 'change-username' })"
        >
          <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 19 19">
            <path d="m232.215 20.41 11.75-12.14 4.25 4.14-12 12h-4z" transform="translate(-231 -7)" fill-rule="nonzero"
                stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.general {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  position: relative;
  z-index: 5;
  background-color: var(--el-bg-color);

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 2rem;
  }

  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: var(--el-bg-darker);
    padding: 3rem;
    position: relative;
    z-index: 5;

    @include mobile {
      padding: 2rem;
    }

    &-label {
      flex: 0 0 34%;
      @include mobile {
        flex: 0;
        margin-right: 1rem;
      }
    }

    &-value {
      flex: 1 0 34%;
      color: var(--el-text-color-secondary);
      font-size: 1.8rem;

      @include mobile {
        font-size: 1.4rem;
      }
    }
  }

  &__text {
    font-weight: 600;
    font-size: 1.8rem;
    display: inline-block;

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__update {
    margin-left: auto;
    position: relative;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--el-text-color-primary);

    &::after {
      width: 100%;
      height: 100%;
      background: none;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      transform: scale(0);
      transition-duration: 0.25s;
      transition-property: transform, opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      content: "";
      z-index: -1;
    }

    @include hover {
      color: var(--el-color-primary);

      &::after {
        background: rgba(29, 32, 33, 45%);
        transform: scale(2);
        opacity: 1;
      }
    }
  }

  &__link {
    text-transform: uppercase;
    color: var(--el-text-color-primary);
    letter-spacing: 0.1rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    @include hover {
      color: var(--el-color-primary-light-5);
    }

    &:active {
      color: var(--el-color-primary-dark-2);
    }

    & svg {
      width: 20px;
    }
  }
}
</style>