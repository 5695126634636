<script setup>
import { onMounted, ref } from 'vue'
import translations from "@/core/helpers/translations.js";

const logotype = ref(null)

onMounted(() => {
  setTimeout(() => {
    if (!logotype.value) return
    logotype.value.style.fill = 'rgba(250, 74, 11, 1)'
    logotype.value.style.stroke = 'transparent'
    logotype.value.classList.add('logotype__animated')
  }, 2900)
})
</script>

<template>
  <router-link :to="translations.i18nRoute({ name: 'home' })">
    <div ref="logotype" class="logotype">
      <svg>
        <text x="50%" y="50%" dy=".35em" text-anchor="middle">GAME SKINS</text>
      </svg>
      <span />
    </div>
  </router-link>
</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

.logotype {
  height: 2.2rem;
  width: 17.5rem;
  stroke: #ffffff;
  display: flex;
  align-items: end;
  position: relative;

  @include mobile-tablet {
    width: 13.9rem;
    height: 2rem;
  }

  &__animated {
    & > span {
      opacity: 1;
      height: 88%;

      @include mobile {
        height: 82%;
      }
    }
  }
}

svg {
  font-family: 'Kanit', sans-serif;
  width: 100%;
  height: 100%;
}
svg text {
  animation: stroke 3s alternate;
  stroke-width: 1;
  font-size: 3rem;
  fill: inherit;
  stroke: inherit;

  @include mobile-tablet {
    font-size: 2.5rem;
  }
}

@keyframes stroke {
  0% {
    fill: rgba(250, 74, 11, 0);
    stroke: rgba(255, 255, 255, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(250, 74, 11, 0);
    stroke: rgba(255, 255, 255, 1);
  }
  80% {
    fill: rgba(250, 74, 11, 0);
    stroke: rgba(255, 255, 255, 1);
    stroke-width: 3;
  }
  100% {
    fill: $el-color-primary;
    stroke: rgba(255, 255, 255, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}
</style>