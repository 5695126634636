<script setup>
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import {useCartStore} from "@/stores/cart.js";
import {useWishlistStore} from "@/stores/wishlist.js";
import {useAuthStore} from "@/stores/auth.js";
import WishlistEmpty from "@/components/wishlist/wishlist-empty.vue";
import {ElNotification} from "element-plus";
import WishlistList from "@/components/wishlist/wishlist-list.vue";
import {Wishlist} from "@/core/api/wishlist.js";

const wishlistStore = useWishlistStore();
const authStore = useAuthStore();
const cartStore = useCartStore();
const { t } = useI18n();

const token = computed(() => authStore.accessToken);

const totalItemsInWishlist = computed(() => {
  return wishlistStore.wishlist.products ? wishlistStore.wishlist.products.edges.length : 0
});

const totalItemsPrice = computed(() => {
  let res = 0
  wishlistStore.wishlist.products?.edges.forEach((product) => {
    res += product.node.price
  });

  return res
});

// const selectedItems = computed(() =>
//   wishlistStore.wishlist.products?.edges.filter((product) => product.node.isSelected)
// );

// const isSelectable = computed(() => wishlistStore.isSelectable);

// const setSelectable = (state) => {
//   wishlistStore.isSelectable = state;
// };

// const selectAllItems = () => {
//   wishlistStore.wishlist.products?.edges.forEach((el) => {
//     el.node.isSelected = true;
//   });
// };

// const deselectAllItems = () => {
//   wishlistStore.wishlist.products?.edges.forEach((el) => {
//     el.isSelected = false;
//   });
//   setSelectable(false);
// }

// const removeSelectedItemsFromWishlist = async () => {
//   const removedItems = wishlistStore
//     .wishlist.products?.map((product) => {
//       if (!product.isSelected)
//         return {
//           uuid: product.uuid,
//         };
//       return null;
//     })
//     .filter((el) => el);
//
//   await Wishlist.overwriteWishlist(
//     token.value,
//     wishlistStore.wishlist.uuid,
//     removedItems
//   );
//
//   ElNotification({
//     title: t("notifications.success.removeWishlistItems.title"),
//     message: t("notifications.success.removeWishlistItems.text"),
//     type: "success",
//     position: "bottom-left",
//   });
//
//   wishlistStore.wishlist = await Wishlist.getWishlist(token.value);
// };

// const addSelectedItemsToCart = async () => {
//   const selectedProductsInCart = [];
//
//   selectedItems.value.forEach((selectedProduct) => {
//     let isIncluded = false;
//     cartStore.currentOrder.order_products.forEach((orderProduct) => {
//       if (selectedProduct.uuid === orderProduct.product.uuid) isIncluded = true;
//     });
//
//     if (!isIncluded) selectedProductsInCart.push(selectedProduct);
//   });
//
//   const allProductsInCart = selectedProductsInCart.map((el) => {
//     return {
//       uuid: el.uuid,
//     };
//   });
//
//   const res = await updateOrder(
//     token.value,
//     [...allProductsInCart, ...cartStore.currentOrder],
//     cartStore.currentOrder.uuid,
//   );
//
//   if (res) {
//     ElNotification({
//       title: t("notifications.success.addProducts.title"),
//       message: t("notifications.success.addProducts.text"),
//       type: "success",
//       position: "bottom-left",
//     });
//
//     cartStore.setCurrentOrders(res);
//
//     wishlistStore.wishlist = await getWishlists(token.value);
//
//     return;
//   }
//
//   ElNotification({
//     title: t("notifications.error.addProduct.title"),
//     message: t("notifications.error.addProduct.text"),
//     duration: 5000,
//     type: "error",
//     position: "bottom-left",
//   });
// };
</script>

<template>
  <div class="wishlist">
    <div class="wishlist__inner container">
      <div class="wishlist__content" v-if="totalItemsInWishlist">
        <div class="wishlist__head">
          <div class="wishlist__price">
            <span>{{ t("wishlist.item", totalItemsInWishlist) }}</span>
            <span>~</span>
            <span v-text="totalItemsPrice?.toFixed(2)" />
            <img src="@icons/coin.svg" alt="coin">
          </div>
<!--          <div class="wishlist__tools">-->
<!--            <button-->
<!--              v-if="!isSelectable"-->
<!--              class="wishlist__tools-button"-->
<!--              @click="setSelectable(true)"-->
<!--            >-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 9">-->
<!--                <path d="M5.829 6.443a.5.5 0 0 1 .713.701l-.939.956a.5.5 0 0 1-.713 0L1.04 4.195a.5.5 0 1 1 .713-.702l3.493 3.545.583-.594zm3.99-2.636a.5.5 0 1 1-.68-.733l3.02-2.81a.5.5 0 1 1 .681.732l-3.02 2.81z"/>-->
<!--                <path d="m10.248 7.04 6.78-6.82a.5.5 0 0 1 .709.706L10.6 8.103a.5.5 0 0 1-.71-.002L6.04 4.194a.5.5 0 1 1 .712-.702l3.495 3.547z"/>-->
<!--              </svg>-->
<!--              <span>{{ t("wishlist.select") }}</span>-->
<!--            </button>-->
<!--            <i18n-t-->
<!--              v-if="isSelectable"-->
<!--              class="wishlist__price"-->
<!--              tag="span"-->
<!--              scope="global"-->
<!--              keypath="wishlist.selected"-->
<!--            >-->
<!--              <template #count>{{ selectedItems.length }}</template>-->
<!--              <template #items>-->
<!--                {{ t("wishlist.item", totalItemsInWishlist) }}-->
<!--              </template>-->
<!--            </i18n-t>-->
<!--            <button-->
<!--              v-if="isSelectable"-->
<!--              class="wishlist__tools-button"-->
<!--              @click="selectAllItems"-->
<!--            >-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 9">-->
<!--                <path d="M5.829 6.443a.5.5 0 0 1 .713.701l-.939.956a.5.5 0 0 1-.713 0L1.04 4.195a.5.5 0 1 1 .713-.702l3.493 3.545.583-.594zm3.99-2.636a.5.5 0 1 1-.68-.733l3.02-2.81a.5.5 0 1 1 .681.732l-3.02 2.81z"/>-->
<!--                <path d="m10.248 7.04 6.78-6.82a.5.5 0 0 1 .709.706L10.6 8.103a.5.5 0 0 1-.71-.002L6.04 4.194a.5.5 0 1 1 .712-.702l3.495 3.547z"/>-->
<!--              </svg>-->
<!--              <span>{{ t("wishlist.selectAll") }}</span>-->
<!--            </button>-->
<!--            <button-->
<!--              v-if="isSelectable"-->
<!--              class="wishlist__tools-button"-->
<!--              @click="deselectAllItems"-->
<!--            >-->
<!--              <svg xmlns="http://www.w3.org/2000/svg" id="close" viewBox="0 0 21 21" fill="currentColor">-->
<!--                <path d="M9.138 10.5.282 1.645A.963.963 0 1 1 1.645.282L10.5 9.138 19.355.282a.963.963 0 0 1 1.363 1.363L11.862 10.5l8.856 8.855a.963.963 0 0 1-1.363 1.363L10.5 11.862l-8.855 8.856a.963.963 0 0 1-1.363-1.363L9.138 10.5z"/>-->
<!--              </svg>-->
<!--              <span>{{ t("wishlist.deselect") }}</span>-->
<!--            </button>-->
<!--          </div>-->
        </div>
        <wishlist-list />
<!--        <transition name="fromBottom">-->
<!--          <div class="wishlist__buttons" v-if="selectedItems.length">-->
<!--            <div class="wishlist__buttons-inner">-->
<!--              <button-->
<!--                class="wishlist__buttons-item"-->
<!--                @click="addSelectedItemsToCart"-->
<!--              >-->
<!--                {{ t("wishlist.add") }}-->
<!--              </button>-->
<!--              <button-->
<!--                class="wishlist__buttons-item"-->
<!--                @click="removeSelectedItemsFromWishlist"-->
<!--              >-->
<!--                {{ t("wishlist.delete", { count: selectedItems.length }) }}-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </transition>-->
      </div>
      <wishlist-empty v-else />
    </div>
  </div>
</template>

<style scoped lang="scss">
.wishlist {
  width: 100%;

  &__inner {
    width: 100%;
    display: flex;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2px;
  }

  &__head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 2rem;

    @include mobile {
      padding: 2rem 0;
      gap: 1rem;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--el-text-color-secondary);
    letter-spacing: 0.12rem;
    font-weight: 500;

    & img {
      width: 20px;
    }

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__tools {
    display: flex;
    align-items: center;
    gap: 3rem;

    @include mobile {
      flex-direction: column;
      width: 100%;
      align-items: start;
      gap: 1rem;
    }

    &-button {
      text-transform: uppercase;
      color: var(--el-text-color-primary);
      display: flex;
      align-items: center;
      gap: 0.8rem;
      font-size: 1.4rem;

      @include hover {
        color: var(--el-color-primary);
      }
    }
  }

  &__buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0 5rem;
    background-color: transparent;

    @include mobile-tablet {
      padding: 0;
    }

    &-inner {
      width: 100%;
      padding: 1rem 4rem;
      background-color: var(--el-bg-darker);
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: end;

      @include mobile {
        gap: 0;
        padding: 0;
      }
    }

    &-item {
      text-transform: uppercase;
      font-weight: 700;
      color: var(--el-text-color-primary);
      border-radius: 5px;
      padding: 0 6rem;
      height: 8rem;
      border: 2px solid;
      font-size: 12px;
      letter-spacing: 0.12rem;

      &:nth-child(1) {
        background-color: var(--el-color-info);
        border-color: var(--el-color-info);

        @include hover {
          background-color: var(--el-color-info-light-5);
          border-color: var(--el-color-info-light-5);
        }

        &:active {
          background-color: var(--el-color-info-dark-2);
          border-color: var(--el-color-info-dark-2);
        }
      }

      &:nth-child(2) {
        @include hover {
          background-color: rgba(#fa6b38, 0.2);
          border-color: var(--el-color-primary);
          color: var(--el-color-primary);
        }

        &:active {
          background-color: rgba(#fa6b38, 0.2);
          border-color: var(--el-color-primary);
          color: var(--el-color-primary);
        }

        @include mobile {
          background-color: var(--el-bg-darker);
          border: none;

          &:active {
            background-color: rgba(#fa6b38, 0.2);
            border-color: var(--el-bg-darker);
            color: var(--el-color-primary);
          }
        }
      }

      @include mobile {
        border-radius: 0;
        width: 100%;
        height: 50px;
        padding: 0;
      }
    }
  }
}
</style>