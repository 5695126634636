<script setup>
</script>

<template>
  <el-skeleton class="tools" animated>
    <template #template>
      <div class="tools__box tools__crumbs">
        <el-skeleton-item variant="text" class="tools__text" />
        <el-skeleton-item variant="text" class="tools__text" />
      </div>
      <div class="tools__box tools__total">
        <el-skeleton-item variant="text" class="tools__text" />
        <el-skeleton-item variant="circle" class="tools__circle" />
      </div>
      <div class="tools__box tools__buttons">
        <el-skeleton-item variant="button" class="tools__button" />
        <el-skeleton-item variant="button" class="tools__button" />
      </div>
    </template>
  </el-skeleton>
</template>

<style scoped lang="scss">
.tools {
  width: 100%;
  height: 100%;
  background-color: var(--el-bg-color);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @include mobile {
    padding: 1.5rem;
  }

  &__box {
    width: 100%;
  }

  &__crumbs {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__text {
    width: 20%;
    height: 2rem;
    border-radius: 0.2rem;

    &:nth-child(1) {
      width: 35%;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 2rem;
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  &__circle {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
  }

  &__button {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.2rem;
  }
}
</style>