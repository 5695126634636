<script setup>
</script>

<template>
  <el-skeleton class="sk-product-variants" animated>
    <template #template>
      <el-skeleton-item variant="h3" class="sk-product-variants__title" />
      <ul class="sk-product-variants__list">
        <li class="sk-product-variants__item">
          <el-skeleton-item variant="text" class="sk-product-variants__name" />
          <el-skeleton-item variant="text" class="sk-product-variants__name" />
        </li>
        <li v-for="el of 5" :key="el" class="sk-product-variants__item">
          <el-skeleton-item variant="text" class="sk-product-variants__value" />
          <el-skeleton-item variant="text" class="sk-product-variants__value" />
          <el-skeleton-item variant="text" class="sk-product-variants__value" />
        </li>
      </ul>
      <el-skeleton-item variant="button" class="sk-product-variants__button" />
    </template>
  </el-skeleton>
</template>

<style scoped lang="scss">
.sk-product-variants {
  width: 100%;
  background-color:  var(--el-bg-color);
  padding: 5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;

  &__title {
    height: 3rem;
    border-radius: 0.2rem;
    width: 20%;
    margin-bottom: 4rem;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 4rem;
  }

  &__item {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    padding: 1.5rem 0;
  }

  &__name {
    height: 1.8rem;
    width: 35%;
    border-radius: 0.2rem;

    &:nth-child(2) {
      width: 50%;
    }
  }

  &__value {
    height: 2.5rem;
    width: 40%;
    border-radius: 0.2rem;

    &:first-child {
      width: 60%;
    }

    &:last-child {
      margin-left: auto;
      width: 60%;
    }
  }

  &__button {
    align-self: end;
    height: 5rem;
    width: 20%;
  }
}
</style>