import {apiTemplate} from "@/core/helpers/api.js";

export class Products {
  static async getProducts(data) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `query {
        products ${data ? `(${data})` : ''} {
          edges {
            cursor
            node {
              uuid
              name
              price
              images {
                edges {
                  node {
                    image
                  }
                }
              }
              category {
                name
              }
              attributeGroups {
                edges {
                  node {
                    name
                    uuid
                    attributes {
                      name
                      uuid
                      values {
                        value
                        uuid
                      }
                    }
                  }
                }
              }
              feedbacks {
                edges {
                  node {
                    rating
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }`
    );

    return await response;
  }

  static async getProductById(productId) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `query {
        products (uuid: "${productId}") {
          edges {
            node {
              uuid
              name
              price
              images {
                edges {
                  node {
                    image
                  }
                }
              }
              category {
                name
              }
              attributeGroups {
                edges {
                  node {
                    name
                    uuid
                    attributes {
                      name
                      uuid
                      values {
                        value
                        uuid
                      }
                    }
                  }
                }
              }
              feedbacks {
                edges {
                  node {
                    rating
                  }
                }
              }
            }
          }
        }
      }`
    );

    return await response;
  }
}