import {defineStore} from "pinia";
import {ref} from "vue";

export const useAppStore = defineStore('app', () => {
  const isMenuVisible = ref(false);
  const isCookieManageVisible = ref(false);
  const isFiltersVisible = ref(true);
  const isHeaderToolsVisible = ref(false);
  const isHeaderCartVisible = ref(false);
  const isMobilFiltersVisible = ref(false);

  return {
    isMenuVisible,
    isCookieManageVisible,
    isFiltersVisible,
    isHeaderToolsVisible,
    isHeaderCartVisible,
    isMobilFiltersVisible
  };
})