<script setup>

</script>

<template>
  <div class="products">
    <el-skeleton class="sk-head-list" animated>
      <template #template>
        <el-skeleton-item variant="text" class="sk-head-list__text" />
        <el-skeleton-item variant="text" class="sk-head-list__text" />
      </template>
    </el-skeleton>
    <div class="products__list">
      <el-skeleton
          v-for="i of 30"
          :key="i"
          class="sk-product-card"
          animated
      >
        <template #template>
          <el-skeleton-item variant="image" class="sk-product-card__image" />
          <el-skeleton-item variant="h3" class="sk-product-card__title" />
          <el-skeleton-item variant="text" class="sk-product-card__text" />
        </template>
      </el-skeleton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.products {
  width: 100%;
  background-color: var(--el-bg-color);
  padding: 5rem;

  &__list {
    width: 100%;
    display: flex;
    align-items: stretch;
    gap: .2rem;
    overflow-x: hidden;
  }
}

.sk-head-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;

  &__text {
    &:nth-child(1) {
      height: 2rem;
      width: 20rem;
      border-radius: .5rem;
    }

    &:nth-child(2) {
      height: 1.6rem;
      width: 15rem;
    }
  }
}

.sk-product-card {
  min-width: 180px;
  max-width: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-color: var(--el-bg-darker);
  padding: 2rem;
  border-radius: 0.2rem;

  &__image {
    width: 100%;
    height: 112px;
    border-radius: 0.2rem;
    margin-bottom: 0.5rem;
  }

  &__title {
    height: 2rem;
    border-radius: 0.2rem;
    width: 70%;
  }

  &__text {
    height: 1.6rem;
    border-radius: 0.2rem;
    width: 100%;
  }
}
</style>