<script setup>
import { useI18n } from "vue-i18n";
import { computed, onMounted, onUnmounted, ref } from "vue";
import {Orders} from "@/core/api/orders.js";
import {useAuthStore} from "@/stores/auth.js";
import OrdersItem from "@/components/orders/orders-item.vue";
import OrderItemSkeleton from "@/components/skeletons/orders/order-item-skeleton.vue";

const { t } = useI18n();
const authStore = useAuthStore();

const accessToken = computed(() => authStore.accessToken);
const userEmail = computed(() => authStore.user?.email);
const orders = ref([]);
const deliveringOrders = ref([])
const isLoading = ref(true);

const getOrders = async () => {
  isLoading.value = true

  setTimeout(async () => {
    const data = await Orders.getOrders("", accessToken.value, userEmail.value);
    orders.value = data.data.orders.edges
      .filter((order) => order.node.status !== 'PENDING')
      .filter((order) => order.node.status !== 'DELIVERING')

    deliveringOrders.value = data.data.orders.edges
      .filter((order) => order.node.status === 'DELIVERING')

    isLoading.value = false;
  }, 500);
};

let a = setInterval(() => {});

onMounted(async () => {
  await getOrders();

  if (deliveringOrders.value.length) {
    a = setInterval(async () => {
      await getOrders();
    }, 10000);
  }
});

onUnmounted(() => {
  clearInterval(a);
});
</script>

<template>
  <div class="container">
    <div class="orders">
      <div class="orders__head">
        <h1 class="orders__title" v-text="t('orders.title')" />
      </div>
      <order-item-skeleton v-if="isLoading" />
      <div class="orders__content" v-if="deliveringOrders.length && !isLoading">
        <orders-item
          v-for="order of deliveringOrders"
          :key="order.node.uuid"
          :order="order.node"
        />
      </div>
      <div class="orders__content" v-if="orders.length && !isLoading">
        <orders-item
          v-for="order of orders"
          :key="order.node.uuid"
          :order="order.node"
        />
      </div>
      <p class="orders__empty" v-if="orders.length === 0 && deliveringOrders.length === 0 && !isLoading">{{ t('orders.empty') }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.orders {
  width: 100%;
  background-color: var(--el-bg-color);
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5rem;

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem 4rem;
    border-bottom: 0.1rem solid var(--el-text-color-placeholder);

    @include mobile {
      padding: 0 2rem 2rem;
    }
  }

  &__title {
    font-size: 18px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 50px;
  }

  &__empty {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--el-text-color-placeholder);
  }
}
</style>
