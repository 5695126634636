<script setup>
import {dayjs} from "element-plus";
import {useI18n} from "vue-i18n";
import OrdersProduct from "@/components/orders/orders-product.vue";

const props = defineProps({
  order: Object
})

const {t} = useI18n()
</script>

<template>
  <div class="order">
    <div class="order__head">
      <span class="order__head-box">
        <span class="order__head-name" v-text="t('orders.date')"></span>
        <span
          class="order__head-value"
          v-text="order.buyTime ? dayjs(order.buyTime).format('DD MMM YYYY | HH:mm') : '-'"
        />
      </span>
      <div class="order__head-box">
        <span class="order__head-name" v-text="t('orders.total')" />
        <span class="order__head-value" v-text="order.totalPrice" />
      </div>
      <div class="order__head-box">
        <span class="order__head-name" v-text="t('orders.status')" />
        <span class="order__head-value" v-text="order.status" />
      </div>
      <div class="order__head-box">
        <span class="order__head-name" v-text="'UUID: '" />
        <span class="order__head-value" v-text="order.uuid" />
      </div>
    </div>
    <div class="order__content">
      <orders-product
        v-for="product in order.orderProducts.edges"
        :product="product.node"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.order {
  &__head {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 10px;
    border-bottom: 0.1rem solid var(--el-text-color-placeholder);

    &-name {
      font-weight: 600;
      font-size: 16px;
      color: #f6f6f6;
    }
  }

  &__content {
    padding-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 220px));
    grid-gap: 2px;

    @media (max-width: 500px) {
      grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
    }
  }
}
</style>
