<script setup>
import { computed, ref } from 'vue'
import {ElMessage, ElNotification} from 'element-plus'
import { onClickOutside } from '@vueuse/core'
import {useCartStore} from "@/stores/cart.js";
import {useAuthStore} from "@/stores/auth.js";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import translations from "@/core/helpers/translations.js";
import {nameAdapter} from "@/core/helpers/nameAdapter.js";
import {Orders} from "@/core/api/orders.js";
import {useCopy} from "@/core/hooks/useCopy.js";
import {Wishlist} from "@/core/api/wishlist.js";
import {useWishlistStore} from "@/stores/wishlist.js";

const props = defineProps({
  product: Object,
  stickers: Object,
  attributes: Object
})

const { t, locale } = useI18n()
const route = useRoute()
const authStore = useAuthStore()
const cartStore = useCartStore()
const wishlistStore = useWishlistStore()

const user = computed(() => authStore.user)
const accessToken = computed(() => authStore.accessToken)
const orderUuid = computed(() => {
  return cartStore.currentOrder.uuid ? cartStore.currentOrder.uuid : null
})
const wishlistUuid = computed(() => {
  return wishlistStore.wishlist ? wishlistStore.wishlist.uuid : ''
})

const isProductInCart = computed(() => {
  const el = cartStore.currentOrder?.orderProducts?.edges.find((el) => el.node.product.uuid === props.product.uuid);
  return !!el;
});

const manageProduct = async () => {
  if (user.value) {
    if (isProductInCart.value) {
      const res = await Orders.overwriteCart("removeOrderProduct", accessToken.value, orderUuid.value, props.product.uuid);

      if (!res.errors) {
        cartStore.setCurrentOrders(res.data.removeOrderProduct.order)

        ElNotification({
          title: t("notifications.success.removeProduct.title"),
          message: t("notifications.success.removeProduct.text", {
            name: props.product.name,
          }),
          duration: 5000,
          type: "success",
          position: "bottom-left",
        });
      } else {
        ElNotification({
          message: res.errors[0].message,
          type: "error",
          duration: 5000,
          position: "bottom-left",
        });
      }
    } else {
      const res = await Orders.overwriteCart("addOrderProduct", accessToken.value, orderUuid.value, props.product.uuid);

      if (!res.errors) {
        cartStore.setCurrentOrders(res.data.addOrderProduct.order)

        ElNotification({
          title: t("notifications.success.addProduct.title"),
          message: t("notifications.success.addProduct.text", {
            name: props.product.name,
          }),
          duration: 5000,
          type: "success",
          position: "bottom-left",
        });
      } else {
        ElNotification({
          message: res.errors[0].message,
          type: "error",
          duration: 5000,
          position: "bottom-left",
        });
      }
    }
  } else {
    ElNotification({
      title: t("notifications.error.mustBeLogin.title"),
      message: t("notifications.error.mustBeLogin.text"),
      type: "error",
      duration: 5000,
      position: "bottom-left",
    });
  }
}

// const tags = computed(() => {
//   const result = []
//
//   result.push(
//       {
//         exterior: props.product.exterior
//       },
//       { quality: props.product.quality },
//       { rarity: props.product.rarity },
//       { skin_type: props.product.skin_type },
//       { name: props.product.name_tag },
//       { name: nameAdapter(props.product.name) }
//   )
//
//   return result.filter((item) => Object.keys(item).some((key) => typeof item[key] === 'string'))
// })

const typeOrWeapon = computed(() => {
  return props.product.weapon || props.product.skin_type
})

const fullUrl = computed(
    () => `https://gameskins.eu/${locale.value}/product /${props.product.uuid}`
)

const shareByEmailUrl = computed(() => {
  const subject = t('productPage.emailUrlSubject', {
    category: props.product.category.name,
    name: nameAdapter(props.product.name)
  })
  return `mailto:?subject=${subject}&body=${fullUrl.value}`
})
const shareByFacebookUrl = computed(() => {
  return `https://www.facebook.com/sharer/sharer.php?u=${fullUrl.value}`
})
const shareByTwitterUrl = computed(() => {
  const subject = t('productPage.emailUrlSubject', {
    category: props.product.category.name,
    name: nameAdapter(props.product.name)
  })
  return `https://twitter.com/share?text=${subject}&url=${fullUrl.value}`
})
const shareByTelegramUrl = computed(() => {
  const subject = t('productPage.emailUrlSubject', {
    skin_type: props.product.skin_type,
    name: nameAdapter(props.product.name)
  })
  return `https://t.me/share/url?url=${fullUrl.value}&text=${subject}&`
})
const steamCompareUrl = computed(() => {
  return `https://steamcommunity.com/market/listings/730/${props.product.name}`
})

const shareButton = ref(null)
const shareMenu = ref(null)
const isShareOpen = ref(false)
const setShareVisible = (state) => {
  isShareOpen.value = state
}
onClickOutside(shareMenu, () => setShareVisible(false), { ignore: [shareButton] })
const copyUrl = () => {
  setShareVisible(false)
  useCopy(fullUrl.value)

  ElMessage({
    message: t('productPage.copied'),
    type: 'success',
    duration: 5000
  })
}

const computedRating = computed(() => Math.floor(props.product.feedbacks.edges[0]?.node.rating / 2))

const rating = ref(computedRating.value)

const isProductInWishlist = computed(() => {
  const el = wishlistStore.wishlist.products?.edges.find((el) => el.node.uuid === props.product.uuid);
  return !!el;
});

const addOrRemoveWishlist = async () => {
  if (user.value) {
    if (isProductInWishlist.value) {
      const res = await Wishlist.overwriteWishlist('removeWishlistProduct', accessToken.value, wishlistUuid.value, props.product.uuid)
      wishlistStore.wishlist = res.data.removeWishlistProduct.wishlist

      ElNotification({
        title: t("notifications.success.removeWishlist.title"),
        message: t("notifications.success.removeWishlist.text", {
          name: props.product.name
        }),
        type: "success",
        position: "bottom-left",
      });
    } else {
      const res = await Wishlist.overwriteWishlist('addWishlistProduct', accessToken.value, wishlistUuid.value, props.product.uuid)
      wishlistStore.wishlist = res.data.addWishlistProduct.wishlist

      ElNotification({
        title: t("notifications.success.addWishlist.title"),
        message: t("notifications.success.addWishlist.text", {
          name: props.product.name
        }),
        type: "success",
        position: "bottom-left",
      });
    }
  } else {
    ElNotification({
      title: t("notifications.error.mustBeLogin.title"),
      message: t("notifications.error.addProduct.text"),
      type: "error",
      duration: 5000,
      position: "bottom-left",
    });
  }
};
</script>

<template>
  <div class="product-info">
    <div class="product-info__data">
      <span class="product-info__weapon" v-text="typeOrWeapon" />
      <span class="product-info__name" v-text="nameAdapter(product.name)" />
      <span
          class="product-info__types"
          v-text="`${product.category.name}`"
      />
    </div>
    <div class="product-info__price">
      <div class="product-info__price-container">
        <img src="@icons/coin.svg" alt="" class="product-info__coin" >
        <span v-text="product.price" />
      </div>
      <span
          class="product-info__price-suggest"
          v-text="t('product.suggest', { price: product.price })"
      />
    </div>
    <div class="product-info__tools">
      <el-button
          class="product-info__tools-add"
          :type="isProductInCart ? 'primary' : 'info'"
          size="large"
          @click="manageProduct"
      >{{ isProductInCart ? t('productPage.remove') : t('productPage.add') }}</el-button>
      <div class="product-info__tools-container">
        <button class="product-info__tools-button" @click="addOrRemoveWishlist">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-heart-fill"
              viewBox="0 0 16 16"
          >
            <path
                fill-rule="evenodd"
                d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
            />
          </svg>
          <span v-text="t('productPage.wishlistAdd')" />
        </button>
        <a target="_blank" :href="steamCompareUrl" class="product-info__tools-button">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 14">
            <path
                d="M.64 4.702h12.516l2.026-.195a.644.644 0 0 0 0-.907L11.752.187a.644.644 0 0 0-.908 0 .644.644 0 0 0 0 .906l2.312 2.311H.64a.637.637 0 0 0-.64.64c0 .356.284.658.64.658Zm14.204 4.551H2.33L.32 9.45c-.036.035-.053.071-.089.107 0 .017-.018.017-.018.035a.135.135 0 0 0-.035.071c0 .018-.018.036-.018.054 0 .017-.018.035-.018.053 0 .035-.018.089-.018.124a.3.3 0 0 0 .018.125c0 .018.018.035.018.053.018.036.018.053.018.071.018.018.018.054.035.071 0 .018.018.018.018.036a.471.471 0 0 0 .089.107l3.413 3.413a.646.646 0 0 0 .925 0 .644.644 0 0 0 0-.907l-2.311-2.31h12.515c.356 0 .64-.285.64-.64-.018-.356-.302-.659-.658-.659Z"
            />
          </svg>
          <span v-text="t('productPage.compare')" />
        </a>
        <button
            ref="shareButton"
            class="product-info__tools-button"
            :class="isShareOpen ? 'product-info__tools-button--active' : ''"
            @click="setShareVisible(!isShareOpen)"
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-share-fill"
              viewBox="0 0 16 16"
          >
            <path
                d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5"
            />
          </svg>
          <span v-text="t('productPage.share')" />
        </button>
        <transition name="fromBottom">
          <div v-if="isShareOpen" ref="shareMenu" class="product-info__share">
            <button class="product-info__share-head" @click="copyUrl">
              <div class="product-info__share-head-inner">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-copy"
                    viewBox="0 0 16 16"
                >
                  <path
                      fill-rule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                  />
                </svg>
                <span v-text="t('productPage.shareL')" />
              </div>
              <span class="product-info__share-text" v-text="t('productPage.shareD')" />
            </button>
            <div class="product-info__share-list">
              <a class="product-info__share-link" :href="shareByEmailUrl">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-envelope"
                    viewBox="0 0 16 16"
                >
                  <path
                      d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"
                  />
                </svg>
                <span v-text="'E-Mail'" />
              </a>
              <a class="product-info__share-link" target="_blank" :href="shareByFacebookUrl">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                >
                  <path
                      d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
                  />
                </svg>
                <span v-text="'Facebook'" />
              </a>
              <a class="product-info__share-link" target="_blank" :href="shareByTwitterUrl">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-twitter-x"
                    viewBox="0 0 16 16"
                >
                  <path
                      d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
                  />
                </svg>
                <span v-text="'X'" />
              </a>
              <a class="product-info__share-link" target="_blank" :href="shareByTelegramUrl">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-telegram"
                    viewBox="0 0 16 16"
                >
                  <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"
                  />
                </svg>
                <span v-text="'Telegram'" />
              </a>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="product-info__rating">
      <div class="product-info__rating-head">
        <h3 class="product-info__label" v-text="t('productPage.rating')" />
        <el-tooltip placement="top" trigger="hover" effect="light">
          <template #content>
            <span class="product-info__rating-tooltip" v-text="t('productPage.ratingD')" />
          </template>
          <svg
              class="product-info__rating-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path
                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"
            />
          </svg>
        </el-tooltip>
      </div>
      <div class="product-info__rating-value">
        <el-rate v-model="rating" allow-half />
        <span v-text="t('productPage.rating', { rating: product.feedbacks.edges[0]?.node.rating ? product.feedbacks.edges[0]?.node.rating : '0' })" />
      </div>
    </div>
    <div v-if="stickers" class="product-info__stickers">
      <h4 class="product-info__label" v-text="t('productPage.included')" />
      <div v-for="(sticker, idx) of stickers" :key="idx" class="product-info__sticker">
        <img :src="sticker.img" :alt="sticker.name" class="product-info__sticker-image" >
        <span class="product-info__sticker-name" v-text="sticker.name" />
      </div>
    </div>
    <div class="product-info__summary">
      <h3 class="product-info__label" v-text="t('productPage.summary.title')" />
      <ul class="product-info__summary-list">
<!--        <li v-if="rarity" class="product-info__summary-item">-->
<!--          <span class="product-info__summary-value" v-text="t('productPage.summary.rarity')" />-->
<!--          <div class="product-info__summary-container">-->
<!--            <span class="product-info__summary-name" v-text="product.rarity" />-->
<!--            <span class="product-info__summary-color" :style="{ backgroundColor: product.color }" />-->
<!--          </div>-->
<!--        </li>-->
<!--        <li v-if="quality" class="product-info__summary-item">-->
<!--          <span class="product-info__summary-value" v-text="t('productPage.summary.quality')" />-->
<!--          <span class="product-info__summary-name" v-text="product.quality" />-->
<!--        </li>-->
<!--        <li v-if="product.exterior" class="product-info__summary-item">-->
<!--          <span class="product-info__summary-value" v-text="t('productPage.summary.exterior')" />-->
<!--          <span class="product-info__summary-name" v-text="product.exterior" />-->
<!--        </li>-->
<!--        <li v-if="product.name_tag" class="product-info__summary-item">-->
<!--          <span class="product-info__summary-value" v-text="t('productPage.summary.name_tag')" />-->
<!--          <span class="product-info__summary-name" v-text="product.name_tag" />-->
<!--        </li>-->
        <li class="product-info__summary-item" v-for="attribute in attributes">
          <span class="product-info__summary-value" v-text="attribute.name.replace(/_/g, ' ')" />
          <span class="product-info__summary-name" v-for="item in attribute.values" :key="item.uuid" v-text="item.value" />
        </li>
      </ul>
    </div>
<!--    <div class="product-info__tags">-->
<!--      <router-link-->
<!--          v-for="(tag, idx) of tags"-->
<!--          :key="idx"-->
<!--          class="product-info__tag"-->
<!--          :to="translations.i18nRoute({ name: 'market', query: { tag: tag }} )"-->
<!--      >-->
<!--        {{ Object.values(tag).join('') }}-->
<!--      </router-link>-->
<!--    </div>-->
  </div>
</template>

<style scoped lang="scss">
.product-info {
  width: 100%;
  height: fit-content;
  background-color: var(--el-bg-color);
  padding: 5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4rem;

  @include mobile {
    padding: 3rem 1.5rem;
  }

  &__data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__name {
    font-size: 3rem;
    font-weight: 700;
  }

  &__types {
    color: var(--el-text-color-secondary);;
  }

  &__price {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    @include mobile {
      gap: 1rem;
    }

    &-container {
      display: flex;
      align-items: center;
      gap: 3rem;

      @include mobile {
        gap: 1.5rem;
      }

      & > span {
        font-size: 5rem;
        font-weight: 700;

        @include mobile {
          font-size: 3.5rem;
        }
      }
    }

    &-suggest {
      color: var(--el-text-color-secondary);;
      font-size: 1.8rem;
    }
  }

  &__coin {
    width: 5rem;
    height: 5rem;
    transform: rotate(15deg);

    @include mobile {
      width: 4rem;
      height: 4rem;
    }
  }

  &__tools {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    &-add {
      width: 100%;
    }

    &-container {
      width: 100%;
      border-radius: 0.5rem;
      display: flex;
      align-items: stretch;
      position: relative;
    }

    &-button {
      width: 100%;
      border: 0.1rem solid var(--el-text-color-secondary);
      color: var(--el-text-color-secondary);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 2rem 1rem;
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;

      &:nth-child(1) {
        border-radius: 0.5rem 0 0 0.5rem;
        border-right: none;
      }

      &:nth-child(3) {
        border-radius: 0 0.5rem 0.5rem 0;
      }

      & > svg {
        min-width: 1.4rem;
        width: 1.4rem;
        height: 1.4rem;

        @include mobile {
          width: 2rem;
          height: 2rem;
        }
      }

      @include hover {
        background-color: var(--el-text-color-primary);
        border-color: var(--el-text-color-primary);
        color: var(--el-bg-color-page);
      }

      &--active {
        border-color: var(--el-color-primary);
        color: var(--el-text-color-primary);
        background-color: transparent;

        @include hover {
          border-color: var(--el-color-primary);
          color: var(--el-text-color-primary);
          background-color: transparent;
        }
      }

      @include mobile {
        padding: 1.5rem 1rem;

        & > span {
          display: none;
        }
      }
    }
  }

  &__share {
    position: absolute;
    background-color: var(--el-bg-darker);
    box-shadow: 0 1rem 3rem #1d2021;
    z-index: 5;
    right: 0;
    top: calc(100% + 2rem);
    width: max-content;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    &-head {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 1rem;
      padding: 1.5rem 2rem;
      color: var(--el-text-color-primary);
      font-weight: 500;
      border-bottom: 0.1rem solid var(--el-text-color-secondary);

      &-inner {
        display: flex;
        align-items: center;
        gap: 1rem;
        color: var(--el-text-color-primary);
        font-weight: 500;
        & > svg {
          min-width: 2rem;
          width: 2rem;
          height: 2rem;
        }
      }

      @include hover {
        background-color: rgba(#717777, 0.4);
      }
    }

    &-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    &-link {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1.5rem 2rem;
      color: var(--el-text-color-primary);
      font-weight: 500;

      @include hover {
        background-color: rgba(#717777, 0.4);
      }

      & > svg {
        min-width: 2rem;
        width: 2rem;
        height: 2rem;
      }
    }

    &-text {
      font-size: 1.4rem;
      color: var(--el-text-color-secondary);
    }
  }

  &__rating {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 3rem;

    &-head {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-icon {
      min-width: 2rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;

      @include hover {
        color: var(--el-color-primary);
      }

      &:active {
        color: var(--el-color-primary);
      }
    }

    &-tooltip {
      max-width: 30rem;
      width: 100%;
      display: block;
    }

    .product-info__label {
      margin: 0;
    }

    &-value {
      display: flex;
      align-items: center;
      gap: 2rem;
      flex-wrap: wrap;
    }
  }

  &__summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    &-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 1rem;
    }

    &-container {
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-color {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.3rem;
    }

    &-item {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &-value {
      text-transform: capitalize;
      color: var(--el-text-color-secondary);
    }

    &-name {
      font-weight: 500;
    }
  }

  &__tags {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__tag {
    padding: 1rem 1.5rem;
    border-radius: 0.3rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 500;
    background-color: var(--el-text-color-secondary);
    color: var(--el-text-color-primary);

    @include hover {
      color: var(--el-bg-color-page);
      background-color: var(--el-text-color-primary);
    }

    &:active {
      color: var(--el-bg-color-page);
      background-color: var(--el-text-color-primary);
    }
  }

  &__stickers {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
  }

  &__sticker {
    display: flex;
    align-items: center;
    gap: 1rem;

    &-image {
      width: 10rem;
      height: 10rem;
      object-fit: contain;

      @include mobile {
        width: 8rem;
        height: 8rem;
      }
    }

    &-name {
      font-weight: 700;
    }
  }

  &__label {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 700;
  }
}
.el-button--primary {
  --el-button-bg-color: #fa490a;
  --el-button-border-color: #fa490a;
}
</style>