<script setup>
import { useI18n } from "vue-i18n";
import UiAnimatedBackground from "@/components/ui/ui-animated-background.vue";
import FormsJob from "@/components/forms/forms-job.vue";

const { t } = useI18n();
</script>

<template>
  <div>
    <ui-animated-background />
    <div class="container">
      <section class="job">
        <h1 class="job__title" v-text="t('job.title')" />
        <p class="job__subtitle" v-text="t('job.subtitle')" />
        <div class="job__content">
          <div class="job__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#2B2F30"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.4.9 8.5 3.8l-.1.1-7.6 7.6 1.4 1.4L12.8 2.3zm-1.3.1C9 .3 7.9 0 6.6 0 4.9 0 3.2.7 1.9 1.9.7 3.2 0 4.9 0 6.6 0 7.9.3 9 1 10.1l1.4-1.4C2.1 8 2 7.3 2 6.6c0-1.2.5-2.4 1.4-3.3.8-.9 2-1.3 3.2-1.3.7 0 1.4.2 2 .5l.1-.1L10.1 1zM8.7 2.3l-.1.1zm3.3 8.3c1.5-2 1.7-4.6.6-6.8l-1.5 1.5c.1.4.2.9.2 1.3 0 1.2-.5 2.4-1.4 3.3-.9.9-2.1 1.4-3.3 1.4-.5 0-.9-.1-1.3-.2l-1.5 1.5c.9.4 1.8.6 2.8.6 1.4 0 2.8-.5 3.9-1.3l4 4 1.4-1.4-3.9-3.9z"
              />
            </svg>
          </div>
          <p class="job__text" v-text="t('job.text')" />
          <p class="job__text" v-text="t('job.description')" />
          <forms-job />
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
.job {
  width: 100%;
  min-height: 50vh;
  //background-color: $dark_block;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @include mobile {
    padding: 4rem 0;
  }

  &__title {
    font-size: 5rem;
    font-weight: 700;
    text-align: center;

    @include mobile {
      font-size: 2.8rem;
    }
  }

  &__subtitle {
    color: var(--el-text-color-secondary);
    text-align: center;
  }

  &__content {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    max-width: 80rem;

    @include mobile {
      margin-top: 3rem;
    }
  }

  &__icon {
    width: 10rem;
    height: 10rem;
    background-color: var(--el-text-color-primary);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;

    & > svg {
      width: 50%;
      height: 50%;
    }
  }

  &__text {
    font-size: 2rem;
    text-align: center;
    font-weight: 600;

    @include mobile {
      font-size: 1.6rem;
    }
  }
}

:deep(.el-input--large) {
  --el-input-inner-height: 48px;
}
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #717777 inset;
}
:deep(.el-textarea__inner) {
  box-shadow: 0 0 0 1px #717777 inset;
}
</style>