<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  float: String,
  showFloat: Boolean,
});

const computedFloatNumber = computed(() =>
  Number(Number(props.float).toFixed(3))
);
const floatNumber = ref(computedFloatNumber.value);

const computedFloatPercent = computed(() => {
  const floatString = String(computedFloatNumber.value).split("0.")[1];
  if (floatString) {
    return Number(floatString.slice(0, 2) + "." + floatString.slice(2));
  }

  const longFloatString = String(Number(props.float).toFixed(5)).split("0.")[1];
  floatNumber.value = Number(Number(props.float).toFixed(4));

  return Number(
    longFloatString.slice(0, 4) + "." + longFloatString.slice(3, 5),
  );
});
</script>

<template>
  <div class="ui-float">
    <span v-if="showFloat" class="ui-float__count" v-text="floatNumber" />
    <div class="ui-float__inner">
      <div class="ui-float__percentage" />
      <div class="ui-float__percentage" />
      <div class="ui-float__percentage" />
      <div class="ui-float__percentage" />
      <div class="ui-float__percentage" />
      <div
        class="ui-float__index"
        :style="{ left: `${computedFloatPercent}%` }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-caret-up-fill"
          viewBox="0 0 16 16"
        >
          <path
            d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.ui-float {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @include mobile {
    gap: 1rem;
  }

  &__count {
    font-size: 1.4rem;

    @include mobile {
      font-size: 1rem;
    }
  }

  &__inner {
    width: 100%;
    height: 1rem;
    display: grid;
    grid-template-columns: 7% 8% 22% 8% 55%;
    position: relative;

    @include mobile-tablet {
      height: 0.8rem;
    }
  }

  &__index {
    width: 1.2rem;
    height: 1.2rem;
    background-color: transparent;
    position: absolute;
    top: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-0.7rem);

    @include mobile-tablet {
      width: 0.9rem;
      height: 0.9rem;
      transform: translateX(-0.45rem);
    }

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  &__percentage {
    width: 100%;
    height: 100%;

    &:nth-child(1) {
      background-color: #5eb648;
      border-radius: 0.3rem 0 0 0.3rem;

      @include mobile-tablet {
        border-radius: 0.2rem 0 0 0.2rem;
      }
    }
    &:nth-child(2) {
      background-color: #79d154;
    }
    &:nth-child(3) {
      background-color: #e3e15b;
    }
    &:nth-child(4) {
      background-color: #e9a75d;
    }
    &:nth-child(5) {
      background-color: #e05a59;
      border-radius: 0 0.3rem 0.3rem 0;

      @include mobile-tablet {
        border-radius: 0 0.2rem 0.2rem 0;
      }
    }
  }
}
</style>