<script setup>
import { ref } from 'vue'
import en from '@/locales/en-GB.json'
import { useI18n } from 'vue-i18n'
import translations from "@/core/helpers/translations.js";

const { t } = useI18n()

const tags = ref(en.footer.tags.content)
</script>

<template>
  <div class="footer-top">
    <div class="footer-top__tags">
      <h3 class="footer-top__title" v-text="t('footer.tags.title')" />
      <div class="footer-top__tags-content">
        <router-link
            v-for="(link, idx) of tags"
            :key="idx"
            class="footer-top__tag"
            :to="translations.i18nRoute({ name: 'market', query: { tag: link } })"
        >
          {{ t(`footer.tags.content[${idx}]`) }}
        </router-link>
      </div>
    </div>
    <div class="footer-top__payments">
      <h3 class="footer-top__title" v-text="t('footer.payment')" />
      <div class="footer-top__payments-content2">
        <img class="footer-top__payments-img" src="@images/mc.png" alt="image" />
        <img class="footer-top__payments-img" src="@images/visa.png" alt="image" />
        <img class="footer-top__payments-img" src="@images/mcId.png" alt="image" />
        <img class="footer-top__payments-img" src="@images/visa-secure.jpg" alt="image" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.footer-top {
  width: 100%;
  padding: 3rem 0;
  border-bottom: 0.2rem solid var(--el-text-color-secondary);
  display: grid;
  grid-column-gap: 5rem;
  grid-template-columns: repeat(2, 1fr);

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4rem;
  }

  &__tags,
  &__payments {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4rem;

    @include mobile {
      gap: 2rem;
    }

    &-content {
      width: 100%;
      display: flex;
      align-items: start;
      gap: 1rem;
      flex-wrap: wrap;
    }

    &-content2 {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 100px);
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
    }

    &-img {
      height: 50px;
      width: auto;

      &:nth-of-type(2) {
        height: 30px;
      }

      &:nth-of-type(3) {
        height: 50px;
      }
    }
  }

  &__title {
    color: var(--el-text-color-secondary);
    font-size: 1.5rem;
  }

  &__tag {
    padding: 1rem;
    border-radius: 0.3rem;
    background-color: var(--el-bg-color-overlay);
    color: var(--el-text-color-primary);
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: 'Montserrat Medium', sans-serif;

    @include hover {
      background-color: var(--el-text-color-primary);
      color: var(--el-bg-color-page);
    }

    &:active {
      background-color: var(--el-text-color-primary);
      color:var(--el-bg-color-page);
    }
  }

  &__icon {
    max-width: 5rem;
    width: 100%;
    height: auto;
  }
}
</style>