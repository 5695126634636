<script setup>
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {useAuthStore} from "@/stores/auth.js";
import translations from "@/core/helpers/translations.js";

const { t } = useI18n();
const authStore = useAuthStore();

const user = computed(() => authStore.user);
</script>

<template>
  <section class="steam">
    <div class="steam__head">
      <h4 class="steam__title" v-text="t('account.steam.title')" />
    </div>
    <div class="steam__inner" v-if="user.trade_url">
      <span class="steam__text" v-text="user.trade_url" />
      <router-link class="steam__refresh" :to="translations.i18nRoute({ name: 'trade-url' })">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 37.5 38.5">
          <path d="M35.5 12.5H24.7c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8L32 9V1.8c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8v10.7z"/>
          <path d="M18.8 38.5C8.4 38.5 0 30.1 0 19.8S8.4 1 18.8 1C24 1 29.1 3.3 32.7 7.2c.7.8 1.3 1.6 1.9 2.4.5.8.3 1.9-.5 2.4s-1.9.3-2.4-.5c-.4-.7-1-1.4-1.5-2-2.9-3.2-7-5-11.3-5-8.4 0-15.3 6.8-15.3 15.3 0 8.4 6.8 15.3 15.3 15.3C27.2 35 34 28.2 34 19.8c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8c-.1 10.3-8.5 18.7-18.8 18.7z"/>
        </svg>
      </router-link>
    </div>
    <div class="steam__inner" v-else>
      <span class="steam__text" v-text="t('account.steam.text')" />
      <router-link class="steam__link" :to="translations.i18nRoute({ name: 'trade-url' })">
        {{t("account.steam.add") }}
      </router-link>
    </div>
  </section>
</template>

<style scoped lang="scss">
.steam {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  position: relative;
  z-index: 5;
  background-color: var(--el-bg-color);

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__title {
    font-size: 2rem;
  }

  &__inner {
    width: 100%;
    padding: 4rem 4rem 3rem;
    border: 2px solid var(--el-bg-darker);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mobile {
      padding: 2rem;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    display: inline-block;
  }

  &__refresh {
    margin-left: auto;
    position: relative;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--el-text-color-primary);

    &::after {
      width: 100%;
      height: 100%;
      background: none;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      transform: scale(0);
      transition-duration: 0.25s;
      transition-property: transform, opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      content: "";
      z-index: -1;
    }

    @include hover {
      color: var(--el-color-primary);

      &::after {
        background: rgba(29, 32, 33, 45%);
        transform: scale(2);
        opacity: 1;
      }
    }
  }

  &__link {
    text-transform: uppercase;
    color: var(--el-text-color-primary);
    letter-spacing: 0.1rem;

    @include hover {
      color: var(--el-color-primary-light-5);
    }

    &:active {
      color: var(--el-color-primary-dark-2);
    }
  }
}
</style>