import {apiTemplate} from "@/core/helpers/api.js";

export class Orders {
  static async getOrders(status, accessToken, userEmail) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "X-EVIBES-AUTH": `Bearer ${accessToken}`,
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `query {
        orders (status: "${status}", orderBy: "-buyTime", userEmail: "${userEmail}") {
          edges {
            node {
              totalPrice
              uuid
              status
              buyTime
              totalPrice
              orderProducts {
                edges {
                  node {
                    uuid
                    notifications
                    attributes
                    quantity
                    status
                    product {
                      uuid
                      price
                      name
                      description
                      images {
                        edges {
                          node {
                            uuid
                            image
                          }
                        }
                      }
                      category {
                        name
                      }
                      attributeGroups {
                        edges {
                          node {
                            name
                            uuid
                            attributes {
                              name
                              uuid
                              values {
                                value
                                uuid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    );

    return await response;
  }

  static async overwriteCart(action, accessToken, orderUuid, productUuid) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "X-EVIBES-AUTH": `Bearer ${accessToken}`,
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        ${action}(orderUuid: "${orderUuid}", productUuid: "${productUuid}") {
          order {
            status
            uuid
            totalPrice
            orderProducts {
              edges {
                node {
                  uuid
                  notifications
                  attributes
                  quantity
                  status
                  product {
                    uuid
                    price
                    name
                    description
                    images {
                      edges {
                        node {
                          uuid
                          image
                        }
                      }
                    }
                    category {
                      name
                    }
                    attributeGroups {
                      edges {
                        node {
                          name
                          uuid
                          attributes {
                            name
                            uuid
                            values {
                              value
                              uuid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    );

    return await response;
  }

  static async deleteOrder(accessToken, orderUuid) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "X-EVIBES-AUTH": `Bearer ${accessToken}`,
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        removeAllOrderProducts (orderUuid: "${orderUuid}") {
          order {
            status
            uuid
            totalPrice
            orderProducts {
              edges {
                node {
                  uuid
                  notifications
                  attributes
                  quantity
                  status
                  product {
                    uuid
                    price
                    name
                    description
                    images {
                      edges {
                        node {
                          uuid
                          image
                        }
                      }
                    }
                    category {
                      name
                    }
                    attributeGroups {
                      edges {
                        node {
                          name
                          uuid
                          attributes {
                            name
                            uuid
                            values {
                              value
                              uuid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    );

    return await response;
  }

  static async buyOrder(accessToken, orderUuid, forcePayment, forceBalance) {
    const userLocale = localStorage.getItem('user-locale');

    const response = await apiTemplate(
      {
        "Content-Type": "application/json",
        "X-EVIBES-AUTH": `Bearer ${accessToken}`,
        "Accept-language": userLocale ? userLocale : 'en-GB'
      },
      `mutation {
        buyOrder(orderUuid: "${orderUuid}", forcePayment: ${forcePayment ? 'true' : 'false'}, forceBalance: ${forceBalance ? 'true' : 'false'}) {
          order {
            totalPrice
            uuid
            status
            orderProducts {
              edges {
                node {
                  uuid
                  quantity
                  status
                  product {
                    uuid
                    price
                    name
                    description
                    quantity
                    category {
                      name
                    }
                    images {
                      edges {
                        node {
                          uuid
                          image
                        }
                      }
                    }
                    attributeGroups {
                      edges {
                        node {
                          name
                          uuid
                          attributes {
                            name
                            uuid
                            values {
                              value
                              uuid
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          transaction {
            amount
            process
            paymentMethod
            order {
              totalPrice
              uuid
              status
              orderProducts {
                edges {
                  node {
                    uuid
                    quantity
                    status
                    product {
                      uuid
                      price
                      name
                      description
                      quantity
                      category {
                        name
                      }
                      images {
                        edges {
                          node {
                            uuid
                            image
                          }
                        }
                      }
                      attributeGroups {
                        edges {
                          node {
                            name
                            uuid
                            attributes {
                              name
                              uuid
                              values {
                                value
                                uuid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`
    );

    return await response;
  }
}