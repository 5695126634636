import {apiUrl} from "@/core/api/index.js";

export const apiTemplate = async (headers, body) => {
  const response = await fetch(`${apiUrl}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      query: body
    })
  })

  return await response.json()
}
