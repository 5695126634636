<script setup>
import {useI18n} from "vue-i18n";
import {useAuthStore} from "@/stores/auth.js";
import {computed} from "vue";
import {useAppStore} from "@/stores/app.js";
import translations from "@/core/helpers/translations.js";
import {Orders} from "@/core/api/orders.js";
import {ElNotification} from "element-plus";
import {useCartStore} from "@/stores/cart.js";
import UiFloatViewer from "@/components/ui/ui-float-viewer.vue";

const props = defineProps(  {
  product: Object
});

const { t } = useI18n();
const authStore = useAuthStore();
const appStore = useAppStore();
const cartStore = useCartStore()

const accessToken = computed(() => authStore.accessToken);
const orderUuid = computed(() => {
  return cartStore.currentOrder.uuid ? cartStore.currentOrder.uuid : null
})

const computedProductName = computed(() => {
  return props.product.product.name.split("| ")[1]
    ? props.product.product.name.split("| ")[1].split(" (")[0]
    : props.product.product.name;
});

const removeProduct = async () => {
  const res = await Orders.overwriteCart("removeOrderProduct", accessToken.value, orderUuid.value, props.product.product.uuid);
  cartStore.setCurrentOrders(res.data.removeOrderProduct.order)

  if (!res.errors) {
    cartStore.setCurrentOrders(res.data.removeOrderProduct.order)

    ElNotification({
      title: t("notifications.success.removeProduct.title"),
      message: t("notifications.success.removeProduct.text", {
        name: props.product.name,
      }),
      duration: 5000,
      type: "success",
      position: "bottom-left",
    });
  } else {
    ElNotification({
      message: res.errors[0].message,
      type: "error",
      duration: 5000,
      position: "bottom-left",
    });
  }
};

const closeCartWidget = () => {
  appStore.isHeaderCartVisible = false;
};

const productFloat = computed(() => {
  const steamInfoGroup = props.product.product.attributeGroups.edges.find(
    (group) => group.node.name === "Skin Info"
  );
  if (!steamInfoGroup) return null;

  const floatAttribute = steamInfoGroup.node.attributes.find(
    (attr) => attr.name === "float"
  );
  if (!floatAttribute || !floatAttribute.values.length) return null;

  return floatAttribute.values[0].value;
});

const productStickers = computed(() => {
  const skinInfoGroup = props.product.product.attributeGroups.edges.find(
    (group) => group.node.name === "Skin Info"
  );
  if (!skinInfoGroup) return null;

  const stickerAttribute = skinInfoGroup.node.attributes.find(
    (attr) => attr.name === "stickers"
  );

  if (!stickerAttribute || !stickerAttribute.values.length) return null;

  try {
    return stickerAttribute?.values.map((sticker) => {
      const { uuid, ...rest } = JSON.parse(sticker.value);
      return rest;
    })[0];
  } catch (error) {
    console.error("Failed to parse stickers JSON:", error);
    return null;
  }
});
</script>

<template>
  <div class="product-card">
    <div class="product-card__image">
      <img class="image" :src="product.product?.images.edges[0].node.image" :alt="product.product.name" />
      <div class="product-card__stickers" v-if="productStickers">
        <el-tooltip
          v-for="sticker of productStickers"
          :key="sticker.uuid"
          :content="sticker.name"
          placement="top"
          effect="light"
        >
          <img class="product-card__sticker" :src="sticker.img" :alt="sticker.name" />
        </el-tooltip>
      </div>
    </div>
    <div class="product-card__inner">
      <div class="product-card__info">
        <div class="product-card__state">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 18">
            <path d="M15.429 4.498v9L7.797 18 0 13.502l.003-9L7.803 0l7.626 4.498Zm-1.562.98L7.802 1.903 1.542 5.48v7.007l6.256 3.61 6.066-3.613.003-7.007Zm-5.638.18v3.6h2.057V10.8h-3.6V5.657h1.543Z"
                fill-rule="nonzero"/>
          </svg>
          <span v-text="t('product.state')" />
        </div>
<!--        <span class="product-card__weapon" :style="{ color: product.product.color }">-->
<!--          {{ product.weapon }}-->
<!--        </span>-->
        <div class="product-card__name">
          {{ computedProductName }}
        </div>
<!--        <span class="product-card__quality">-->
<!--          {{-->
<!--            `${product.weapon ? product.weapon : ""} ${product.exterior ? product.exterior : ""} ${product.rarity ? product.rarity : ""} ${product.quality ? product.quality : ""}`-->
<!--          }}-->
<!--        </span>-->
        <div class="product-card__float" v-if="productFloat">
          <ui-float-viewer :float="productFloat" show-float />
        </div>
      </div>
      <div class="product-card__tools">
        <div class="product-card__price">
          <svg class="coin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0" fill="gold"/>
            <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" fill="gold"/>
            <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" fill="green"/>
            <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" fill="green"/>
          </svg>
          <span v-text="product.product.price" />
        </div>
        <button class="product-card__delete" @click="removeProduct">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 14">
            <g fill-rule="nonzero">
              <path d="M0 2.987V3h.987l1.578 10.378c.03.352.326.622.68.622H11c.354 0 .649-.27.68-.623L12.986 3h1A.987.987 0 0 0 13 2H1a1 1 0 0 0-1 .987ZM10.513 13H3.578L2.565 3H11.5l-.987 10Z"/>
              <path d="M9 0H5a1 1 0 0 0-1 1v1h1V1h4v1h1V1a1 1 0 0 0-1-1Z"/>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <router-link
      @click="closeCartWidget"
      class="product-card__link"
      :to="translations.i18nRoute({ name: 'product', params: { uuid: props.product.uuid } })"
    />
  </div>
</template>

<style scoped lang="scss">
.product-card {
  width: 100%;
  padding: 2rem;
  position: relative;
  display: flex;
  align-items: stretch;
  gap: 2rem;
  background-color: transparent;
  transition: all 0.3s ease;

  @include hover {
    background-color: var(--el-bg-light);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 2rem;
    width: calc(100% - 4rem);
    height: 1px;
    background-color: var(--el-text-color-placeholder);
  }

  &__image {
    min-width: 128px;
    width: 128px;
    height: auto;
    align-self: center;

    @include mobile {
      min-width: 96px;
      width: 96px;
    }

    & > .image {
      width: 100%;
      height: auto;
    }
  }

  &__stickers {
    position: absolute;
    z-index: 3;
    bottom: 2rem;
    left: 2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__sticker {
    width: 30px;
    height: auto;
    transition: all 0.3s ease;

    @include hover {
      scale: 1.3;
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    align-items: stretch;
    gap: 2rem;
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }

  &__state {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 700;
    font-size: 1.4rem;
    color: var(--el-color-success-light-7);

    @include mobile {
      font-size: 1.2rem;
    }
  }

  &__weapon {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__name {
    font-size: 1.6rem;
    font-weight: 700;

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__quality {
    color: var(--el-text-color-secondary);
    font-size: 1.4rem;

    @include mobile {
      font-size: 1.2rem;
    }
  }

  &__float {
    width: 100%;
    margin-top: auto;
  }

  &__tools {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    width: max-content;

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__delete {
    position: relative;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--el-text-color-primary);
    z-index: 4;

    &::after {
      width: 100%;
      height: 100%;
      background: none;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      transform: scale(0);
      transition-duration: 0.25s;
      transition-property: transform, opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      content: "";
      z-index: -1;
    }

    @include hover {
      color: var(--el-color-primary);

      &::after {
        background: rgba(29, 32, 33, 45%);
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
  }
}

svg {
  width: 20px;
}
</style>