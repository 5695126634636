<script setup>
import { useI18n } from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import FormsSignIn from "@/components/forms/forms-sign-in.vue";

const { t } = useI18n();
</script>

<template>
  <div class="sign-in">
    <div class="sign-in__nav">
      <router-link
        class="sign-in__link"
        active-class="sign-in__link--active"
        :to="translations.i18nRoute({ name: 'sign-in' })"
      >
        {{ t("header.login") }}
      </router-link>
      <router-link
        class="sign-in__link"
        active-class="sign-in__link--active"
        :to="translations.i18nRoute({ name: 'sign-up' })"
      >
        {{ t("header.register") }}
      </router-link>
    </div>
    <div class="sign-in__head">
      <h1 class="sign-in__title" v-text="t('login.title')" />
    </div>
    <div class="sign-in__form">
      <forms-sign-in />
    </div>
  </div>
</template>

<style scoped lang="scss">
.sign-in {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4rem;

  @include mobile {
    padding: 0 2rem;
  }

  &__nav {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-bottom: 6rem;
  }

  &__link {
    padding: 1rem 0;
    font-weight: 700;
    color: var(--el-text-color-primary);
    text-transform: uppercase;

    @include hover {
      color: var(--el-color-primary);
    }

    &:active {
      color: var(--el-color-primary);
    }

    &--active {
      position: relative;
      color: var(--el-color-primary);

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.3rem;
        background-color: var(--el-color-primary);
      }
    }
  }

  &__head {
    margin-bottom: 3rem;
  }

  &__title {
    font-size: 2.5rem;
    font-weight: 700;

    @include mobile {
      font-size: 1.8rem;
      text-align: center;
    }
  }

  &__form {
    width: 100%;
    max-width: 45rem;
  }
}
</style>