<script setup>
import slider from '@vueform/slider'
import {computed, onMounted, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import MarketFiltersSkeleton from "@/components/skeletons/market/market-filters-skeleton.vue";
import {Categories} from "@/core/api/categories.js";
import {debounce} from "@/core/helpers/debounce.js";
import {useRoute} from "vue-router";
import UiCheckbox from "@/components/ui/ui-checkbox.vue";

const {t} = useI18n()
const route = useRoute()

const emit = defineEmits(["filterPrice", "filterAttributes"]);

const activeCategory = computed(() => route.params.category || "");
const filterableAttributes = ref([]);
const maxPrice = ref([]);

const isLoading = ref(true);
const collapse = ref({});
const price = ref([0, 0]);

const resetPrices = () => {
  price.value = [0, maxPrice.value]
}

const checkPriceRange = () => {
  if (price[0] < 0) price[0] = 0;
  if (price[0] > maxPrice.value) price[0] = maxPrice.value;
  if (price[1] < 0) price[1] = 0;
  if (price[1] > maxPrice.value) price[1] = maxPrice.value;
  if (price[0] > price[1]) [price[0], price[1]] = [price[1], price[0]];
};

const selectedFilters = reactive({});

const isCheckboxChecked = (attributeName, value) => {
  if (!selectedFilters[attributeName]) {
    return false;
  }
  return selectedFilters[attributeName].includes(value);
};

const attributes = computed(() => {
  return route.query['attributes'] ? route.query['attributes'] : '';
});

const getCategory = async () => {
  const res = await Categories.getCategoryByName(activeCategory.value)
  filterableAttributes.value = res.data.categories.edges[0].node.filterableAttributes
      .filter((attribute) =>
          attribute.attributeName !== 'color' &&
          attribute.attributeName !== 'styles' &&
          attribute.attributeName !== 'gems' &&
          attribute.attributeName !== 'assetid' &&
          attribute.attributeName !== 'steamid' &&
          attribute.attributeName !== 'link'
      )

  maxPrice.value = res.data.categories.edges[0].node.minMaxPrices.maxPrice

  filterableAttributes.value.forEach((attribute) => {
    if (attribute.attributeName === 'hero') {
      attribute.possibleValues.sort((a, b) => a.localeCompare(b))
    }

    if (!(attribute.attributeName in selectedFilters)) {
      selectedFilters[attribute.attributeName] = [];
    }
  });

  isLoading.value = false;
}

const updateSelectedFilters = (attributeName, value, isChecked) => {
  if (isChecked) {
    if (!selectedFilters[attributeName].includes(value)) {
      selectedFilters[attributeName].push(value);
    }
  } else {
    selectedFilters[attributeName] = selectedFilters[attributeName].filter(item => item !== value);
    selectedFilters[attributeName] = [...selectedFilters[attributeName]];
  }
};

const parseAttributesFromUrl = (attributesString) => {
  if (!attributesString) return {};

  const result = {};
  const attributesArray = attributesString.split(';');

  for (const attribute of attributesArray) {
    const [key, operatorValues] = attribute.split('=');
    if (!operatorValues) continue;

    if (operatorValues.startsWith('icontains-')) {
      const value = operatorValues.replace('icontains-', '');
      result[key] = [value];
    }
    else if (operatorValues.startsWith('in-')) {
      const match = operatorValues.match(/in-\[(.*)]/);
      if (match && match[1]) {
        const values = match[1]
          .replace(/\\?\"/g, '')
          .split(', ');
        result[key] = values;
      }
    }
  }

  return result;
};

function formatString(input) {
  return input
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

watch(price, debounce((newPrice) => emit("filterPrice", newPrice), 400), { deep: true });
watch(
  () => selectedFilters,
  (newSelected) => {
    emit('filterAttributes', Object.fromEntries(
        Object.entries(newSelected).map(([key, values]) => {
          return [key, [...values]];
        })
      ));
  },
  { deep: true }
);

onMounted(async () => {
  await getCategory();
  price.value = [0, maxPrice.value]

  if (attributes.value) {
    const parsedAttributes = parseAttributesFromUrl(attributes.value);

    emit('filterAttributes', parsedAttributes);

    for (const [key, values] of Object.entries(parsedAttributes)) {
      if (key in selectedFilters) {
        selectedFilters[key] = values;
      }
    }
  }
});
</script>

<template>
  <div class="filters">
    <el-collapse v-if="!isLoading" v-model="collapse">
      <el-collapse-item
          name="0"
      >
        <template #title>
          <div class="filters__head">
            <h3 class="filters__name" v-text="t('market.filter.price')" />
<!--            <button-->
<!--                v-if="price[0] !== 0.1 || price[1] !== 30000"-->
<!--                class="filters__head-button"-->
<!--                @click="resetPrices"-->
<!--                v-text="t('market.filter.reset')"-->
<!--            />-->
          </div>
        </template>
<!--        <client-only>-->
          <div class="filters__price">
            <div class="filters__price-range">
              <slider v-model="price" :max="maxPrice" :step="-1" />
            </div>
            <div class="filters__price-fields">
              <el-input v-model="price[0]" type="number" />
              <el-input v-model="price[1]" type="number" />
            </div>
          </div>
<!--        </client-only>-->
      </el-collapse-item>
      <el-collapse-item
          v-for="(attribute, idx) in filterableAttributes"
          :key="idx"
          :name="`${idx + 2}`"
      >
        <template #title>
          <div class="filters__head">
            <h3 class="filters__name" v-text="attribute.attributeName.replace(/_/g, ' ')" />
<!--            <button-->
<!--                class="filters__head-button"-->

<!--                v-text="t('market.filter.reset')"-->
<!--            />-->
          </div>
        </template>
        <ul class="filters__list">
          <li
              v-for="(value, idx) of attribute.possibleValues"
              :key="idx"
              class="filters__item"
          >
            <ui-checkbox
                :id="attribute.attributeName + idx"
                :modelValue="isCheckboxChecked(attribute.attributeName, value)"
                @update:modelValue="(isChecked) => updateSelectedFilters(attribute.attributeName, value, isChecked)"
            >
              {{ value }}
            </ui-checkbox>
<!--            <button-->
<!--                class="filters__button"-->
<!--                @click="selectOnly(idx, filterIdx)"-->
<!--                v-text="t('market.filter.only')"-->
<!--            />-->
          </li>
        </ul>
      </el-collapse-item>
    </el-collapse>
    <market-filters-skeleton v-else />
  </div>
</template>

<style scoped lang="scss">
.filters {
  width: 100%;

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2rem;
    text-transform: capitalize;
    font-size: 14px;

    &-button {
      text-transform: uppercase;
      color: var(--el-text-color-secondary);
      font-size: 1.4rem;

      @include hover {
        color: var(--el-color-primary);
      }

      &:active {
        color: var(--el-color-primary);
      }
    }
  }

  &__price {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    padding: 0 3.2rem;
    position: relative;
    z-index: 1000;

    &-range {
      width: 100%;
      padding-top: 5rem;
      display: flex;
      align-items: center;
    }

    &-fields {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  &__list {
    width: 100%;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.8rem 2rem;

    @include hover {
      background-color: var(--el-bg-darker);
      .filters__button {
        opacity: 1;
      }
    }
  }

  &__button {
    text-transform: uppercase;
    color: var(--el-text-color-secondary);
    opacity: 0;
    font-size: 1.4rem;

    @include hover {
      color: var(--el-color-primary);
    }

    &:active {
      color: var(--el-color-primary);
    }
  }
}

:deep(.el-input--large) {
  --el-input-inner-height: 48px;
}
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #717777 inset;
}
:deep(.el-checkbox) {
  height: unset;
}
</style>