import { useClipboard } from "@vueuse/core";
import { ref } from "vue";

export const useCopy = async (value) => {
  const source = ref(value);
  const { text, copy, isSupported } = useClipboard({ source });
  if (!isSupported) return false;

  await copy(source.value);

  return !!text;
};
