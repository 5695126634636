<script setup>
import {useAuthStore} from "@/stores/auth.js";
import {useI18n} from "vue-i18n";
import {computed, reactive, ref} from "vue";
import translations from "@/core/helpers/translations.js";
import {apiTemplate} from "@/core/helpers/api.js";

const authStore = useAuthStore();
const { t } = useI18n();

const user = computed(() => authStore.user);
const accessToken = computed(() => authStore.accessToken);

const form = ref();
const formModel = reactive({
  amount: 5,
});
const rules = reactive({
  amount: [
    {
      required: true,
      message: t("fieldErrors.required", { field: "Amount" }),
      trigger: "change",
    },
    {
      message: t("balance.min"),
      validator: (rule, val) => val >= 5,
      trigger: "change",
    },
    {
      message: t("balance.max"),
      validator: (rule, val) => val <= 1000,
      trigger: "change",
    },
  ],
});

const userLocale = localStorage.getItem('user-locale');

const tupUp = async () => {
  const res = await apiTemplate(
    {
      "Content-Type": "application/json",
      "X-EVIBES-AUTH": `Bearer ${accessToken.value}`,
      "Accept-language": userLocale ? userLocale : 'en-GB'
    },
    `mutation {
      deposit(amount: ${formModel.amount}) {
        transaction {
          process
        }
      }
    }`
  )

  if (res.data.deposit.transaction.process) {
    window.location.href = res.data.deposit.transaction.process.url
  }
};

const isAgree = ref(false);
</script>

<template>
  <div class="container">
    <div class="balance">
      <div class="balance__inner">
        <div class="balance__head">
          <h1 class="balance__title" v-text="t('balance.title')" />
          <div class="balance__amount">
            <span v-text="t('balance.amount')" />
            <svg class="coin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0" fill="gold"/>
              <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" fill="gold"/>
              <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" fill="green"/>
              <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" fill="green"/>
            </svg>
            <span class="balance__amount-value" v-text="user.balance.amount" />
          </div>
        </div>
      </div>
      <el-form
        ref="form"
        class="balance__checkout"
        require-asterisk-position="right"
        label-position="top"
        :model="formModel"
        :rules="rules"
      >
        <el-form-item :label="t('balance.get') + ' Skin Coin'" prop="amount">
          <el-input v-model.number="formModel.amount" type="number" />
        </el-form-item>
        <el-form-item :label="t('balance.spend') + ' EUR'">
          <el-input v-model.number="formModel.amount" type="number" />
        </el-form-item>
        <div class="balance__warn">
          <h4 class="balance__warn-title" v-text="t('balance.warn')" />
          <p class="balance__warn-text" v-text="t('balance.min')" />
          <p class="balance__warn-text" v-text="t('balance.max')" />
        </div>
        <el-checkbox v-model="isAgree">
          <div class="balance__cb">
            <i18n-t
              class="balance__tools-text"
              tag="p"
              scope="global"
              keypath="cart.checkout.agree"
            >
              <template #button>
                <span class="balance__tools-text--upper">
                  {{ t("balance.title") }}
                </span>
              </template>
              <template #terms>
                <router-link
                  class="balance__tools-link"
                  :to="translations.i18nRoute({ name: 'terms-of-use' })"
                >
                  {{ t("terms.title") }}
                </router-link>
              </template>
              <template #privacy>
                <router-link
                  class="balance__tools-link"
                  :to="translations.i18nRoute({ name: 'privacy-policy' })"
                >
                  {{ t("privacy.title") }}
                </router-link>
              </template>
            </i18n-t>
          </div>
        </el-checkbox>
        <div
          v-if="isAgree && formModel.amount >= 5 && formModel.amount <= 1000"
          @click="tupUp"
          class="balance__button el-button el-button--info el-button--large"
        >
          {{ t("balance.title") }}
        </div>
        <el-button
          v-else
          class="balance__button"
          type="info"
          size="large"
          disabled
        >
          {{ t("balance.title") }}
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.balance {
  width: 100%;
  background-color: var(--el-bg-color);
  padding: 4rem;
  display: flex;
  align-items: stretch;
  gap: 4rem;

  @include mobile-tablet {
    flex-direction: column;
    align-items: start;
    padding: 4rem 2rem;
  }

  &__inner {
    width: 100%;
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__amount {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--el-text-color-secondary);

    &-value {
      color: var(--el-text-color-primary);
      font-weight: 600;
      font-size: 2rem;
    }

    & svg {
      width: 20px;
    }
  }

  &__checkout {
    padding: 3rem;
    max-width: 400px;
    width: 100%;
    background-color: var(--el-bg-darker);

    @include mobile-tablet {
      padding: 2rem;
    }
  }

  &__warn {
    background-color: var(--el-text-color-primary);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    padding: 3rem;
    margin-bottom: 4rem;

    @include mobile-tablet {
      padding: 2rem;
    }

    &-title {
      color: var(--el-color-primary);
      font-weight: 700;
      font-size: 1.8rem;
    }

    &-text {
      color: var(--el-bg-color-page);
      font-weight: 500;
      line-height: 130%;
    }
  }

  &__button {
    width: 100%;
    text-transform: uppercase;
    margin-top: 5rem;
  }

  &__cb {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    line-height: 130%;
  }

  &__tools {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;

    &-text {
      color: var(--el-text-color-secondary);
      line-height: 130%;

      &--upper {
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    &-link {
      font-weight: 600;
      color: var(--el-color-info);

      @include hover {
        color: var(--el-color-info-light-5);
      }

      &:active {
        color: var(--el-color-info-dark-2);
      }
    }
  }
}

:deep(.el-input--large) {
  --el-input-inner-height: 48px;
}
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #717777 inset;
}
</style>
