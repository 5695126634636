<script setup>
</script>

<template>
  <el-skeleton class="sk-product-info" animated>
    <template #template>
      <div class="sk-product-info__head">
        <el-skeleton-item class="sk-product-info__head-weapon" variant="text" />
        <el-skeleton-item class="sk-product-info__head-name" variant="text" />
        <el-skeleton-item class="sk-product-info__head-info" variant="text" />
      </div>
      <div class="sk-product-info__price">
        <el-skeleton-item variant="text" class="sk-product-info__price-value" />
        <el-skeleton-item variant="text" class="sk-product-info__price-suggest" />
      </div>
      <div class="sk-product-info__tools">
        <el-skeleton-item variant="button" class="sk-product-info__tools-button" />
        <div class="sk-product-info__tools-buttons">
          <el-skeleton-item variant="button" class="sk-product-info__tools-buttons-item" />
          <el-skeleton-item variant="button" class="sk-product-info__tools-buttons-item" />
          <el-skeleton-item variant="button" class="sk-product-info__tools-buttons-item" />
        </div>
      </div>
      <div class="sk-product-info__rating">
        <el-skeleton-item class="sk-product-info__label" variant="text" />
        <div class="sk-product-info__rating-inner">
          <el-skeleton-item class="sk-product-info__rating-value" variant="text" />
          <el-skeleton-item class="sk-product-info__rating-label" variant="text" />
        </div>
      </div>
      <div class="sk-product-info__stickers">
        <el-skeleton-item class="sk-product-info__label" variant="text" />
        <div class="sk-product-info__sticker">
          <el-skeleton-item variant="image" class="sk-product-info__sticker-image" />
          <el-skeleton-item variant="text" class="sk-product-info__sticker-label" />
        </div>
        <div class="sk-product-info__sticker">
          <el-skeleton-item variant="image" class="sk-product-info__sticker-image" />
          <el-skeleton-item variant="text" class="sk-product-info__sticker-label" />
        </div>
      </div>
      <div class="sk-product-info__tags">
        <el-skeleton-item variant="button" class="sk-product-info__tag" />
        <el-skeleton-item variant="button" class="sk-product-info__tag" />
        <el-skeleton-item variant="button" class="sk-product-info__tag" />
        <el-skeleton-item variant="button" class="sk-product-info__tag" />
        <el-skeleton-item variant="button" class="sk-product-info__tag" />
        <el-skeleton-item variant="button" class="sk-product-info__tag" />
      </div>
    </template>
  </el-skeleton>
</template>

<style scoped lang="scss">
.sk-product-info {
  width: 100%;
  background-color: var(--el-bg-color);
  padding: 5rem 3rem;

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    margin-bottom: 5rem;

    &-weapon {
      height: 1.6rem;
      border-radius: 0.2rem;
      width: 10%;
    }

    &-name {
      height: 3.2rem;
      border-radius: 0.2rem;
      width: 45%;
    }

    &-info {
      height: 1.5rem;
      border-radius: 0.2rem;
      width: 60%;
    }
  }

  &__price {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    margin-bottom: 5rem;

    &-value {
      height: 5rem;
      border-radius: 0.2rem;
      width: 45%;
    }

    &-suggest {
      height: 1.6rem;
      border-radius: 0.2rem;
      width: 35%;
    }
  }

  &__tools {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2rem;
    margin-bottom: 5rem;

    &-button {
      width: 100%;
      border-radius: 0.3rem;
      height: 6.5rem;
    }

    &-buttons {
      width: 100%;
      display: flex;
      align-items: start;
      gap: 0.5rem;

      &-item {
        width: 100%;
        border-radius: 0.3rem;
        height: 6rem;
      }
    }
  }

  &__rating {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 3rem;

    &-inner {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    &-value {
      height: 4rem;
      width: 45%;
      border-radius: 0.2rem;
    }

    &-label {
      width: 45%;
      height: 1.8rem;
      border-radius: 0.2rem;
    }
  }

  &__label {
    height: 3rem;
    width: 45%;
    border-radius: 0.2rem;
  }

  &__stickers {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 3rem;
    margin-bottom: 5rem;
  }

  &__sticker {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;

    &-image {
      width: 10rem;
      height: 10rem;
      border-radius: 0.2rem;
    }

    &-label {
      height: 2rem;
      width: 100%;
    }
  }

  &__tags {
    width: 100%;
    display: flex;
    align-items: start;
    gap: 1rem;
    flex-wrap: wrap;
  }

  &__tag {
    height: 4rem;
    border-radius: 0.2rem;

    &:nth-child(odd) {
      width: 30%;
    }

    &:nth-child(even) {
      width: 20%;
    }
  }
}
</style>