<script setup>
import { computed, reactive, ref } from "vue";
import {ElNotification} from "element-plus";
import { useI18n } from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {Auth} from "@/core/api/auth.js";
import UiPasswordHelper from "@/components/ui/ui-password-helper.vue";

const { t } = useI18n();
const router = useRouter()
const route = useRoute()

const id = computed(() =>
  route.query.uid ? (route.query.uid) : undefined,
);

const token = computed(() =>
  route.query.token ? (route.query.token) : undefined,
);

const isPending = ref(false);

const fieldFill = reactive({
  hasMin: false,
  hasLowerUpper: false,
  hasNumber: false,
  hasSpecial: false,
});
const isPasswordFocus = ref(false);
const isPasswordHelperVisible = computed(() => {
  return isPasswordFocus.value;
});
const recoverFormRef = ref();
const recoverForm = reactive({
  password: "",
  passwordConfirm: ""
});
const rules = reactive({
  password: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.password") }),
      trigger: "blur",
    },
    {
      message: t("fieldErrors.needLowerUpper"),
      validator(rule, value) {
        fieldFill.hasLowerUpper = !(
          !/[A-Z]/.test(value) || !/[a-z]/.test(value)
        );

        fieldFill.hasMin = value.length >= 8;

        fieldFill.hasNumber = /\d/.test(value);

        fieldFill.hasSpecial = /[#.?!@$%^&*'()_+=:;"'/>.<,|-]/.test(value);

        return true;
      },
      trigger: "change",
    }
  ],
  passwordConfirm: [
    {
      required: true,
      message: t("fieldErrors.required", { field: t("fields.password") }),
      trigger: "change",
    },
    {
      message: t("fieldErrors.compare", { field: t("fields.password") }),
      trigger: "change",
      validator() {
        return recoverForm.password === recoverForm.passwordConfirm;
      },
    }
  ]
});

const submitForm = async () => {
  const res = await Auth.confirmResetPassword(id.value, token.value, recoverForm.password, recoverForm.passwordConfirm)

  if (res.data.confirmResetPassword.success) {
    ElNotification({
      title: t("notifications.success.reset.title"),
      message: t("notifications.success.reset.text"),
      duration: 5000,
      type: "success",
      position: "bottom-left",
    });
  } else {
    ElNotification({
      message: res.errors[0].message,
      duration: 5000,
      type: "error",
      position: "bottom-left",
    });
  }
};

const isFormValid = computed(() => {
  return (
    recoverForm.password.length !== 0 &&
    recoverForm.password === recoverForm.passwordConfirm &&
    fieldFill.hasLowerUpper &&
    fieldFill.hasMin &&
    fieldFill.hasNumber &&
    fieldFill.hasSpecial
  );
});

const isPasswordVisible = ref(false);
const isPasswordConfirmVisible = ref(false);
</script>

<template>
  <el-form
    ref="recoverFormRef"
    class="form"
    :model="recoverForm"
    :rules="rules"
    label-position="top"
  >
    <el-form-item class="form__element" prop="password">
      <el-input
        v-model="recoverForm.password"
        size="large"
        :type="isPasswordVisible ? 'text' : 'password'"
        :placeholder="t('fields.password')"
        @focus="() => (isPasswordFocus = true)"
        @blur="() => (isPasswordFocus = false)"
      >
        <template #suffix>
          <button
            class="form__switch"
            @click.prevent="() => (isPasswordVisible = !isPasswordVisible)"
            v-text="isPasswordVisible ? t('login.hide') : t('login.show')"
          />
        </template>
      </el-input>
    </el-form-item>
    <transition name="fade">
      <ui-password-helper v-if="isPasswordHelperVisible" :fill="fieldFill" />
    </transition>
    <el-form-item class="form__element" prop="passwordConfirm">
      <el-input
        v-model="recoverForm.passwordConfirm"
        size="large"
        :type="isPasswordConfirmVisible ? 'text' : 'password'"
        :placeholder="t('fields.passwordR')"
      >
        <template #suffix>
          <button
            class="form__switch"
            @click.prevent="
              () => (isPasswordConfirmVisible = !isPasswordConfirmVisible)
            "
            v-text="
              isPasswordConfirmVisible ? t('login.hide') : t('login.show')
            "
          />
        </template>
      </el-input>
    </el-form-item>
    <el-button
      type="info"
      class="form__button"
      size="large"
      :disabled="!isFormValid"
      @click.prevent="submitForm"
      >{{ isPending ? t("login.loading") : t("reset.button") }}</el-button
    >
  </el-form>
</template>

<style scoped lang="scss">
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;

  &__element {
    width: 100%;
  }

  &__switch {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: var(--el-text-color-secondary);
    margin: 0 !important;

    @include hover {
      color: var(--el-text-color-primary);
    }

    &:active {
      color: var(--el-text-color-primary);
    }
  }

  &__link {
    align-self: end;
    margin-top: -2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: var(--el-text-color-primary);
    font-weight: 500;

    @include hover {
      color: var(--el-color-primary);
    }

    &:active {
      color: var(--el-color-primary);
    }

    @include mobile {
      margin-top: 0;
    }
  }

  &__button {
    width: 100%;
    margin-top: 1rem;
  }
}
</style>