<script setup>
import { Navigation } from "swiper/modules";
import {ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import translations from "@/core/helpers/translations.js";

const props = defineProps<{
  products: Object
};

const {t} = useI18n()

const activeSlide = ref(0);

const slider = ref(null);
const prevButton = ref(null);
const nextButton = ref(null);

const config = {
  slidesPerView: "auto",
  spaceBetween: 2,
};

const onSwipe = (e) => {
  activeSlide.value = e.activeIndex;
};

watch(
  () => props.products,
  () => {
    activeSlide.value = 0;
  },
  { deep: true, immediate: true },
);

const closeMenu = () => {
  alert("need add close");
};

const $emits = defineEmits(['close'])
</script>

<template>
  <div
    :class="
      activeSlide === 0
        ? 'header__slider header__slider--initial'
        : 'header__slider'
    "
  >
    <button
      v-show="activeSlide !== 0"
      ref="prevButton"
      class="slider__button slider__button-prev"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 6 10"
        fill="currentColor"
      >
        <path
          d="M5.23 1.1A.644.644 0 0 0 5.02.05a.646.646 0 0 0-.713.144l-4.12 4.12a.644.644 0 0 0 0 .907l4.138 4.12a.644.644 0 0 0 .906 0 .644.644 0 0 0 0-.906L1.576 4.763 5.23 1.1Z"
        />
      </svg>
    </button>
    <swiper
      ref="slider"
      class="slider"
      v-bind="config"
      :navigation="{ prevEl: prevButton, nextEl: nextButton }"
      :modules="[Navigation]"
      :slides-per-group="4"
      @slide-change="(e) => onSwipe(e)"
    >
      <swiper-slide
        v-for="product of products"
        :key="product.skin_id"
        class="slider__slide"
        @click="$emits('close')"
      >
        <div class="product-card">
          <span
            class="product-card__color"
            :style="{ backgroundColor: product.color }"
          />
          <img
            :src="product.image_url"
            :alt="product.name"
            class="product-card__image"
            :placeholder="[150, 110, 60, 2]"
          />
          <span class="product-card__title">
            {{
              product.name.split("| ")[1]
                ? product.name.split("| ")[1].split(" (")[0]
                : product.name
            }}
          </span>
          <p class="product-card__text">
            <span v-text="t('header.from')" />
            <span class="price" v-text="product.price" />
          </p>
          <router-link
            :to="
              translations.i18nRoute(
                `/market?page=1&skin_type=${product.skin_type}&weapon=${product.weapon}&name=${product.name.split('| ')[1] ? product.name.split('| ')[1].split(' (')[0] : product.name}`,
              )
            "
            class="product-card__button"
          />
        </div>
      </swiper-slide>
    </swiper>
    <button
      v-show="activeSlide < products.length - 6"
      ref="nextButton"
      class="slider__button slider__button-next"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 6 10"
        fill="currentColor"
      >
        <path
          d="M.186 1.1A.644.644 0 0 1 .398.05a.646.646 0 0 1 .712.144l4.12 4.12a.644.644 0 0 1 0 .907l-4.138 4.12a.644.644 0 0 1-.906 0 .644.644 0 0 1 0-.906l3.655-3.672L.186 1.1Z"
        />
      </svg>
    </button>
  </div>
</template>

<style scoped lang="scss">
.header__slider {
  width: 100%;
  position: relative;

  &--initial {
    padding-left: 5rem;
  }
}

.slider {
  &__slide {
    max-width: 180px;
    width: 100%;
  }

  &__button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    color: var(--el-text-color-secondary);
    background-color: transparent;

    & > svg {
      width: 3rem;
      height: 3rem;
    }

    &-prev {
      left: 0;
    }

    &-next {
      right: 0;
    }

    @include hover {
      background-color: rgba(#232728, 0.6);
    }
  }
}

.product-card {
  width: 100%;
  flex: 1;
  background-color: var(--el-bg-darker);
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;

  @include hover {
    background-color: var(--el-bg-color-overlay);
    box-shadow: var(--el-box-shadow-dark);
  }

  &__color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.5rem;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.4rem;
    color: var(--el-text-color-secondary);

    & > .price {
      font-size: 1.8rem;
      font-weight: 600;
      color: var(--el-text-color-primary);
    }
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>