<script setup>
import {ElMessage, ElNotification} from "element-plus";
import {useI18n} from "vue-i18n";
import {computed, ref} from "vue";
import translations from "@/core/helpers/translations.js";
import {useAuthStore} from "@/stores/auth.js";
import UiFloatViewer from "@/components/ui/ui-float-viewer.vue";
import {useCopy} from "@/core/hooks/useCopy.js";
import {useCartStore} from "@/stores/cart.js";
import {useWishlistStore} from "@/stores/wishlist.js";
import {Wishlist} from "@/core/api/wishlist.js";
import {Orders} from "@/core/api/orders.js";
import {nameAdapter} from "@/core/helpers/nameAdapter.js";

const { product, view } = defineProps({
  product: Object,
  view: "grid" | "list"
})

const { t } = useI18n();
const authStore = useAuthStore();
const cartStore = useCartStore();

const { locale } = useI18n();

const wishlistUuid = computed(() => {
  return wishlistStore.wishlist ? wishlistStore.wishlist.uuid : ''
})
const orderUuid = computed(() => {
  return cartStore.currentOrder.uuid ? cartStore.currentOrder.uuid : null
})
const user = computed(() => authStore.user);
const accessToken = computed(() => authStore.accessToken);

const productFloat = computed(() => {
  const steamInfoGroup = product.attributeGroups.edges.find(
    (group) => group.node.name === "Skin Info"
  );
  if (!steamInfoGroup) return null;

  const floatAttribute = steamInfoGroup.node.attributes.find(
    (attr) => attr.name === "float"
  );
  if (!floatAttribute || !floatAttribute.values.length) return null;

  return floatAttribute.values[0].value;
});

const productStickers = computed(() => {
  const skinInfoGroup = product.attributeGroups.edges.find(
    (group) => group.node.name === "Skin Info"
  );
  if (!skinInfoGroup) return null;

  const stickerAttribute = skinInfoGroup.node.attributes.find(
    (attr) => attr.name === "stickers"
  );

  if (!stickerAttribute || !stickerAttribute.values.length) return null;

  try {
    return stickerAttribute?.values.map((sticker) => {
      const { uuid, ...rest } = JSON.parse(sticker.value);
      return rest;
    })[0];
  } catch (error) {
    console.error("Failed to parse stickers JSON:", error);
    return null;
  }
});

const isProductInCart = computed(() => {
  const el = cartStore.currentOrder?.orderProducts?.edges.find((el) => el.node.product.uuid === product.uuid);
  return !!el;
});

const computedProductName = computed(() => {
  return product.name.split("| ")[1]
    ? product.name.split("| ")[1].split(" (")[0]
    : product.name;
});

const computedDetailUrl = computed(() => {
  return translations.i18nRoute({ name: 'home'})
});

const addOrRemoveProduct = async () => {
  if (user.value) {
    if (isProductInCart.value) {
      const res = await Orders.overwriteCart("removeOrderProduct", accessToken.value, orderUuid.value, product.uuid);

      if (!res.errors) {
        cartStore.setCurrentOrders(res.data.removeOrderProduct.order)

        ElNotification({
          title: t("notifications.success.removeProduct.title"),
          message: t("notifications.success.removeProduct.text", {
            name: product.name,
          }),
          duration: 5000,
          type: "success",
          position: "bottom-left",
        });
      } else {
        ElNotification({
          message: res.errors[0].message,
          type: "error",
          duration: 5000,
          position: "bottom-left",
        });
      }
    } else {
      const res = await Orders.overwriteCart("addOrderProduct", accessToken.value, orderUuid.value, product.uuid);

      if (!res.errors) {
        cartStore.setCurrentOrders(res.data.addOrderProduct.order)

        ElNotification({
          title: t("notifications.success.addProduct.title"),
          message: t("notifications.success.addProduct.text", {
            name: product.name,
          }),
          duration: 5000,
          type: "success",
          position: "bottom-left",
        });
      } else {
        ElNotification({
          message: res.errors[0].message,
          type: "error",
          duration: 5000,
          position: "bottom-left",
        });
      }
    }
  } else {
    ElNotification({
      title: t("notifications.error.mustBeLogin.title"),
      message: t("notifications.error.mustBeLogin.text"),
      type: "error",
      duration: 5000,
      position: "bottom-left",
    });
  }
};

const isCardMenuVisible = ref(false);

const setMenuVisible = () => {
  isCardMenuVisible.value = !isCardMenuVisible.value;
};

const hideMenuOnMouseLeave = () => {
  if (isCardMenuVisible.value) isCardMenuVisible.value = false;
};

const copyLink = ()=> {
  useCopy(
    `https://gameskins.eu/${locale.value}/product /${product.uuid}`,
  );

  ElMessage({
    message: t("productPage.copied"),
    type: "success",
    duration: 5000,
  });
};

const wishlistStore = useWishlistStore();

const isProductInWishlist = computed(() => {
  const el = wishlistStore.wishlist.products?.edges.find((el) => el.node.uuid === product.uuid);
  return !!el;
});

const addOrRemoveWishlist = async () => {
  if (user.value) {
    if (isProductInWishlist.value) {
      const res = await Wishlist.overwriteWishlist('removeWishlistProduct', accessToken.value, wishlistUuid.value, product.uuid)
      wishlistStore.wishlist = res.data.removeWishlistProduct.wishlist

      ElNotification({
        title: t("notifications.success.removeWishlist.title"),
        message: t("notifications.success.removeWishlist.text", {
          name: product.name
        }),
        type: "success",
        position: "bottom-left",
      });
    } else {
      const res = await Wishlist.overwriteWishlist('addWishlistProduct', accessToken.value, wishlistUuid.value, product.uuid)
      wishlistStore.wishlist = res.data.addWishlistProduct.wishlist

      ElNotification({
        title: t("notifications.success.addWishlist.title"),
        message: t("notifications.success.addWishlist.text", {
          name: product.name
        }),
        type: "success",
        position: "bottom-left",
      });
    }
  } else {
    ElNotification({
      title: t("notifications.error.mustBeLogin.title"),
      message: t("notifications.error.addProduct.text"),
      type: "error",
      duration: 5000,
      position: "bottom-left",
    });
  }
};
</script>

<template>
  <div
    :class="[`product-card product--${view}`]"
    @mouseleave="hideMenuOnMouseLeave"
  >
    <div
      :class="[
        'product-card__inner',
        isProductInCart ? 'product-card--added' : '',
        isCardMenuVisible ? 'product-card--opened' : '',
      ]"
    >
      <div class="product-card__head">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 18">
          <path d="M15.429 4.498v9L7.797 18 0 13.502l.003-9L7.803 0l7.626 4.498Zm-1.562.98L7.802 1.903 1.542 5.48v7.007l6.256 3.61 6.066-3.613.003-7.007Zm-5.638.18v3.6h2.057V10.8h-3.6V5.657h1.543Z"
              fill-rule="nonzero"/>
        </svg>
        <span v-text="t('product.state')" />
      </div>
      <img class="product-card__image" :src="product.images.edges[0].node.image" alt="image" />
      <p class="product-card__price">
        <img src="@icons/coin.svg" alt="coin" />
        <span v-text="product.price" />
      </p>
      <div class="product-card__stickers" v-if="productStickers">
        <el-tooltip
          v-for="sticker of productStickers"
          :key="sticker.uuid"
          :content="sticker.name"
          placement="left"
          effect="light"
        >
          <img class="product-card__sticker" :src="sticker.img" :alt="sticker.name" />
        </el-tooltip>
      </div>
      <span class="product-card__weapon" :style="{ color: product.color }">
        {{ product.weapon }}
      </span>
      <div class="product-card__name">
        {{ computedProductName }}
      </div>
      <span class="product-card__quality">
        {{
          `${product.weapon ? product.weapon : ""} ${product.exterior ? product.exterior : ""} ${product.rarity ? product.rarity : ""} ${product.quality ? product.quality : ""}`
        }}
      </span>
      <div class="product-card__float">
        <ui-float-viewer
          v-if="productFloat"
          :float="productFloat"
          show-float
        />
      </div>
      <router-link class="product-card__link" :to="translations.i18nRoute({ name: 'product', params: { uuid: product.uuid } })" />
      <div class="product-card__tools-desktop">
        <button
          :class="
            isProductInCart
              ? 'product-card__tools-button product-card__tools-button--added'
              : 'product-card__tools-button'
          "
          @click="addOrRemoveProduct"
        >
          <svg v-if="isProductInCart" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 9 9">
            <path d="M2.856 4.248 0 1.414 1.387.028l2.85 2.828L7.071 0l1.387 1.387-2.829 2.85 2.856 2.834L7.1 8.458 4.25 5.629 1.415 8.485.028 7.1z"/>
          </svg>
          <span>
            {{ isProductInCart ? t("product.remove") : t("product.add") }}
          </span>
        </button>
        <button
          :class="
            isProductInCart
              ? 'product-card__tools-button product-card__tools-button--added'
              : 'product-card__tools-button'
          "
          @click="setMenuVisible"
        >
          <span class="product-card__tools-dot" />
          <span class="product-card__tools-dot" />
          <span class="product-card__tools-dot" />
        </button>
      </div>
      <div class="product-card__tools-mobile">
        <button
          :class="
            isProductInCart
              ? 'product-card__tools-button product-card__tools-button--added'
              : 'product-card__tools-button'
          "
          @click="addOrRemoveProduct"
        >
          <img v-if="isProductInCart" src="@icons/close-bold.svg" alt="close-bold" />
          <span>
            {{ isProductInCart ? t("product.remove") : t("product.add") }}
          </span>
        </button>
        <button
          :class="
            isProductInCart
              ? 'product-card__tools-button product-card__tools-button--added'
              : 'product-card__tools-button'
          "
          @click="setMenuVisible"
        >
          <span class="product-card__tools-dot" />
          <span class="product-card__tools-dot" />
          <span class="product-card__tools-dot" />
        </button>
      </div>
      <transition name="fade">
        <div v-if="isCardMenuVisible" class="product-card__tools-menu">
          <div class="inner">
            <ul class="list">
              <li class="item">
                <router-link
                  class="button"
                  :to="
                    translations.i18nRoute({ name: 'market', query: { name: computedProductName } })"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                  </svg>
                  <span v-text="t('product.search')" />
                </router-link>
              </li>
              <li v-if="product.link" class="item">
                <a class="button" :href="product.link">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
                  </svg>
                  <span v-text="t('product.view')" />
                </a>
              </li>
              <li class="item">
                <a
                  class="button"
                  target="_blank"
                  :href="`https://steamcommunity.com/market/listings/730/${product.name}`"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M.329 10.333A8.01 8.01 0 0 0 7.99 16C12.414 16 16 12.418 16 8s-3.586-8-8.009-8A8.006 8.006 0 0 0 0 7.468l.003.006 4.304 1.769A2.2 2.2 0 0 1 5.62 8.88l1.96-2.844-.001-.04a3.046 3.046 0 0 1 3.042-3.043 3.046 3.046 0 0 1 3.042 3.043 3.047 3.047 0 0 1-3.111 3.044l-2.804 2a2.223 2.223 0 0 1-3.075 2.11 2.22 2.22 0 0 1-1.312-1.568L.33 10.333Z"/>
                    <path d="M4.868 12.683a1.715 1.715 0 0 0 1.318-3.165 1.7 1.7 0 0 0-1.263-.02l1.023.424a1.261 1.261 0 1 1-.97 2.33l-.99-.41a1.7 1.7 0 0 0 .882.84Zm3.726-6.687a2.03 2.03 0 0 0 2.027 2.029 2.03 2.03 0 0 0 2.027-2.029 2.03 2.03 0 0 0-2.027-2.027 2.03 2.03 0 0 0-2.027 2.027m2.03-1.527a1.524 1.524 0 1 1-.002 3.048 1.524 1.524 0 0 1 .002-3.048"/>
                  </svg>
                  <span v-text="t('product.steam')" />
                </a>
              </li>
              <li class="item">
                <button class="button" @click="addOrRemoveWishlist">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15"/>
                  </svg>
                  <span
                    v-text="
                      isProductInWishlist
                        ? t('product.unfollow')
                        : t('product.follow')
                    "
                  />
                </button>
              </li>
              <li class="item">
                <button class="button" @click="copyLink">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5"/>
                  </svg>
                  <span v-text="t('product.share')" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="isProductInCart" class="product-card__added">
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 12 10">
        <path d="M2.904 8.11.12 5.163 1.536 3.75l2.777 2.94L10.12.835l1.415 1.414-5.849 5.894.005.005-1.414 1.415-1.414-1.415.04-.04z"/>
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.product-card {
  width: 100%;
  height: 100%;
  background-color: var(--el-bg-color);
  transition: all 0.2s ease;
  position: relative;

  @include hover {
    transform: translateY(-25px) !important;
    background-color: var(--el-bg-darker);
    box-shadow: var(--el-box-shadow-dark);
    z-index: 7;

    .product-card__tools-desktop {
      height: 50px;
      z-index: 7;
    }
  }

  @include mobile-tablet {
    @include hover {
      transform: none !important;
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
    position: relative;
    padding: 2rem 1.5rem;
    transition: all 0.3s ease;
  }

  &--added {
    filter: grayscale(100%);

    @include hover {
      filter: none;
    }

    @include mobile-tablet {
      filter: none;
    }
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-weight: 700;
    font-size: 1.4rem;
    color: var(--el-color-success-light-7);
    margin-bottom: 2rem;
    position: relative;
    z-index: 6;

    & svg {
      width: 20px;
    }
  }

  &__added {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 78px;
    height: 78px;
    border-radius: 100%;
    background-color: var(--el-color-info);
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(0);
  }

  &__image {
    width: auto;
    height: 12rem;
    align-self: center;
    margin-bottom: 2rem;

    @include mobile {
      height: 10rem;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2rem;

    & img {
      width: 20px;
    }

    @include mobile {
      font-size: 2rem;
    }
  }

  &__weapon {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: 500;

    @include mobile {
      font-size: 1.2rem;
    }
  }

  &__name {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    @include mobile {
      font-size: 1.6rem;
    }
  }

  &__quality {
    color: var(--el-text-color-secondary);

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__float {
    width: 100%;
    margin-top: auto;
    padding-top: 2rem;
  }

  &__tools {
    &-desktop {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: -1;
      display: flex;
      align-items: start;
      background-color: var(--el-bg-color);
      overflow: hidden;
      height: 0;
      transition:
        height 0.2s ease,
        z-index 0.1s ease;

      @include mobile-tablet {
        display: none;
      }
    }

    &-mobile {
      width: 100%;
      display: none;
      align-items: stretch;
      margin-top: 2rem;
      border: 1px solid var(--el-text-color-placeholder);
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      z-index: 6;

      @include mobile-tablet {
        display: flex;
      }
    }

    &-menu {
      z-index: 5;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#1d2021, 0.7);
      display: flex;
      align-items: end;

      & .inner {
        width: 100%;
        background-color: var(--el-bg-color);
        padding: 0.5rem 0;

        @include mobile-tablet {
          padding-bottom: calc(30px + 4rem);
        }
      }

      & .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      & .item {
        width: 100%;
      }

      & .button {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        width: 100%;
        padding: 1.5rem 2rem;
        text-align: start;
        color: var(--el-text-color-primary);
        font-weight: 600;
        font-size: 1.8rem;

        @include hover {
          background-color: var(--el-bg-darker);
        }

        @include mobile-tablet {
          padding: 1rem 1.5rem;
          font-size: 1.4rem;
        }
      }
    }

    &-button {
      background-color: var(--el-color-info);
      min-height: 4rem;

      @include mobile-tablet {
        background-color: transparent;
        padding: 5px;
        font-size: 1.2rem;
      }

      @include hover {
        background-color: var(--el-color-info-dark-2);
      }

      &:nth-of-type(1) {
        width: 100%;
        height: 100%;
        border-radius: 0 0 0 5px;
        color: var(--el-text-color-primary);
        letter-spacing: 0.12rem;
        font-weight: 700;
        font-size: 1.7rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        @include mobile-tablet {
          border-radius: 0;
          font-weight: 600;
          font-size: 1rem;
        }
      }

      &:nth-of-type(2) {
        border-radius: 0 0 5px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        min-width: 40px;
        width: 40px;
        height: 100%;
        margin-left: 1px;

        @include mobile-tablet {
          border-radius: 0;
          gap: 3px;
          border-left: 1px solid var(--el-text-color-placeholder);
          min-width: 35px;
          width: 35px;
        }
      }

      &--added {
        background-color: var(--el-color-error);

        @include hover {
          background-color: var(--el-color-error-dark-2);
        }
      }
    }

    &-dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: var(--el-text-color-primary);
      display: block;

      @include mobile-tablet {
        width: 3px;
        height: 3px;
      }
    }
  }

  &__link {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &__stickers {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @include mobile {
      right: 1rem;
      top: 1rem;
    }
  }

  &__sticker {
    width: 35px;
    height: auto;
    transition: all 0.3s ease;

    @include hover {
      scale: 1.3;
    }
  }
}

svg {
  width: 20px;
}
</style>