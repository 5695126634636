<script setup>
import { useI18n } from "vue-i18n";
import translations from "@/core/helpers/translations.js";
import FormsResetPassword from "@/components/forms/forms-reset-password.vue";

const { t } = useI18n();
</script>

<template>
  <div class="recover">
    <div class="recover__head">
      <h1 class="recover__title" v-text="t('forgot.title')" />
      <h1 class="recover__text" v-text="t('forgot.subtitle')" />
    </div>
    <div class="recover__form">
      <forms-reset-password />
    </div>
    <router-link
      class="recover__link"
      :to="translations.i18nRoute({ name: 'sign-in' })"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
        />
      </svg>
      <span v-text="t('forgot.link')" />
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.recover {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__head {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
  }

  &__title {
    font-size: 2.5rem;
    font-weight: 700;

    @include mobile {
      font-size: 1.8rem;
      text-align: center;
    }
  }

  &__text {
    color: var(--el-text-color-secondary);
    font-size: 1.6rem;

    @include mobile {
      font-size: 1.4rem;
    }
  }

  &__form {
    padding: 0 4rem;
    width: 100%;
    max-width: 45rem;
    margin-bottom: 4rem;

    @include mobile {
      padding: 0 2rem;
    }
  }

  &__link {
    color: var(--el-text-color-primary);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.4rem;

    @include hover {
      color: var(--el-color-primary);
    }

    &:active {
      color: var(--el-color-primary);
    }

    & > svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}
</style>
