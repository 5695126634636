<script setup>
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useAuthStore} from "@/stores/auth.js";
import {Auth} from "@/core/api/auth.js";
import translation from "@/core/helpers/translations.js";
import {useRoute, useRouter} from "vue-router";

const { locale } = useI18n();
const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()

const locales = ref([
  {
    locale: "en-GB",
    displayName: "English",
  },
  {
    locale: "de-DE",
    displayName: "Deutsch",
  },
  {
    locale: "fr-FR",
    displayName: "Français",
  },
  {
    locale: "es-ES",
    displayName: "Español",
  },
  {
    locale: "it-IT",
    displayName: "Italiano",
  },
  {
    locale: "nl-NL",
    displayName: "Dutch",
  },
]);

const userUuid = computed(() => {
  return authStore.user?.uuid ? authStore.user.uuid : ''
})
const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : ''
})

const currentLocale = computed(() => {
  return locales.value.find((el) => el.locale === locale.value);
});

const selectedLocale = ref(currentLocale.value.locale);

const switchLanguage = async (locale) => {
  if (userUuid.value && accessToken.value) {
    const res = await Auth.setLanguage(userUuid.value, accessToken.value, locale)

    if (res.data.updateUser) {
      authStore.setUser({
        user: res.data.updateUser.user,
        accessToken: accessToken.value
      })

      if (locale !== translation.currentLocale) {
        translation.switchLanguage(locale)
      }
    }
  } else {
    translation.switchLanguage(locale)
  }

  await router.push({
    name: route.name,
    params: {
      ...route.params,
      locale: locale
    },
    query: { ...route.query }
  })
};
</script>

<template>
  <el-select v-model="selectedLocale" class="switcher">
    <template #prefix>
      <img
        class="switcher__icon"
        :src="`/flags/${selectedLocale}.svg`"
        alt="image"
      />
    </template>
    <el-option
      v-for="lang in locales"
      :key="lang.locale"
      :value="lang.locale"
      :label="lang.locale.split('-')[0]"
      @click="switchLanguage(lang.locale)"
    >
      <template #default>
        <div class="switcher__item">
          <img
            class="switcher__icon"
            :src="`/flags/${lang.locale}.svg`"
            :alt="lang.displayName"
          />
          <span class="switcher__name" v-text="lang.displayName" />
        </div>
      </template>
    </el-option>
  </el-select>
</template>

<style scoped lang="scss">
.switcher {
  width: 12rem;

  &__item {
    //width: 15rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @include hover {
      .switcher__name {
        color: var(--el-color-primary);
      }
    }
  }

  &__icon {
    width: 24px;
    min-width: 24px;
    height: 16px;
    min-height: 16px;
  }

  &__name {
    font-weight: 600;
    transition: all 0.3s ease;
    font-size: 1.6rem;
  }
}
</style>

<style lang="scss">
.switcher {
  border: none;

  .el-select {
    box-shadow: none !important;
  }

  .el-select__wrapper {
    box-shadow: none !important;
    background-color: transparent !important;

    @include hover {
      .el-select__selected-item {
        color: var(--el-color-primary);
      }
    }
  }

  .el-select__selected-item {
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s ease;
  }
}
</style>
