<script setup >
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import {ref} from "vue";
import ProductCard from "@/components/product/product-card.vue";

const props = defineProps({
  products: Object
})

const activeSlide = ref(0)

const baseSwiper = ref(null)
const prevButton = ref(null)
const nextButton = ref(null)
</script>

<template>
  <div class="base-swiper__wrapper">
    <button
        v-show="activeSlide !== 0"
        ref="prevButton"
        class="base-swiper__button base-swiper__button-prev"
    >
      <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 17 29">
        <path d="M12.864.772.136 13.5l12.728 12.728" transform="translate(2 1)" stroke-width="3" fill="none"
            fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
    <swiper
        ref="baseSwiper"
        class="base-swiper"
        slides-per-view="auto"
        :space-between="2"
        :navigation="{ prevEl: prevButton, nextEl: nextButton }"
        :modules="[Navigation]"
        @slide-change="(e) => (activeSlide = e.activeIndex)"
    >
      <swiper-slide v-for="product of products" :key="product.skin_id" class="base-swiper__slide">
        <product-card :product="product.node" view="grid" />
      </swiper-slide>
    </swiper>
    <button
        v-show="activeSlide !== products.length - 4"
        ref="nextButton"
        class="base-swiper__button base-swiper__button-next"
    >
      <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 17 29">
        <path d="M.136 26.228 12.864 13.5.136.772" transform="translate(2 1)" stroke-width="3" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
</template>

<style scoped lang="scss">
.base-swiper {
  width: 100%;
  transform: translateY(-25px);

  @include mobile-tablet {
    transform: none;
    width: 100vw;
    padding-right: 2rem;
  }

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__slide {
    width: 220px;
    padding: 25px 0;

    @include mobile-tablet {
      width: 23rem;
      padding: 0;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    bottom: 50px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--el-text-color-secondary);

    & svg {
      width: 20px;
    }

    @include mobile {
      display: none;
    }

    @include desktop {
      width: 5rem;
    }

    @include mobile {
      display: none;
    }

    @include hover {
      background-color: var(--el-bg-color);
      color: var(--el-text-color-primary);
    }

    &:active {
      background-color: var(--el-bg-color);
    }

    &-prev {
      left: -5rem;
      border-right: 2px solid var(--el-bg-color-page);
    }

    &-next {
      border-left: 2px solid var(--el-bg-color-page);
      right: -5rem;
    }
  }
}
</style>