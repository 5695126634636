<script setup>
import {useAuthStore} from "@/stores/auth.js";
import {computed} from "vue";
import {useRouter} from "vue-router";
import UiLogotype from "@/components/ui/ui-logotype.vue";
import UiAnimatedBackground from "@/components/ui/ui-animated-background.vue";

const authStore = useAuthStore();
const router = useRouter()

const user = computed(() => authStore.user);

if (user.value) {
  router.push({
    name: "home",
    params: {
      locale: localStorage.getItem('user-locale') || 'en-GB'
    },
  });
}
</script>

<template>
  <div class="layout">
    <div class="layout__logo">
      <ui-logotype />
    </div>
    <main class="main">
      <RouterView v-slot="{ Component }">
        <Transition name="opacity" mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </main>
    <ui-animated-background />
  </div>
</template>

<style scoped lang="scss">
.layout {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 2rem 0 5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8rem;

  @include mobile {
    gap: 4rem;
  }

  &__logo {
    padding-left: 5rem;

    @include mobile {
      padding-left: 2rem;
    }
  }

  .main {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

:deep(.el-input--large) {
  --el-input-inner-height: 48px;
}
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #717777 inset;
}
</style>
