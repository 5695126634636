import { createI18n } from 'vue-i18n'
import enGB from '@/locales/en-GB.json'
import deDE from '@/locales/de-DE.json'
import esES from '@/locales/es-ES.json'
import frFR from '@/locales/fr-FR.json'
import itIT from '@/locales/it-IT.json'
import nlNL from '@/locales/nl-NL.json'

const currentLocale = localStorage.getItem('user-locale') || 'en-GB'

const i18n = createI18n({
  locale: currentLocale,
  fallbackLocale: 'en-GB',
  allowComposition: true,
  legacy: false,
  globalInjection: true,
  messages: {
    "en-GB": enGB,
    "de-DE": deDE,
    "es-ES": esES,
    "fr-FR": frFR,
    "it-IT": itIT,
    "ro-RO": nlNL
  }
})

export default i18n
